import { apiv2 } from "../apiv2";

export const getPrizeReport = async (campaignId, paramName = '', paramValue = '', from, to, page = 1) => {
  console.log('paramName', campaignId);
  return await apiv2.get(`/prize-report/${campaignId}`, {
    params: {
      page: page,
      ...(paramName && paramValue && {[paramName]: paramValue}),
      ...(from && { from }),
      ...(to && { to }),
    }
  });
}

export const getPrizeReportExport = async (campaignId, paramName = '', paramValue = '', from, to, page = 1) => {
  return await apiv2.get(`/prize-report/${campaignId}/export`, {
    params: {
      page: page,
      ...(paramName && paramValue && {[paramName]: paramValue}),
      ...(from && { from }),
      ...(to && { to }),
    }
  });
}
