import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Container, Row } from 'react-bootstrap';
import TextoWhatsApp from 'cashin-design-system/Styles/img/WhatsApp/mensagem-boas-vindas.png';

const FolderWhatsAppPreview = ({ titleComunication, introductionComunication, descriptionComunication, attachmentFilePath, btnAddParticipation, styles, state, layoutInfo }) => {
  const stylesWhatsApp = {
    input: {
      display: 'block',
      width: '80%',
      margin: '10px auto',
      padding: '5px',
    },
    textarea: {
      display: 'block',
      width: '80%',
      height: '100px',
      margin: '10px auto',
      padding: '5px',
    },
    button: {
      display: 'block',
      margin: '20px auto',
    },
    device: {
      paddingTop: '70px',
      paddingLeft: '14px',
      width: '25rem',
      height: '50rem',
      position: 'relative',
      background: `url('https://mockuphone.com/Images/devices_picture/apple-iphone14pro-spaceblack-portrait.png') no-repeat center center`,
      backgroundSize: 'cover'
    },
    screen: {
      backgroundColor: '#e5ddd5',
      width: '22rem',
      height: '40rem',
      overflow: 'auto',
      margin: '10px',
      padding: '10px',
    },
    message: {
      padding: '5px 10px',
      marginBottom: '5px',
      borderRadius: '10px',
      maxWidth: '98%',
      clear: 'both',
    },
    userMessage: {
      backgroundColor: '#DCF8C6',
      alignSelf: 'flex-end',
      marginLeft: 'auto',
      marginRight: '5px',
    },
    messageImage: {
      height: 200
    },
    image: { float: "right", marginBottom: "0.5rem", borderRadius: ".5rem", width: 288, objectFit: "cover" }
  };

  const handleTextMarkdownToHtml = (text) => {
    if (!text) return '';
    // negrito cpm **
    text = text.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');
    // negrito
    text = text.replace(/\*(.*?)\*/g, '<strong>$1</strong>');
    // itálico
    text = text.replace(/_(.*?)_/g, '<em>$1</em>');
    // // quebra de linha
    text = text.replace(/\n/g, '<br />');

    return text;
  }

  return (
    <Container>
      <Row>
        <Col xs className="d-flex justify-content-center">
          <div className={styles.whatsAppMessage()}>
            <div style={stylesWhatsApp.device}>
              <div style={stylesWhatsApp.screen}>

                {attachmentFilePath && (
                  // <div style={{ ...stylesWhatsApp.message, ...stylesWhatsApp.userMessage, ...stylesWhatsApp.messageImage }}>
                  <img src={attachmentFilePath} alt="Publicidade WhatsApp" style={stylesWhatsApp.image} />
                  // </div>
                )}
                <div style={{ ...stylesWhatsApp.message, ...stylesWhatsApp.userMessage }}>
                  <p style={{ fontWeight: 'bold' }} dangerouslySetInnerHTML={{ __html: handleTextMarkdownToHtml(titleComunication) }} />
                  <p dangerouslySetInnerHTML={{ __html: handleTextMarkdownToHtml(introductionComunication) }} />
                  <p dangerouslySetInnerHTML={{ __html: handleTextMarkdownToHtml(descriptionComunication) }} />
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

FolderWhatsAppPreview.propTypes = {
  styles: PropTypes.object,
  state: PropTypes.object,
  layoutInfo: PropTypes.object,
};

export default FolderWhatsAppPreview;
