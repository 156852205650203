import { css } from './CreateStitches';
import Checked from './img/Check/check-checked.png';
import CheckedDisabled from './img/Check/check-disabled.png';
import Checkedindeterminate from './img/Check/check-indeterminate.png';
import RadioChecked from './img/Radio/radio-checked.png';
import RadioCheckedHover from './img/Radio/radio-checked-hover.png';
import RadioCheckedFocus from './img/Radio/radio-checked-focus.png';
import RadioDisabled from './img/Radio/radio-disabled.png';
import Switch from './img/Switch/switch.png';
import SwitchFocus from './img/Switch/switch-focus.png';

export const check = css({
  variants: {
    checkbox: {
      normal: {
        '& .form-check-input[type="checkbox"]': {
          borderRadius: 2,
          backgroundColor: '$neutralColorHighPure',
          borderColor: '$neutralColorHighDark',

          '&:hover': {
            backgroundColor: '$neutralColorHighPure',
            borderColor: '$feedbackColorHelperPure',
          },

          '&:checked': {
            backgroundColor: '$feedbackColorHelperPure',
            backgroundImage: `url(${Checked})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'auto',
            borderColor: '$feedbackColorHelperPure',

            '&:hover': {
              backgroundColor: '$feedbackColorHelperLight',
              borderColor: '$feedbackColorHelperLight',
            },

            '&:disabled': {
              backgroundColor: '$neutralColorHighLight',
              backgroundImage: `url(${CheckedDisabled})`,
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              backgroundSize: 'auto',
              borderColor: '$neutralColorHighMedium',
            },
          },

          '&:disabled': {
            backgroundColor: '$neutralColorHighPure',
            borderColor: '$neutralColorHighMedium',

            '& ~ .form-check-label': {
              color: '$neutralColorLowLight',
            },
          },

          '&:indeterminate': {
            backgroundColor: '$neutralColorHighPure',
            backgroundImage: `url(${Checkedindeterminate})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'auto',
            borderColor: '$feedbackColorHelperPure',

            '&:hover': {
              backgroundColor: '$neutralColorHighPure',
              borderColor: '$feedbackColorHelperPure',
            },
          },
        },
      },
      hover: {
        '& .form-check-input[type="checkbox"]': {
          borderRadius: 2,
          backgroundColor: '$neutralColorHighPure',
          borderColor: '$feedbackColorHelperPure',

          '&:hover': {
            backgroundColor: '$neutralColorHighPure',
            borderColor: '$feedbackColorHelperPure',
          },

          '&:checked': {
            backgroundColor: '$feedbackColorHelperLight',
            backgroundImage: `url(${Checked})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'auto',
            borderColor: '$feedbackColorHelperLight',

            '&:hover': {
              backgroundColor: '$feedbackColorHelperLight',
              borderColor: '$feedbackColorHelperLight',
            },

            '&:disabled': {
              backgroundColor: '$neutralColorHighLight',
              backgroundImage: `url(${CheckedDisabled})`,
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              backgroundSize: 'auto',
              borderColor: '$neutralColorHighMedium',
            },
          },

          '&:disabled': {
            backgroundColor: '$neutralColorHighPure',
            borderColor: '$neutralColorHighMedium',

            '& ~ .form-check-label': {
              color: '$neutralColorLowLight',
            },
          },

          '&:indeterminate': {
            backgroundColor: '$neutralColorHighPure',
            backgroundImage: `url(${Checkedindeterminate})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'auto',
            borderColor: '$feedbackColorHelperPure',

            '&:hover': {
              backgroundColor: '$neutralColorHighPure',
              borderColor: '$feedbackColorHelperPure',
            },
          },
        },
      },
    },
    radio: {
      normal: {
        '& .form-check-input[type="radio"]': {
          borderRadius: '$borderRadiusCircular',
          backgroundColor: '$neutralColorHighPure',
          borderColor: '$neutralColorHighDark',

          '&:hover': {
            backgroundColor: '$neutralColorHighPure',
            borderColor: '$feedbackColorHelperPure',
          },

          '&:checked': {
            backgroundColor: '$feedbackColorHelperPure',
            backgroundImage: `url(${RadioChecked})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: '60% 50%',
            backgroundSize: 'auto',
            borderColor: '$feedbackColorHelperPure',

            '&:hover': {
              backgroundColor: '$feedbackColorHelperLight',
              backgroundImage: `url(${RadioCheckedHover})`,
              backgroundRepeat: 'no-repeat',
              backgroundPosition: '60% 50%',
              backgroundSize: 'auto',
              borderColor: '$feedbackColorHelperLight',
            },

            '&:focus, &:focus-visible': {
              backgroundColor: '$highlightColorDark',
              backgroundImage: `url(${RadioCheckedFocus})`,
              backgroundRepeat: 'no-repeat',
              backgroundPosition: '60% 50%',
              backgroundSize: 'auto',
              borderColor: '$highlightColorDark',
              boxShadow: 'none',
              outline: 0,
            },

            '&:disabled': {
              backgroundColor: '$neutralColorHighLight',
              backgroundImage: `url(${RadioDisabled})`,
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              backgroundSize: 'auto',
              borderColor: '$neutralColorHighMedium',
            },
          },

          '&:focus, &:focus-visible': {
            backgroundColor: '$neutralColorHighPure',
            borderColor: '$highlightColorDark',
            boxShadow: 'none',
            outline: 0,
          },

          '&:disabled': {
            backgroundColor: '$neutralColorHighLight',
            borderColor: '$neutralColorHighMedium',

            '& ~ .form-check-label': {
              color: '$neutralColorLowLight',
            },
          },
        },
      },
      hover: {
        '& .form-check-input[type="radio"]': {
          borderRadius: '$borderRadiusCircular',
          backgroundColor: '$neutralColorHighPure',
          borderColor: '$feedbackColorHelperPure',

          '&:hover': {
            backgroundColor: '$neutralColorHighPure',
            borderColor: '$feedbackColorHelperPure',
          },

          '&:checked': {
            backgroundColor: '$feedbackColorHelperLight',
            backgroundImage: `url(${RadioCheckedHover})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: '50% 45%',
            backgroundSize: 'auto',
            borderColor: '$feedbackColorHelperLight',

            '&:hover': {
              backgroundColor: '$feedbackColorHelperLight',
              backgroundImage: `url(${RadioCheckedHover})`,
              backgroundRepeat: 'no-repeat',
              backgroundPosition: '50% 45%',
              backgroundSize: 'auto',
              borderColor: '$feedbackColorHelperLight',
            },

            '&:focus, &:focus-visible': {
              backgroundColor: '$highlightColorDark',
              backgroundImage: `url(${RadioCheckedFocus})`,
              backgroundRepeat: 'no-repeat',
              backgroundPosition: '50% 45%',
              backgroundSize: 'auto',
              borderColor: '$highlightColorDark',
              boxShadow: 'none',
              outline: 0,
            },

            '&:disabled': {
              backgroundColor: '$neutralColorHighLight',
              backgroundImage: `url(${RadioDisabled})`,
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              backgroundSize: 'auto',
              borderColor: '$neutralColorHighMedium',
            },
          },

          '&:focus, &:focus-visible': {
            backgroundColor: '$neutralColorHighPure',
            borderColor: '$highlightColorDark',
            boxShadow: 'none',
            outline: 0,
          },

          '&:disabled': {
            backgroundColor: '$neutralColorHighLight',
            borderColor: '$neutralColorHighMedium',

            '& ~ .form-check-label': {
              color: '$neutralColorLowLight',
            },
          },
        },
      },
      focus: {
        '& .form-check-input[type="radio"]': {
          backgroundColor: '$neutralColorHighPure',
          borderColor: '$highlightColorDark',
          boxShadow: 'none',
          outline: 0,

          '&:hover': {
            backgroundColor: '$neutralColorHighPure',
            borderColor: '$feedbackColorHelperPure',
          },

          '&:checked': {
            backgroundColor: '$highlightColorDark',
            backgroundImage: `url(${RadioCheckedFocus})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: '60% 50%',
            backgroundSize: 'auto',
            borderColor: '$highlightColorDark',
            boxShadow: 'none',
            outline: 0,

            '&:hover': {
              backgroundColor: '$feedbackColorHelperLight',
              backgroundImage: `url(${RadioCheckedHover})`,
              backgroundRepeat: 'no-repeat',
              backgroundPosition: '60% 50%',
              backgroundSize: 'auto',
              borderColor: '$feedbackColorHelperLight',
            },

            '&:focus, &:focus-visible': {
              backgroundColor: '$highlightColorDark',
              backgroundImage: `url(${RadioCheckedFocus})`,
              backgroundRepeat: 'no-repeat',
              backgroundPosition: '60% 50%',
              backgroundSize: 'auto',
              borderColor: '$highlightColorDark',
              boxShadow: 'none',
              outline: 0,
            },

            '&:disabled': {
              backgroundColor: '$neutralColorHighLight',
              backgroundImage: `url(${RadioDisabled})`,
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              backgroundSize: 'auto',
              borderColor: '$neutralColorHighMedium',
            },
          },

          '&:focus, &:focus-visible': {
            backgroundColor: '$neutralColorHighPure',
            borderColor: '$highlightColorDark',
            boxShadow: 'none',
            outline: 0,
          },

          '&:disabled': {
            backgroundColor: '$neutralColorHighLight',
            borderColor: '$neutralColorHighMedium',

            '& ~ .form-check-label': {
              color: '$neutralColorLowLight',
            },
          },
        },
      },
    },
    switch: {
      normal: {
        '& .form-check-input': {
          width: 48,
          height: 24,
          margin: 0,
          marginRight: 8,
          borderWidth: 0,
          backgroundColor: '$neutralColorLowLight',
          backgroundImage: `url(${Switch})`,
          backgroundPosition: '1px center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'auto',

          '&:checked': {
            backgroundPosition: '26px center',
            backgroundColor: '$feedbackColorHelperPure',
            backgroundImage: `url(${Switch})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'auto',

            '&:focus, &:focus-visible': {
              backgroundPosition: '22px center',
              backgroundColor: '$feedbackColorHelperPure',
              backgroundImage: `url(${SwitchFocus})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'auto',
              boxShadow: 'none',
              outline: 0,
            },

            '&:disabled': {
              backgroundColor: '$neutralColorHighMedium',
            },
          },

          '&:focus, &:focus-visible': {
            backgroundImage: `url(${SwitchFocus})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: '1px center',
            backgroundSize: 'auto',
            boxShadow: 'none',
            outline: 0,
          },

          '&:disabled': {
            backgroundColor: '$neutralColorHighMedium',
          },
        },

        '&.switch-input': {
          position: 'relative',
          top: '-13px',
        },
      },
      focusUnchecked: {
        '& .form-check-input': {
          width: 48,
          height: 24,
          margin: 0,
          marginRight: 8,
          borderWidth: 0,
          backgroundColor: '$neutralColorLowLight',
          backgroundImage: `url(${SwitchFocus})`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: '1px center',
          backgroundSize: 'auto',
          boxShadow: 'none',
          outline: 0,

          '&:checked': {
            backgroundPosition: '26px center',
            backgroundColor: '$feedbackColorHelperPure',
            backgroundImage: `url(${Switch})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'auto',

            '&:focus, &:focus-visible': {
              backgroundPosition: '22px center',
              backgroundColor: '$feedbackColorHelperPure',
              backgroundImage: `url(${SwitchFocus})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'auto',
              boxShadow: 'none',
              outline: 0,
            },

            '&:disabled': {
              backgroundColor: '$neutralColorHighMedium',
            },
          },

          '&:focus, &:focus-visible': {
            backgroundImage: `url(${SwitchFocus})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: '1px center',
            backgroundSize: 'auto',
            boxShadow: 'none',
            outline: 0,
          },

          '&:disabled': {
            backgroundColor: '$neutralColorHighMedium',
          },
        },
      },
      focusChecked: {
        '& .form-check-input': {
          width: 48,
          height: 24,
          margin: 0,
          marginRight: 8,
          borderWidth: 0,
          backgroundColor: '$neutralColorLowLight',
          backgroundImage: `url(${SwitchFocus})`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: '1px center',
          backgroundSize: 'auto',
          boxShadow: 'none',
          outline: 0,

          '&:checked': {
            backgroundPosition: '22px center',
            backgroundColor: '$feedbackColorHelperPure',
            backgroundImage: `url(${SwitchFocus})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'auto',
            boxShadow: 'none',
            outline: 0,

            '&:focus, &:focus-visible': {
              backgroundPosition: '22px center',
              backgroundColor: '$feedbackColorHelperPure',
              backgroundImage: `url(${SwitchFocus})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'auto',
              boxShadow: 'none',
              outline: 0,
            },

            '&:disabled': {
              backgroundColor: '$neutralColorHighMedium',
            },
          },

          '&:focus, &:focus-visible': {
            backgroundImage: `url(${SwitchFocus})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: '1px center',
            backgroundSize: 'auto',
            boxShadow: 'none',
            outline: 0,
          },

          '&:disabled': {
            backgroundColor: '$neutralColorHighMedium',
          },
        },
      },
    },
  },
});
