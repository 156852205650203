import React, { useContext, useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import CreateCampaignPage from './create-campaign-page'
import exampleLayout1 from 'cashin-design-system/Styles/img/ExampleLayout/miniatura-1.png'
import exampleLayout2 from 'cashin-design-system/Styles/img/ExampleLayout/miniatura-2.png'
import exampleLayout3 from 'cashin-design-system/Styles/img/ExampleLayout/miniatura-3.png'
import { CampaignContext } from '../context/campaign-context'
import { Auth } from 'aws-amplify'
import moment from 'moment'
import useIdentificationInfo from '../API/queries/company/useIdentificationInfo'
import qs from 'qs'
import api from '../API/api'
import { toast } from 'react-toastify'

const defaultState = {
  campaignNameValue: '',
  campaignNameError: '',
  dateRange: [],
  startDateError: '',
  endDateError: '',
  fileInputError: '',
  isInvalidCampaignName: false,
  isInvalidStartDate: false,
  isInvalidEndDate: false,
  isInvalidFileInput: false,
  breadcrumb: [
    {
      url: '/campaigns',
      itemName: 'Campanhas',
    },
    {
      isActive: true,
      itemName: 'Criar campanha',
    },
  ],
  isStepActive: {
    0: false,
    1: false,
    2: false,
    3: false,
    4: false,
  },
  isStepDone: {
    0: false,
    1: false,
    2: false,
    3: false,
    4: false,
  },
  activeStep: null,
  selectedFiles: null,
  selectedFilesBatch: null,
  isRuleActive: {
    0: false,
    1: false,
    2: false,
  },
  hitOptions: [
    {
      value: 0,
      label: 'R$',
    },
    {
      value: 1,
      label: '%',
    },
  ],
  hitConditionsOptions: [
    {
      value: 1,
      label: 'e',
    },
    {
      value: 2,
      label: 'Ou',
    },
  ],
  tookOptions: [
    {
      value: 1,
      label: '%',
    },
    {
      value: 2,
      label: 'R$',
    },
  ],
  tookConditionsOptions: [
    {
      value: 1,
      label: 'e',
    },
    {
      value: 2,
      label: 'Ou',
    },
  ],
  swtDisableHit: false,
  hitConditions: '',
  hitConditionsError: '',
  isHitConditionsInvalid: false,
  swtDisableTook: false,
  tookConditions: '',
  tookConditionsError: '',
  isTookConditionsInvalid: false,
  swtEmailComunication: false,
  swtWhatsAppComunication: false,
  isEmptyFile: true,
  isEmptyFileBatch: true,
  isLayoutActive: {
    0: false,
    1: false,
    2: false,
    3: false,
    4: false,
  },
  isLayoutSelectorActive: {
    0: false,
    1: false,
    2: false,
    3: false,
  },
  layoutTitleChoosen: '',
  hideEngagement: true,
}

const weekToIndicesMappingApp = {
  0: [0, 13],
  1: [0, 13],
  2: [0, 1, 13],
  3: [0, 1, 12, 13],
  4: [0, 1, 6, 12, 13],
  5: [0, 1, 6, 3, 12, 13],
  6: [0, 1, 2, 3, 6, 12, 13],
  7: [0, 1, 2, 3, 6, 5, 12, 13],
  8: [0, 1, 2, 3, 6, 5, 8, 12, 13],
  9: [0, 1, 2, 3, 4, 5, 6, 8, 12, 13],
  10: [0, 1, 2, 3, 4, 5, 6, 7, 8, 12, 13],
  11: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 12, 13],
  12: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 12, 13],
  13: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13],
}

const steps = [
  {
    stepTitle: 'Participantes',
    stepSubTitle: 'Envie os participantes da',
    stepSubTitle2: 'sua campanha',
    stepIcon: 'supervised_user_circle',
  },
  {
    stepTitle: 'Criar regras',
    stepSubTitle: 'Crie as regras da sua',
    stepSubTitle2: 'campanha',
    stepIcon: 'rule',
  },
  {
    stepTitle: 'Engajamento',
    stepSubTitle: 'Monte sua estratégia de',
    stepSubTitle2: 'engajamento',
    stepIcon: 'campaign',
  },
  {
    stepTitle: 'Layout',
    stepSubTitle: 'Escolha seu e customize',
    stepSubTitle2: 'seu layout',
    stepIcon: 'design_services',
  },
  {
    stepTitle: 'Resumo',
    stepSubTitle: 'Confira se está tudo',
    stepSubTitle2: 'certo e publique',
    stepIcon: 'spellcheck',
  },
]

const layoutEmail = [
  {
    iconName: '',
    imageExample: exampleLayout1,
    title1: '',
    title2: '',
    title3: '',
    subTitle: 'Moderno',
  },
  {
    iconName: '',
    imageExample: exampleLayout2,
    title1: '',
    title2: '',
    title3: '',
    subTitle: 'Divertido',
  },
  {
    iconName: '',
    imageExample: exampleLayout3,
    title1: '',
    title2: '',
    title3: '',
    subTitle: 'Executivo',
  },
]

const layoutWhatsApp = [
  {
    iconName: 'format_align_right',
    imageExample: '',
    title1: 'Edite o conteúdo do',
    title2: 'seu layout.',
    title3: '',
    subTitle: 'WhatsApp padrão',
  },
]

const layoutSelection = [
  {
    layoutTitle: 'Boas vindas',
    layoutDimensions: '600x1200px',
  },
  {
    layoutTitle: 'Dica para bater a meta',
    layoutDimensions: '600x1200px',
  },
  {
    layoutTitle: 'Motivacional',
    layoutDimensions: '600x1200px',
  },
  {
    layoutTitle: 'Lembrete de Período',
    layoutDimensions: '600x1200px',
  },
  {
    layoutTitle: 'Como conhecer o seu Produto',
    layoutDimensions: '600x1200px',
  },
  {
    layoutTitle: 'Pergunta de Engajamento',
    layoutDimensions: '600x1200px',
  },
  {
    layoutTitle: 'Lembrete de Período',
    layoutDimensions: '600x1200px',
  },
  {
    layoutTitle: 'Motivacional',
    layoutDimensions: '600x1200px',
  },
  {
    layoutTitle: 'Relembre suas Regras',
    layoutDimensions: '600x1200px',
  },
  {
    layoutTitle: 'Motivacional',
    layoutDimensions: '600x1200px',
  },
  {
    layoutTitle: 'Pergunta de Engajamento',
    layoutDimensions: '600x1200px',
  },
  {
    layoutTitle: 'Campanha Encerrada',
    layoutDimensions: '600x1200px',
  },
]

const layoutSelectionApp = [
  {
    layoutTitle: 'Notificação',
    layoutDimensions: '600x1200px',
  },
  {
    layoutTitle: 'Notificação',
    layoutDimensions: '600x1200px',
  },
  {
    layoutTitle: 'Notificação',
    layoutDimensions: '600x1200px',
  },
  {
    layoutTitle: 'Notificação',
    layoutDimensions: '600x1200px',
  },
  {
    layoutTitle: 'Notificação',
    layoutDimensions: '600x1200px',
  },
  {
    layoutTitle: 'Notificação',
    layoutDimensions: '600x1200px',
  },
  {
    layoutTitle: 'Notificação',
    layoutDimensions: '600x1200px',
  },
  {
    layoutTitle: 'Notificação',
    layoutDimensions: '600x1200px',
  },
  {
    layoutTitle: 'Notificação',
    layoutDimensions: '600x1200px',
  },
  {
    layoutTitle: 'Notificação',
    layoutDimensions: '600x1200px',
  },
  {
    layoutTitle: 'Notificação',
    layoutDimensions: '600x1200px',
  },
  {
    layoutTitle: 'Notificação',
    layoutDimensions: '600x1200px',
  },
  {
    layoutTitle: 'Notificação',
    layoutDimensions: '600x1200px',
  },
  {
    layoutTitle: 'Notificação',
    layoutDimensions: '600x1200px',
  },
]

const CreateCampaign = ({ styles, history }) => {
  const { data: indentificantionData } = useIdentificationInfo()
  const campaignContext = useContext(CampaignContext)
  const [ownState, setOwnState] = useState(defaultState)
  const [startDateValue, onChangeStartDate] = useState(new Date())
  const [endDateValue, onChangeEndDate] = useState(new Date())

  const [show, setShow] = useState(false)
  const [showSendTest, setShowSendTest] = useState(false)
  const [showSendTestConfirmation, setShowSendTestConfirmation] =
    useState(false)
  const [currentUser, setCurrentUser] = useState({})
  const [nextEngajament, setNextEngajament] = useState(0)

  const [selectedWeeks, setSelectedWeeks] = useState([
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
  ])

  const [wppMessageAttributes, setWppMessageAttributes] = useState([
    {
      template: 'Folder de boas vindas',
      id:
        nextEngajament === 1
          ? 'default_whatsapp_welcome'
          : 'default_whatsapp_welcome_2',
      title: '',
      introduction: '',
      description: '',
      addParticipationButton: true,
      attachmentFilePath: '',
    },
    {
      template: 'Folder de dica para bater a meta',
      id:
        nextEngajament === 1
          ? 'default_tip_reach_goal'
          : 'default_tip_reach_goal_2',
      title: '',
      introduction: '',
      description: '',
      addParticipationButton: true,
      attachmentFilePath: '',
    },
    {
      template: 'Folder motivacional 1',
      id:
        nextEngajament === 1
          ? 'default_motivational'
          : 'default_motivational_2',
      title: '',
      introduction: '',
      description: '',
      addParticipationButton: true,
      attachmentFilePath: '',
    },
    {
      template: 'Folder de lembrete de período 1',
      id:
        nextEngajament === 1
          ? 'default_period_reminder'
          : 'default_period_reminder_2',
      title: '',
      introduction: '',
      description: '',
      addParticipationButton: true,
      attachmentFilePath: '',
    },
    {
      template: 'Folder de como conhecer o seu produto',
      id:
        nextEngajament === 1
          ? 'default_how_to_know_your_product'
          : 'default_how_to_know_your_product_2',
      title: '',
      introduction: '',
      description: '',
      addParticipationButton: true,
      attachmentFilePath: '',
    },
    {
      template: 'Folder de pergunta de engajamento 2',
      id:
        nextEngajament === 1
          ? 'default_engagement_question2'
          : 'default_engagement_question2_2',
      title: '',
      introduction: '',
      description: '',
      addParticipationButton: true,
      attachmentFilePath: '',
    },
    {
      template: 'Folder de lembrete de período 2',
      id:
        nextEngajament === 1
          ? 'default_period_reminder2'
          : 'default_period_reminder2_2',
      title: '',
      introduction: '',
      description: '',
      addParticipationButton: true,
      attachmentFilePath: '',
    },
    {
      template: 'Folder motivacional 3',
      id:
        nextEngajament === 1
          ? 'default_motivational3'
          : 'default_motivational3_2',
      title: '',
      introduction: '',
      description: '',
      addParticipationButton: true,
      attachmentFilePath: '',
    },
    {
      template: 'Folder relembre suas regras',
      id:
        nextEngajament === 1
          ? 'default_remember_your_rules'
          : 'default_remember_your_rules_2',
      title: '',
      introduction: '',
      description: '',
      addParticipationButton: true,
      attachmentFilePath: '',
    },
    {
      template: 'Folder motivacional 2',
      id:
        nextEngajament === 1
          ? 'default_motivational2'
          : 'default_motivational2_2',
      title: '',
      introduction: '',
      description: '',
      addParticipationButton: true,
      attachmentFilePath: '',
    },
    {
      template: 'Folder de pergunta de engajamento 1',
      id:
        nextEngajament === 1
          ? 'default_engagement_question'
          : 'default_engagement_question_2',
      title: '',
      introduction: '',
      description: '',
      addParticipationButton: true,
      attachmentFilePath: '',
    },
    {
      template: 'Folder de campanha encerrada',
      id: nextEngajament === 1 ? 'default_thanks' : 'default_thanks_2',
      title: '',
      introduction: '',
      description: '',
      addParticipationButton: true,
      attachmentFilePath: '',
    },
  ])

  const [wppMessageAttributesDefault, setWppMessageAttributesDefault] =
    useState([])

  const [appMessageAttributesDefault, setAppMessageAttributesDefault] =
    useState([])

  useEffect(() => {
    setWppMessageAttributesDefault([
      {
        template: 'Folder de boas vindas',
        id:
          nextEngajament === 1
            ? 'default_whatsapp_welcome'
            : 'default_whatsapp_welcome_2',
        title:
          nextEngajament === 1
            ? 'VENHA PARTICIPAR DA CAMPANHA! 🤩'
            : 'CAMPANHA NOVA COMEÇANDO 🤩',
        introduction:
          nextEngajament === 1
            ? 'Bem-vindo! Você foi convidado para participar da **Campanha {{campanha}}** da _{{empresa}}_ no período de {{inicio}} a {{fim}}.'
            : 'Bem-vindo! Você foi convidado para participar da **Campanha {{campanha}}** da _{{empresa}}_ no período de {{inicio}} a {{fim}}.',
        description:
          nextEngajament === 1
            ? 'Alcance a seguinte meta para ganhar:\n**META**: {{meta}}\n\n**PRÊMIO**: {{prêmio}} 🏆\n\nMotivos para você participar dessa Campanha:\n1️⃣ Utilize sua premiação sem burocracia;\n2️⃣ Pagamento inteligente, seguro e 100% digital;\n3️⃣ Comemore seus cashins e a sua participação.\n\nFicou com alguma dúvida? Entre em contato com a sua empresa.\n{{site}} {{regulamento}}\nVocê quer participar da campanha?'
            : 'Alcance a seguinte meta para ganhar:\n**META**: {{meta}}\n\n**PRÊMIO**: {{prêmio}} 🏆\n\nPor que você deve participar dessa campanha?\n1️⃣ A premiação é feita de forma descomplicada e 100% digital;\n2️⃣ Receba seu prêmio com inteligência e segurança;\n3️⃣ 6 módulos de resgate na palma da mão.\n\nFicou com alguma dúvida? Entre em contato com a sua empresa.\n{{site}}\n{{regulamento}}\nVocê quer participar da campanha?',
        addParticipationButton: true,
        attachmentFilePath: '',
        actions: [nextEngajament === 1 ? 'Participar' : 'Participar'],
      },
      {
        template: 'Folder de dica para bater a meta',
        id:
          nextEngajament === 1
            ? 'default_tip_reach_goal'
            : 'default_tip_reach_goal_2',
        title:
          nextEngajament === 1
            ? 'Oba! Aqui vão 4 dicas para alcançar as suas metas!!! 🎉'
            : 'Uhu! Aqui vão algumas dicas que podem te ajudar no discurso para conquistar o cliente!!! 🎉',
        introduction:
          nextEngajament === 1
            ? 'Você está participando da **Campanha {{campanha}}** da _{{empresa}}_ no período de {{inicio}} a {{fim}}.'
            : 'Você está participando da **Campanha {{campanha}}** da _{{empresa}}_ no período de {{inicio}} a {{fim}}.',
        description:
          nextEngajament === 1
            ? '1️⃣ Seja constante na sua rotina;\n2️⃣ Para alcançar sua meta é importante conhecer o seu produto ou serviço;\n3️⃣ Gerencie seu tempo. Faça listas, estipule objetivos e eliminando gargalos;\n4️⃣ Ao promover o seu produto ou serviço venda valor além de preço.\n\nComo está indo a sua meta?\n\n{{site}}\n{{regulamento}}'
            : '1️⃣ Conheça muito bem seu produto ou serviço;\n2️⃣ Planeje seus diálogos, assim poderá recalcular a rota de acordo com a necessidade do cliente;\n3️⃣ Crie um roteiro mostrando todos os pontos fortes e esteja preparado para dúvidas;\n4️⃣ Não tenha medo de colocar em prática.\n\n{{site}}\n{{regulamento}}',
        addParticipationButton: true,
        attachmentFilePath: '',
      },
      {
        template: 'Folder motivacional 1',
        id:
          nextEngajament === 1
            ? 'default_motivational'
            : 'default_motivational_2',
        title:
          nextEngajament === 1
            ? 'Vem que é apenas o começo da nossa campanha! 💁🏽‍♀️💁🏻‍♂'
            : 'Estamos só no começo! 💁🏽‍♀️💁🏻‍♂️',
        introduction:
          nextEngajament === 1
            ? 'Você está participando da **Campanha {{campanha}}** da _{{empresa}}_ no período de {{inicio}} a {{fim}}.'
            : 'Você está participando da **Campanha {{campanha}}** da _{{empresa}}_ no período de {{inicio}} a {{fim}}.',
        description:
          nextEngajament === 1
            ? 'Algumas pessoas querem que algo aconteça, outras fazem acontecer.\nFaça a sua meta acontecer. 🚀\n\n{{site}}\n{{regulamento}}'
            : 'Só vence amanhã quem não desiste hoje.\nVamos pra cima!👊🏼\n\nEsta preparado pra essa jornada?\n\n{{site}}\n{{regulamento}}',
        addParticipationButton: true,
        attachmentFilePath: '',
        actions: [
          nextEngajament === 1 ? 'Estou fazendo acontecer!' : 'Estou prepado',
          nextEngajament === 1 ? 'Estou com dúvidas' : 'Estou com dúvidas',
        ],
      },
      {
        template: 'Folder de lembrete de período 1',
        id:
          nextEngajament === 1
            ? 'default_period_reminder'
            : 'default_period_reminder_2',
        title:
          nextEngajament === 1
            ? 'Corre que ainda da tempo!!! 🏃🏼‍♀️🏃🏾'
            : 'Psiu! Ainda está em tempo de alcançar a meta!!! 🏃🏼‍♀️🏃🏾',
        introduction:
          nextEngajament === 1
            ? 'Você está participando da **Campanha {{campanha}}** da _{{empresa}}_ no período de {{inicio}} a {{fim}}.'
            : 'Você está participando da **Campanha {{campanha}}** da _{{empresa}}_ no período de {{inicio}} a {{fim}}.',
        description:
          nextEngajament === 1
            ? 'Alcance a seguinte meta para ganhar:\n**META**: {{meta}}\n\n**PRÊMIO**: {{prêmio}} 🏆\n\nAinda dá tempo de pegar o ritmo e correr atrás da meta. 😊\nVocê esta conseguindo alcançar a meta? \n\n{{site}}\n{{regulamento}}'
            : 'Alcance a seguinte meta para ganhar:\n**META**: {{meta}}\n\n**PRÊMIO**: {{prêmio}} 🏆\nEstamos em tempo de pegar fôlego e alcançar a meta. 😊\n\n{{site}}\n{{regulamento}}',
        addParticipationButton: true,
        attachmentFilePath: '',
        actions: [
          nextEngajament === 1 ? 'Estou sim' : '',
          nextEngajament === 1 ? 'Mais ou menos' : '',
        ],
      },
      {
        template: 'Folder de como conhecer o seu produto',
        id:
          nextEngajament === 1
            ? 'default_how_to_know_your_product'
            : 'default_how_to_know_your_product_2',
        title:
          nextEngajament === 1
            ? 'Oie! Ai vai um check list para conhecer seu produto ou serviço!!! 🤓'
            : 'Oie! Ai vai um check list para a venda do seu produto ou serviço!!! 🤓',
        introduction:
          nextEngajament === 1
            ? 'Você está participando da **Campanha {{campanha}}** da _{{empresa}}_ no período de {{inicio}} a {{fim}}.'
            : 'Você está participando da **Campanha {{campanha}}** da _{{empresa}}_ no período de {{inicio}} a {{fim}}.',
        description:
          nextEngajament === 1
            ? '✔️ Sua experiência faz diferença;\n✔️ Faça pesquisas, conheça o seu produto ou serviço;\n✔️ O feedback do seu cliente é super importante;\n✔️ Troque experiência com outros participantes.\n\n{{site}}\n{{regulamento}}'
            : '✔️ Ajude o cliente a entender o que ele precisa;\n✔️ Crie senso de urgência;\n✔️ Acredite e defenda o seu produto ou serviço;\n✔️ Conheça seu público alvo.\n\nVocê já aplica essas técnicas no momento da venda?\n\n{{site}}\n{{regulamento}}',
        addParticipationButton: true,
        attachmentFilePath: '',
      },
      {
        template: 'Folder de pergunta de engajamento 2',
        id:
          nextEngajament === 1
            ? 'default_engagement_question2'
            : 'default_engagement_question2_2',
        title:
          nextEngajament === 1
            ? 'Falta só mais um pouquinho... ⏳'
            : 'Estamos a poucos passos...',
        introduction:
          nextEngajament === 1
            ? 'Você está participando da **Campanha {{campanha}}** da _{{empresa}}_ no período de {{inicio}} a {{fim}}.'
            : 'Você está participando da **Campanha {{campanha}}** da _{{empresa}}_ no período de {{inicio}} a {{fim}}.',
        description:
          nextEngajament === 1
            ? 'A campanha está a todo vapor por aqui e por ai como esta? 🚂\nVocê está conseguindo alcançar as suas metas?\n\nAí vai algumas dicas para te ajudar:\n➡️ Respire fundo, desacelere um pouco;\n➡️ Reveja os pequenos objetivos;\n➡️ Estabeleça um plano de ação.\n\n{{site}}\n{{regulamento}}'
            : 'A campanha ainda está rolando. 🚂\nAqui vão algumas dicas para te ajudar:\n➡️ Acredite em seu potencial;\n➡️ Mantenha-se focado no seu objetivo;\n➡️ Aprenda com as dificuldades.\n\nConta para gente, você está conseguindo alcançar as suas metas?\n\n{{site}}\n{{regulamento}}',
        addParticipationButton: true,
        attachmentFilePath: '',
        actions: [
          nextEngajament === 1 ? 'A todo vapor!!!' : 'Estou sim',
          nextEngajament === 1 ? 'Mais ou menos' : 'Mais ou menos',
        ],
      },
      {
        template: 'Folder de lembrete de período 2',
        id:
          nextEngajament === 1
            ? 'default_period_reminder2'
            : 'default_period_reminder2_2',
        title:
          nextEngajament === 1
            ? 'Uhu! chegamos na última semana. 🕺🏽💃🏼'
            : 'Start na última semana da nossa campanha...',
        introduction:
          nextEngajament === 1
            ? 'Você está participando da **Campanha {{campanha}}** da _{{empresa}}_ no período de {{inicio}} a {{fim}}.'
            : 'Você está participando da **Campanha {{campanha}}** da _{{empresa}}_ no período de {{inicio}} a {{fim}}.',
        description:
          nextEngajament === 1
            ? 'Alcance a seguinte meta para ganhar:\n**META**: {{meta}}\n\n**PRÊMIO**: {{prêmio}} 🏆\n\nBatendo a sua meta você poderá...\n📌 Ganhar cashins para usar de forma 100% online;\n📌 Ganhar destaque de acordo com o seu desempenho;\n📌 Identificar seus pontos positivos e investir neles.\n\n{{site}}\n{{regulamento}}'
            : 'Você já sabe como vai aproveitar os seus Cashins?\nAlcançando as suas metas você poderá:\n📌 Ganhar cashins para usar de forma totalmente descomplicada;\n📌 Trazer evidencia para as suas competências;\n📌 Se autoavaliar e agregar valor nas suas metas de vida.\nn{{site}}\n{{regulamento}}',
        addParticipationButton: true,
        attachmentFilePath: '',
      },
      {
        template: 'Folder motivacional 3',
        id:
          nextEngajament === 1
            ? 'default_motivational3'
            : 'default_motivational3_2',
        title:
          nextEngajament === 1
            ? 'É a reta final, bora lá!!! 🙋🏽‍♀️🙋🏼‍♂️'
            : 'Ei, vamos!!! Nada de perder o ritmo. 🎊😉',
        introduction:
          nextEngajament === 1
            ? 'Você está participando da **Campanha {{campanha}}** da _{{empresa}}_ no período de {{inicio}} a {{fim}}.'
            : 'Você está participando da **Campanha {{campanha}}** da _{{empresa}}_ no período de {{inicio}} a {{fim}}.',
        description:
          nextEngajament === 1
            ? 'A gente não perde quando falha. A gente perde quando desiste.\nNão desiste não!!! Bora focar na meta. 🎯\n\n{{site}}\n{{regulamento}}'
            : 'Se a escada até a meta parece estar muito longa, foque no degrau de hoje. Um passo de cada vez.\n\n{{site}}\n{{regulamento}}',
        addParticipationButton: true,
        attachmentFilePath: '',
      },
      {
        template: 'Folder relembre suas regras',
        id:
          nextEngajament === 1
            ? 'default_remember_your_rules'
            : 'default_remember_your_rules_2',
        title:
          nextEngajament === 1
            ? 'Eai! Hoje é dia de relembrar as regras... 🤔💭'
            : 'Eai! O que acha de relembrar as regras da campanha hoje? ✍🏼💭',
        introduction:
          nextEngajament === 1
            ? 'Você está participando da **Campanha {{campanha}}** da _{{empresa}}_ no período de {{inicio}} a {{fim}}.'
            : 'Você está participando da *Campanha {{campanha}}* da _{{empresa}}_ no período de {{inicio}} a {{fim}}.\nAlcance a seguinte meta para ganhar:\n**META**: {{meta}}\n**PRÊMIO**: {{prêmio}} 🏆',
        description:
          nextEngajament === 1
            ? 'Alcance a seguinte meta para ganhar:\n\n**META**: {{meta}}\n\n**PRÊMIO**: {{prêmio}} 🏆\n\nFicou com alguma dúvida? Entre em contato com a sua empresa.\nEstamos torcendo por você. 🤜🏼🤛🏾\n\n{{site}}\n{{regulamento}}'
            : 'Ficou com alguma dúvida? Entre em contato com a sua empresa.\nEstamos na torcida para que você consiga alcançar a meta e aproveitar sua premiação Cashin. 🤜🏼🤛🏾\n{{site}}\n{{regulamento}}',
        addParticipationButton: true,
        attachmentFilePath: '',
      },
      {
        template: 'Folder motivacional 2',
        id:
          nextEngajament === 1
            ? 'default_motivational2'
            : 'default_motivational2_2',
        title:
          nextEngajament === 1
            ? 'Psiu... Nada de desanimo!!! 🎊😉'
            : 'A linha de chegada é logo ali!!! 🙋🏽‍♀️🙋🏼‍♂️',
        introduction:
          nextEngajament === 1
            ? 'Você está participando da **Campanha {{campanha}}** da _{{empresa}}_ no período de {{inicio}} a {{fim}}.'
            : 'Você está participando da **Campanha {{campanha}}** da _{{empresa}}_ no período de {{inicio}} a {{fim}}.',
        description:
          nextEngajament === 1
            ? 'Você pode tudo, menos desistir. Não fique pensando no que poderia ter feito antes, ainda da tempo!!!\n\n{{site}}\n{{regulamento}}'
            : 'Se você quer ser o melhor, deve fazer coisas que os outros não estão dispostos a fazer.\nSe coloque a disposição, você vai ver como é capaz.\n\nComo tem sido a campanha para você?\n{{site}}\n{{regulamento}}',
        addParticipationButton: true,
        attachmentFilePath: '',
        actions: [
          nextEngajament === 1 ? '' : 'Estou indo bem',
          nextEngajament === 1 ? '' : 'Estou com dúvidas',
        ],
      },
      {
        template: 'Folder de pergunta de engajamento 1',
        id:
          nextEngajament === 1
            ? 'default_engagement_question'
            : 'default_engagement_question_2',
        title:
          nextEngajament === 1
            ? 'Lembrete importante para você!!! ⚠️'
            : 'Hoje é dia de refletir!!! 🤔',
        introduction:
          nextEngajament === 1
            ? 'Você está participando da **Campanha {{campanha}}** da _{{empresa}}_ no período de {{inicio}} a {{fim}}.'
            : 'Você está participando da **Campanha {{campanha}}** da _{{empresa}}_ no período de {{inicio}} a {{fim}}.',
        description:
          nextEngajament === 1
            ? 'Você tem celebrado suas pequenas conquistas?\nNós acreditamos que comemorar pequenas metas aumenta o bem-estar, te faz lembrar que é capaz e eleva sua autoestima. Comemore!!! 🥳\n\n{{site}}\n{{regulamento}}'
            : 'Sabe os pequenos objetivos que fazem parte do processo até a sua meta?\nVocê tem valorizado eles?\nOlhar para as pequenas conquistas diárias te faz lembrar que é capaz e eleva sua autoestima. Valorize e comemore!!! 🥳\n\nAs suas metas estão sendo alcançadas?\n{{site}}\n{{regulamento}}',
        addParticipationButton: true,
        attachmentFilePath: '',
        actions: [
          nextEngajament === 1 ? 'Estou batendo a meta' : 'Sim',
          nextEngajament === 1 ? 'Estou com dúvidas' : 'Mais ou menos',
        ],
      },
      {
        template: 'Folder de campanha encerrada',
        id: nextEngajament === 1 ? 'default_thanks' : 'default_thanks_2',
        title:
          nextEngajament === 1
            ? 'Acabou! Obrigada por participar com a gente. 🎊🎉🥳'
            : 'Campanha concluída com sucesso! 🎊🎉🥳',
        introduction:
          nextEngajament === 1
            ? 'Você participou da **Campanha {{campanha}}** da _{{empresa}}_ no período de {{inicio}} a {{fim}}.'
            : 'Você participou da **Campanha {{campanha}}** da _{{empresa}}_ no período de {{inicio}} a {{fim}}.',
        description:
          nextEngajament === 1
            ? 'Sua premiação será liberada dentro de alguns dias, você vai receber um sms ou e-mail para se cadastrar e usar seus cashins.\nSeu prêmio na mão, de forma 100% digital, com muita segurança e liberdade.\nFicou com alguma dúvida? Entre em contato com a sua empresa.\n\n{{site}}\n{{regulamento}}'
            : 'Aguarde, a premiação será liberada dentro de alguns dias, você vai receber um sms ou e-mail para se cadastrar e usar seus cashins. 😉.\n\nVocê poderá aproveitar de forma simples, 100% digital, com muita segurança e liberdade.\n\n\nFicou com alguma dúvida? Entre em contato com a sua empresa.\n\n{{site}}\n{{regulamento}}',
        addParticipationButton: true,
        attachmentFilePath: '',
      },
    ])
    campaignContext.setEngajamentSelected(nextEngajament)
  }, [nextEngajament])

  useEffect(() => {
    setAppMessageAttributesDefault([
      {
        template: 'Folder de boas vindas',
        id: 'default_app_welcome',
        title: 'Boas-vindas',
        description:
          'Oie, nova campanha no ar! 🤩🥳 Você foi selecionado para concorrer a prêmios da {{empresa}}, acesse o App e confira suas metas.',
        segmentation: '',
        recurrence: false,
        startDate: '{{inicio}}',
        endDate: '{{fim}}',
        dateSend: '{{data_envio}}',
      },
      {
        template: 'Folder de dica para bater a meta',
        id: 'default_app_tip_reach_goal',
        title: 'O app é o seu aliado na busca pelo sucesso! 🌟',
        description:
          'Explore as campanhas, conquiste objetivos e seja a estrela da sua história! 🚀',
        segmentation: '',
        recurrence: false,
        startDate: '{{inicio}}',
        endDate: '{{fim}}',
        dateSend: '{{data_envio}}',
      },
      {
        template: 'Folder motivacional 1',
        id: 'default_app_motivational',
        title: 'Dicas para bater a sua meta 💡',
        description:
          '1° Seja constante na sua rotina; 2° Conheça o seu produto ou serviço; 3° Gerencie seu tempo; 4° Venda valor além de preço. ✍🏼 ✔️',
        segmentation: '',
        recurrence: false,
        startDate: '{{inicio}}',
        endDate: '{{fim}}',
        dateSend: '{{data_envio}}',
      },
      {
        template: 'Folder de lembrete de período 1',
        id: 'default_app_period_reminder',
        title: 'Explore, Conquiste, Compartilhe! 🌈',
        description:
          'Acesse o app e torne cada campanha uma página inesquecível da sua jornada de sucesso! 🌟',
        segmentation: '',
        recurrence: false,
        startDate: '{{inicio}}',
        endDate: '{{fim}}',
        dateSend: '{{data_envio}}',
      },
      {
        template: 'Folder de como conhecer o seu produto',
        id: 'default_app_how_to_know_your_product',
        title: 'Alerta Desafio da Semana ⚠️',
        description:
          'Esta semana, o desafio é expressar gratidão. Partilhe algo pelo qual é grato(a) e inspire os outros a fazerem o mesmo. Boa sorte! 🙏🏽🍀',
        segmentation: '',
        recurrence: false,
        startDate: '{{inicio}}',
        endDate: '{{fim}}',
        dateSend: '{{data_envio}}',
      },
      {
        template: 'Folder de pergunta de engajamento 2',
        id: 'default_app_engagement_question2',
        title: 'A magia acontece no app! 💫💖',
        description:
          'Acesse às campanhas, compartilhe suas vitórias nos comentários e faça parte dessa rede de sucesso!',
        segmentation: '',
        recurrence: false,
        startDate: '{{inicio}}',
        endDate: '{{fim}}',
        dateSend: '{{data_envio}}',
      },
      {
        template: 'Folder de lembrete de período 2',
        id: 'default_app_period_reminder2',
        title: '✨ Afirmação do dia ✨',
        description:
          'Eu sou um ímã de metas! Conquistando tudo por onde passo! 👑',
        segmentation: '',
        recurrence: false,
        startDate: '{{inicio}}',
        endDate: '{{fim}}',
        dateSend: '{{data_envio}}',
      },
      {
        template: 'Folder motivacional 3',
        id: 'default_app_motivational3',
        title: 'O app é o seu playground de conquistas! 💥',
        description:
          'Explore, participe, e transforme metas em realidade! #PlayToWin',
        segmentation: '',
        recurrence: false,
        startDate: '{{inicio}}',
        endDate: '{{fim}}',
        dateSend: '{{data_envio}}',
      },
      {
        template: 'Folder relembre suas regras',
        id: 'default_app_remember_your_rules',
        title: 'Psiu... Nada de desanimo!!! 🎊😉',
        description:
          'Você pode tudo, menos desistir. Não fique pensando no que poderia ter feito antes, ainda da tempo!!!',
        segmentation: '',
        recurrence: false,
        startDate: '{{inicio}}',
        endDate: '{{fim}}',
        dateSend: '{{data_envio}}',
      },
      {
        template: 'Folder motivacional 2',
        id: 'default_app_motivational2',
        title: 'O palco das conquistas está esperando por você! 🔥 ',
        description:
          'Acesse o app agora e transforme suas metas em verdadeiras histórias de sucesso! 🌟',
        segmentation: '',
        recurrence: false,
        startDate: '{{inicio}}',
        endDate: '{{fim}}',
        dateSend: '{{data_envio}}',
      },
      {
        template: 'Folder de pergunta de engajamento 1',
        id: 'default_app_engagement_question',
        title: 'Você tem celebrado suas pequenas conquistas?',
        description:
          'Nós acreditamos que comemorar pequenas metas aumenta o bem-estar, te faz lembrar que é capaz e eleva sua autoestima. Comemore!!! 🥳',
        segmentation: '',
        recurrence: false,
        startDate: '{{inicio}}',
        endDate: '{{fim}}',
        dateSend: '{{data_envio}}',
      },
      {
        template: 'Folder de penultima semana',
        id: 'default_penultimate_week',
        title: 'É a reta final, bora lá!!! 🙋🏽‍♀️🙋🏼‍♂️',
        description:
          'A gente não perde quando falha. A gente perde quando desiste. Não desiste!!! Bora focar na meta. 🎯',
        segmentation: '',
        recurrence: false,
        startDate: '{{inicio}}',
        endDate: '{{fim}}',
        dateSend: '{{data_envio}}',
      },
      {
        template: 'Folder de ultima semana',
        id: 'default_last_week',
        title: 'Ultima semana de campanha!🎉',
        description: 'Vem acompanhar seus resultados.🤩',
        segmentation: '',
        recurrence: false,
        startDate: '{{inicio}}',
        endDate: '{{fim}}',
        dateSend: '{{data_envio}}',
      },
      {
        template: 'Folder de campanha encerrada',
        id: 'default_app_thanks',
        title: 'Obrigada por participar da campanha.🎊🎉',
        description:
          'Sua premiação será liberada dentro de alguns dias. Fique de olho no modulo de extrato no App. Seu prêmio na mão, de forma 100% digital, com muita segurança e liberdade.',
        segmentation: '',
        recurrence: false,
        startDate: '{{inicio}}',
        endDate: '{{fim}}',
        dateSend: '{{data_envio}}',
      },
    ])
  }, [])

  useEffect(() => {
    const runAsync = async () => {
      setCurrentUser(await Auth.currentAuthenticatedUser())
    }

    runAsync()
  }, [])

  useEffect(() => {
    handleGetNextEngajament()
  }, [])

  const calculateDays = () => {
    if (!campaignContext?.campaign?.periodDate) return []

    if (
      !campaignContext?.campaign?.periodDate?.from ||
      !campaignContext?.campaign?.periodDate?.to
    )
      return []

    const selectedWeek = campaignContext.getTotalDispatch()
    const indicesToCheck = weekToIndicesMappingApp[selectedWeek] || []

    const startDayWeek = moment(campaignContext?.campaign?.periodDate?.from)
    const endDayWeek = moment(campaignContext?.campaign?.periodDate?.to)

    let selectedWeeks = Array(14).fill(null)
    selectedWeeks[0] = startDayWeek.toDate()

    let currentDate = moment(startDayWeek)

    for (let i = 1; i <= 13; i++) {
      if (indicesToCheck.includes(i)) {
        if (currentDate.add(7, 'days').isBefore(endDayWeek)) {
          selectedWeeks[i] = currentDate.clone().toDate()
        }
      }
    }

    selectedWeeks[13] = endDayWeek.toDate()

    setSelectedWeeks(selectedWeeks)

    return selectedWeeks
  }

  useEffect(() => {
    calculateDays()
  }, [campaignContext.campaign.periodDate])

  useEffect(() => {
    if (!campaignContext?.campaign?.name) return
    if (!campaignContext?.campaign?.periodDate?.from) return
    if (!campaignContext?.campaign?.periodDate?.to) return

    setWppMessageAttributesDefault(
      wppMessageAttributesDefault.map((item) => {
        let newIntroduction = item.introduction
          .replace(/{{campanha}}/g, campaignContext.campaign.name)
          .replace(/{{empresa}}/g, campaignContext.companyName)
          .replace(
            /{{inicio}}/g,
            new Date(
              campaignContext.campaign.periodDate.from
            ).toLocaleDateString()
          )
          .replace(
            /{{fim}}/g,
            new Date(
              campaignContext.campaign.periodDate.to
            ).toLocaleDateString()
          )

        return {
          ...item,
          introduction: newIntroduction,
        }
      })
    )

    setAppMessageAttributesDefault(
      appMessageAttributesDefault.map((item, index) => {
        let newDescription = item.description
          .replace(/{{campanha}}/g, campaignContext.campaign.name)
          .replace(/{{empresa}}/g, campaignContext.companyName)
          .replace(
            /{{inicio}}/g,
            new Date(
              campaignContext.campaign.periodDate.from
            ).toLocaleDateString()
          )
          .replace(
            /{{fim}}/g,
            new Date(
              campaignContext.campaign.periodDate.to
            ).toLocaleDateString()
          )

        let newStartDate = item.startDate.replace(
          /{{inicio}}/g,
          new Date(
            campaignContext.campaign.periodDate.from
          ).toLocaleDateString()
        )

        let newEndDate = item.endDate.replace(
          /{{fim}}/g,
          new Date(campaignContext.campaign.periodDate.to).toLocaleDateString()
        )

        let newDateSend = ''

        if (selectedWeeks.length > 0 && selectedWeeks[index] instanceof Date) {
          if (selectedWeeks[index] !== null) {
            newDateSend = item.dateSend.replace(
              /{{data_envio}}/g,
              new Date(selectedWeeks[index]).toLocaleDateString()
            )
          }
        } else {
          newDateSend = item.dateSend
        }

        if (index === 13) {
          newDateSend = item.dateSend.replace(
            /{{data_envio}}/g,
            new Date(selectedWeeks[13]).toLocaleDateString()
          )
        }

        return {
          ...item,
          description: newDescription,
          startDate: newStartDate,
          endDate: newEndDate,
          dateSend: newDateSend,
        }
      })
    )
  }, [ownState, campaignContext])

  const handleClose = () => {
    setOwnState((val) => ({
      ...val,
      isLayoutSelectorActive: {
        ...defaultState.isLayoutSelectorActive,
        0: true,
      },
    }))
    setShow(false)
  }
  const handleCloseSendTest = (time) => {
    setShowSendTest(false)

    setTimeout(() => {
      history.push('/campaigns')
      history.go('/campaigns')
    }, time || 1000)
  }
  const handleCloseSendTestConfirmation = () => {
    setShowSendTestConfirmation(false)

    setTimeout(() => {
      history.push('/campaigns')
      history.go('/campaigns')
    }, 1000)
  }
  const handleShow = () => setShow(true)
  const handleShowSendTest = () => setShowSendTest(true)
  const handleShowSendTestConfirmation = () => setShowSendTestConfirmation(true)

  const handleGetNextEngajament = async () => {
    const queryString = qs.stringify(
      {
        'Pagination.Limit': 10000000,
        'Pagination.Offset': 1,
      },
      { skipNulls: true }
    )

    const response = await api.get(`/campaign/search?${queryString}`)

    setNextEngajament(response.nextEngajament)
  }

  const handleKeyDown = (key) => {
    if (key === 'Enter') {
      handleSearch()
    }
  }

  const handleChange = ({ target: { name, value } }) => {
    setOwnState({
      ...ownState,
      [name]: value,
    })
  }

  const handleUploadChange = (value) => {
    let files = []

    for (let x = 0; x < value.length; x++) {
      files.push({
        name: value[x].name,
        size: value[x].size,
        lastModified: value[x].lastModified,
        type: value[x].type,
      })
    }

    files.length <= 1
      ? setOwnState({
          ...ownState,
          selectedFiles: files,
          fileInputError: '',
          isInvalidFileInput: false,
          isEmptyFile: false,
        })
      : setOwnState({
          ...ownState,
          selectedFiles: null,
          fileInputError: 'O limite de upload é de no máximo 1 arquivo',
          isInvalidFileInput: true,
          isEmptyFile: true,
        })

    let inputFileInvalid = document.getElementById('fdbFileInput')

    inputFileInvalid &&
      files.length > 1 &&
      inputFileInvalid.classList.remove('invalid-feedback')
  }

  const handleUploadChangeBatch = (value) => {
    let files = []

    for (let x = 0; x < value.length; x++) {
      files.push({
        name: value[x].name,
        size: value[x].size,
        lastModified: value[x].lastModified,
        type: value[x].type,
      })
    }

    setOwnState({
      ...ownState,
      selectedFilesBatch: files,
      isEmptyFileBatch: false,
    })
  }

  const handleSearch = () => {}

  const handleRuleType = (value) => {
    const rulePositionArray = value.i

    setOwnState({
      ...ownState,
      isRuleActive: {
        0: false,
        1: false,
        2: false,
        [rulePositionArray]: true,
      },
    })
  }

  const handleChangeSwitch = ({ target: { id, checked } }) => {
    setOwnState({
      ...ownState,
      [id]: checked,
    })
  }

  const handlePlatformComunication = ({ target: { id, checked } }) => {
    setOwnState({
      ...ownState,
      swtEmailComunication: false,
      swtWhatsAppComunication: false,
      [id]: checked,
    })
  }

  const stepOne = () => {
    setOwnState({
      ...ownState,
      activeStep: 1,
      isStepActive: {
        0: true,
        1: false,
        2: false,
        3: false,
        4: false,
      },
      isStepDone: {
        0: false,
        1: false,
        2: false,
        3: false,
        4: false,
      },
    })
  }

  const stepTwo = (campaignDateValue) => {
    setOwnState({
      ...ownState,
      dateRange: campaignDateValue,
      activeStep: 2,
      isStepActive: {
        0: false,
        1: true,
        2: false,
        3: false,
        4: false,
      },
      isStepDone: {
        0: true,
        1: false,
        2: false,
        3: false,
        4: false,
      },
    })
  }

  const stepThree = () => {
    setOwnState({
      ...ownState,
      activeStep: 3,
      isStepActive: {
        0: false,
        1: false,
        2: true,
        3: false,
        4: false,
      },
      isStepDone: {
        0: true,
        1: true,
        2: false,
        3: false,
        4: false,
      },
    })
  }

  const stepFour = () => {
    setOwnState({
      ...ownState,
      activeStep: 4,
      isStepActive: {
        0: false,
        1: false,
        2: false,
        3: true,
        4: false,
      },
      isStepDone: {
        0: true,
        1: true,
        2: true,
        3: false,
        4: false,
      },
    })
  }

  const stepFive = () => {
    setOwnState({
      ...ownState,
      activeStep: 5,
      isStepActive: {
        0: false,
        1: false,
        2: false,
        3: false,
        4: true,
      },
      isStepDone: {
        0: true,
        1: true,
        2: true,
        3: true,
        4: false,
      },
    })
  }

  const handleStepBack = () => {
    if (ownState.activeStep === 1) {
      stepOne()
    } else if (ownState.activeStep === 2) {
      stepOne()
    } else if (ownState.activeStep === 3) {
      stepTwo()
    } else if (ownState.activeStep === 4) {
      stepThree()
    } else if (ownState.activeStep === 5) {
      stepFour()
    }
  }

  const handleStepForward = () => {
    let dateRange = document.getElementById('txtDateRangeCampaign')

    if (ownState.activeStep === 1) {
      stepTwo()
    } else if (ownState.activeStep === 2) {
      stepThree(dateRange?.value?.split(' - '))
    } else if (ownState.activeStep === 3) {
      stepFour()
    } else if (ownState.activeStep === 4) {
      stepFive()
    }
  }

  const handleDeleteFile = () => {
    setOwnState({
      ...ownState,
      isEmptyFile: true,
      selectedFiles: null,
    })
  }

  const handleLayoutType = (value) => {
    const rulePositionArray = value.i

    setOwnState({
      ...ownState,
      isLayoutActive: {
        0: false,
        1: false,
        2: false,
        3: false,
        4: false,
        [rulePositionArray]: true,
      },
    })
  }

  const layoutType = ownState.swtEmailComunication
    ? layoutEmail
    : layoutWhatsApp

  const handleLayoutChoosen = (value) => {
    setOwnState({
      ...ownState,
      layoutTitleChoosen: value.e.subTitle,
      layoutExampleImageChoosen: value.e.imageExample,
    })

    handleShow()
  }

  const handleLayoutSelector = (value) => {
    const rulePositionArray = value.i

    setOwnState({
      ...ownState,
      isLayoutSelectorActive: {
        0: false,
        1: false,
        2: false,
        3: false,
        [rulePositionArray]: true,
      },
    })
  }

  const companyName =
    currentUser?.attributes?.['custom:profile'] === 'Admin'
      ? indentificantionData?.find(
          (x) => x.id === currentUser?.attributes?.['custom:tenantId']
        )?.description
      : currentUser?.attributes?.name

  const handleSaveLayout = async () => {
    const rules =
      campaignContext.isSegmentation === 'yes'
        ? campaignContext.campaignState.rules
        : [campaignContext.campaignState.rule]

    const prizes =
      campaignContext.isSegmentation === 'yes'
        ? campaignContext.campaignState.prizes
        : [campaignContext.campaignState.prize]

    let ruleType = []
    const layoutData = {
      layoutName: ownState.layoutTitleChoosen,
      companyName,
      termsAndRegulation: campaignContext.termsOfService?.fileUrl,
      site: campaignContext.campaignState?.site,
      baseColor: campaignContext.layoutInfos.baseColor,
      footerColor: campaignContext.layoutInfos.footerColor,
      logoHeader: campaignContext.layoutInfos.logoHeader,
      mainBanner1: campaignContext.layoutInfos.mainBanner1,
    }

    rules.forEach((ruleAndRules, index) => {
      const ruleTypeSingle = ruleAndRules.ruleType
      const prizeAndPrizes = prizes[index]

      let ruleTypeItem = ''

      if (ruleTypeSingle === 0) {
        const options = { style: 'currency', currency: 'BRL' }
        const typeValue = ruleAndRules?.reachPurchaseValue?.type
        const typeDiscount = prizeAndPrizes?.discount?.type

        ruleTypeItem = `Meta financeira | Meta: ${
          typeValue === 0
            ? ruleAndRules?.reachPurchaseValue?.value?.toLocaleString(
                'pt-BR',
                options
              )
            : `${ruleAndRules?.reachPurchaseValue?.value}%`
        } / Prêmio: ${
          typeDiscount === 0
            ? prizeAndPrizes.discount?.value?.toLocaleString('pt-BR', options)
            : `${prizeAndPrizes.discount?.value}%`
        }`
      } else if (ruleTypeSingle === 1) {
        const itemQuantityString = ruleAndRules.itemQuantity
          .map((item) => {
            const qtd = item.qtd || ''
            const code = item.code || ''
            const conditionType =
              item.conditionType === 0
                ? 'e'
                : item.conditionType === 1
                ? 'ou'
                : ''
            return `Produto ou Serviço: ${code}, Quantidade: ${qtd} ${conditionType}`
          })
          .join(' ')

        const discountValue = prizeAndPrizes.discount?.value
        const typeValue = ruleAndRules?.reachPurchaseValue?.type
        const typeDiscount = prizeAndPrizes.discount.type

        const options = { style: 'currency', currency: 'BRL' }
        const prizeString =
          typeDiscount === 0
            ? `Prêmio: ${discountValue?.toLocaleString('pt-BR', options)}`
            : `${discountValue}%`

        ruleTypeItem = `${itemQuantityString}, ${prizeString}`
      } else if (ruleTypeSingle === 2) {
        ruleTypeItem = `Híbrido | Meta: ${ruleAndRules?.multipleReachValueCondition?.reachValue?.description} ${ruleAndRules?.multipleReachValueCondition?.conditionType} Meta: ${ruleAndRules?.multipleReachValueCondition?.reachValueFromCondition?.description}, Prêmio: ${prizeAndPrizes?.multipleDiscount?.discount?.description} ${prizeAndPrizes.multipleDiscount?.conditionType} Prêmio: ${prizeAndPrizes?.multipleDiscount?.discountFromCondition?.description}`
      }

      ruleType.push(ruleTypeItem)
    })

    layoutData.ruleType = ruleType

    if (campaignContext.communicationType === 'email') {
      await campaignContext.uploadLayoutFiles(layoutData)
    } else {
      await campaignContext.createWhatsAppLayout(layoutData)
    }

    handleClose()
    stepFive()
  }

  const handleSaveAndContinue = () => {
    if (campaignContext.communicationType !== 'app') {
      handleShowSendTest()
    } else {
      campaignContext.setIsLoadingCreateCampaign(true)
      handleCloseSendTest(2500)
      toast.success('Campanha criada com sucesso!')
    }
  }

  const handleShotSendTest = () => {
    setShowSendTest(false)

    setTimeout(() => {
      handleShowSendTestConfirmation()
    }, 1000)
  }

  useEffect(() => {
    setOwnState({
      ...ownState,
      activeStep: 1,
      isStepActive: {
        0: true,
      },
      isRuleActive: {
        0: true,
      },
      isLayoutSelectorActive: {
        0: true,
      },
    })
  }, [])

  const handleSaveDraft = async () => {
    await campaignContext.createCampaign(true)

    handleSaveAndContinue()
  }

  return (
    <CreateCampaignPage
      styles={styles}
      steps={steps}
      state={ownState}
      startDateValue={startDateValue}
      endDateValue={endDateValue}
      onChangeStartDate={onChangeStartDate}
      onChangeEndDate={onChangeEndDate}
      handleKeyDown={handleKeyDown}
      handleChange={handleChange}
      handleUploadChange={handleUploadChange}
      handleUploadChangeBatch={handleUploadChangeBatch}
      handleRuleType={handleRuleType}
      handleChangeSwitch={handleChangeSwitch}
      handlePlatformComunication={handlePlatformComunication}
      handleStepBack={handleStepBack}
      handleStepForward={handleStepForward}
      handleDeleteFile={handleDeleteFile}
      handleSaveAndContinue={handleSaveAndContinue}
      layoutType={layoutType}
      handleLayoutType={handleLayoutType}
      layoutSelection={
        campaignContext?.communicationType === 'app'
          ? layoutSelectionApp
          : layoutSelection
      }
      handleLayoutSelector={handleLayoutSelector}
      show={show}
      metaString={
        campaignContext.campaignState?.rule?.reachPurchaseValue?.value
      }
      prizeString={campaignContext.campaignState?.prize?.discount?.value}
      showSendTest={showSendTest}
      showSendTestConfirmation={showSendTestConfirmation}
      handleClose={handleClose}
      handleCloseSendTest={handleCloseSendTest}
      handleCloseSendTestConfirmation={handleCloseSendTestConfirmation}
      handleShow={handleShow}
      handleShowSendTest={handleShowSendTest}
      handleShowSendTestConfirmation={handleShowSendTestConfirmation}
      handleLayoutChoosen={handleLayoutChoosen}
      handleSaveLayout={handleSaveLayout}
      handleShotSendTest={handleShotSendTest}
      handleSaveDraft={handleSaveDraft}
      wppMessageAttributes={
        ownState.layoutTitleChoosen === 'WhatsApp personalizado'
          ? wppMessageAttributes
          : wppMessageAttributesDefault
      }
      setWppMessageAttributes={
        ownState.layoutTitleChoosen === 'WhatsApp personalizado'
          ? setWppMessageAttributes
          : setWppMessageAttributesDefault
      }
      appMessageAttributes={appMessageAttributesDefault}
    />
  )
}

CreateCampaign.propTypes = {
  styles: PropTypes.object,
  history: PropTypes.object,
}

export default CreateCampaign
