import React, { useContext, useState } from 'react'
import PropTypes from 'prop-types'
import { Col, Row } from 'react-bootstrap'
import { Input } from 'cashin-components'
import { CampaignContext } from '../../../context/campaign-context'
import { read, utils } from 'xlsx'
import { useCompanyUsageBalance } from '../../../API/queries/company/useCompanyUsageBalance'
import ExceededUsageLimitModal from '../../../Components/ExceededUsageLimitModal'
import api from '../../../API/api'
import { toast } from 'react-toastify'

function clearPhone(phoneNumber) {
  if (!phoneNumber) return ''
  return String(phoneNumber).replace(/\D/g, '')
}
const ParticipantsBatchInput = ({ styles, tagsSegment }) => {
  const [exceededUsageLimitModalIsOpen, SetExceededUsageLimitModalIsOpen] =
    useState(false)
  const campaignContext = useContext(CampaignContext)
  const { data: balance } = useCompanyUsageBalance(campaignContext.companyId)

  const handleClose = () => SetExceededUsageLimitModalIsOpen(false)
  const handleShow = () => SetExceededUsageLimitModalIsOpen(true)

  const downloadTemplate = (fileName) => {
    window.location.href = `https://multicampaignfileshml.blob.core.windows.net/files/${fileName}`
  }

  async function readFile(file) {
    const reader = new FileReader()
    let errorUpload = false
    // verifica se o arquivo é diferente de xls
    if (
      file.type !==
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    ) {
      toast.error('Erro: O arquivo deve ser um arquivo Excel (.xlsx).')
      return
    }

    reader.onload = async (event) => {
      const data = new Uint8Array(event.target.result)
      const workbook = read(data, { type: 'array' })
      const worksheet = workbook.Sheets[workbook.SheetNames[0]]
      const rows = utils.sheet_to_json(worksheet, { header: 1 })

      // Encontrando os índices das colunas a partir da segunda linha
      const celularHeader = 'Celular*'
      const celularIndex = rows[1].findIndex((cell) =>
        cell.includes(celularHeader)
      )
      const emailHeader = 'E-mail*'
      const emailIndex = rows[1].findIndex((cell) => cell.includes(emailHeader))
      const segmentationHeader = 'Segmentação*'
      const segmentationIndex = rows[1].findIndex((cell) =>
        cell.includes(segmentationHeader)
      )

      const filteredRows = rows.slice(2)

      // Removendo as linhas totalmente vazias
      const nonEmptyRows = filteredRows.filter((row) =>
        row.some((cell) => cell.trim() !== '')
      )

      const existingSegmentations = nonEmptyRows
        .map((row) => row[segmentationIndex])
        .filter((segmentation) => segmentation)

      // Validando se todas as segmentações presentes no arquivo Excel estão no array tagsSegment
      existingSegmentations.forEach((segmentation) => {
        if (!tagsSegment.some((seg) => seg.label === segmentation)) {
          toast.error(
            `Erro: A segmentação "${segmentation}" encontrada no arquivo Excel não está definida.`
          )
          errorUpload = true
          return false
        }
      })

      tagsSegment.forEach((seg) => {
        if (!existingSegmentations.includes(seg.label)) {
          toast.error(
            `Erro: A segmentação "${seg.label}" não foi encontrada no arquivo Excel.`
          )
          errorUpload = true
          return false
        }
      })

      if (errorUpload) {
        return
      }

      const validParticipants = nonEmptyRows.filter((row) => {
        const [name, celular, email, segmentation] = row
        const phoneRegex = /^(\d{2})\d{8,9}$/
        // Verificando se há nome
        if (!name) {
          toast.error('Erro: O nome não foi fornecido.')
          errorUpload = true
          return false // Ignora linha
        }

        // Verificando se há celular ou e-mail
        if (!celular && !email) {
          toast.error(
            'Erro: Pelo menos um dos campos (Celular ou E-mail) deve ser fornecido.'
          )
          errorUpload = true
          return false // Ignora linha
        }

        if (celular && !phoneRegex.test(celular)) {
          toast.error('Erro: O telefone está em formato inválido.')
          errorUpload = true
          return false // Ignora linha
        }

        // Validando a segmentação, se ela existir
        if (segmentationIndex !== -1 && !segmentation) {
          toast.error('Erro: A segmentação não foi fornecida.')
          errorUpload = true
          return false // Ignora linha
        }

        return true // Mantém linha
      })

      // Mapeando os valores das colunas correspondentes
      const participantData = validParticipants.map((row) => {
        return {
          name: row[0],
          cellphone: clearPhone(row[1]), // Limpeza dos números de telefone
          email: row[2],
          segmentation:
            segmentationIndex !== -1 ? row[segmentationIndex] : null, // Corrigido para pegar o valor correto da coluna de segmentação
        }
      })

      // Verifica duplicidade de celular
      const cellphoneList = participantData.map(
        (participant) => participant.cellphone
      )
      const hasCellphoneDuplicates = cellphoneList.some(
        (cellphone, index) =>
          cellphone && cellphoneList.indexOf(cellphone) !== index
      )
      if (hasCellphoneDuplicates) {
        toast.error('Erro: Existem participantes com o mesmo celular.')
        errorUpload = true
        return
      }

      // Verifica duplicidade de email
      const emailList = participantData.map((participant) => participant.email)
      const hasEmailDuplicates = emailList.some(
        (email, index) => email && emailList.indexOf(email) !== index
      )

      campaignContext.setParticipants(
        participantData.map((item) => ({
          ...item,
          cellphone: clearPhone(item.cellphone) || clearPhone(item.contact),
        }))
      )
      if (hasEmailDuplicates) {
        toast.error('Erro: Existem participantes com o mesmo e-mail.')
        errorUpload = true
        return
      }

      const participantsClean = participantData.map((item) => {
        const cellphone = item.cellphone || ''
        return {
          ...item,
          celular: clearPhone(cellphone),
        }
      })

      campaignContext.setParticipants(participantsClean)

      if (!campaignContext.isSegmentation) {
        campaignContext.setSegmentTags([
          { value: null, label: 'Não segmentado' },
        ])
      }

      if (errorUpload) {
        return
      }

      const rowCount = participantData.length
      if (rowCount - 1 > balance?.balance) {
        handleShow()
        await api.post(
          `/company-negotiation-usage/send-email/negotiation-denied?companyName=${campaignContext.companyName}`
        )
        return
      }
      campaignContext.uploadParticipantFile(file)
    }
    if (!errorUpload) {
      reader.readAsArrayBuffer(file)
    }
    if (!errorUpload) {
      reader.readAsArrayBuffer(file)
    }
  }

  async function handleUploadFile(file) {
    await readFile(file)
  }

  return (
    <>
      <ExceededUsageLimitModal
        styles={styles}
        size='xs'
        show={exceededUsageLimitModalIsOpen}
        onHide={handleClose}
      />
      <Row>
        <Col xs={12}>
          <p
            className={`
              ${styles.tipography({ paragraphs: 'smallRegular' })}
              ${styles.colors({ colors: 'neutralColorLowMedium' })}
              ${
                campaignContext.currentParticipantFileReference?.length <= 0
                  ? ''
                  : 'd-none'
              }
              m-0
            `}
          >
            Adicione aqui sua lista de participantes <b>sem segmentação</b>.
            Você pode fazer o{' '}
            <span
              className={`
                ${styles.colors({ colors: 'highlightColorPure' })}
                text-decoration-underline
              `}
              style={{ cursor: 'pointer' }}
              onClick={() => {
                downloadTemplate(
                  'd4d1087a-e9f0-4316-a522-5d084200f5c4Planilha_Padrao-Upload_Participantes_sem_Segmentacao.xlsx'
                )
              }}
            >
              download
            </span>{' '}
            de um modelo de exemplo em Excel para ajudar.
            <br />
            Ou se preferir, disponibilizamos também uma opção{' '}
            <b>com segmentação</b> para{' '}
            <span
              className={`
                ${styles.colors({ colors: 'highlightColorPure' })}
                text-decoration-underline
              `}
              style={{ cursor: 'pointer' }}
              onClick={() => {
                downloadTemplate(
                  'b63a9cbe-b73d-447e-b8a7-962c5bd6dc40Planilha_Padrao-Upload_Participantes_com_Segmentacao.xlsx'
                )
              }}
            >
              download
            </span>
            .
          </p>
          <div
            className={`
              ${styles.inputs({ inputFile: 'inputFileBatch' })}
            `}
          >
            <Input
              id='txtFileInput'
              styles={styles}
              isFileInput={true}
              isFileBatch={true}
              isEmptyFile={
                campaignContext.currentParticipantFileReference?.length <= 0
              }
              accept='.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
              filePlaceholderText='Faça upload do arquivo PDF'
              onChange={(e) => handleUploadFile(e[0])}
              handleDeleteFile={campaignContext.deleteParticipantFile}
              files={campaignContext.currentParticipantFileReference}
            />
          </div>
        </Col>
      </Row>
    </>
  )
}

ParticipantsBatchInput.propTypes = {
  styles: PropTypes.object,
  state: PropTypes.object,
  handleUploadChangeBatch: PropTypes.func,
  handleDeleteFile: PropTypes.func,
}

export default ParticipantsBatchInput
