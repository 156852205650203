import React, { Fragment, useState, useContext, useEffect } from 'react'
import qs from 'qs'
import { CampaignContext } from '../../context/campaign-context'
import PropTypes from 'prop-types'
import { Col, Row, ProgressBar } from 'react-bootstrap'
import { Input, ButtonComponent, Select } from 'cashin-components'
import ParticipantsTableComponent from './participants-table-component'
import { Auth } from 'aws-amplify'
import api from '../../API/api'
import { useCompanyUsageBalance } from '../../API/queries/company/useCompanyUsageBalance'
import { NegotiationUsageTypeAction } from '../../enums/NegotiationUsageTypeAction'
import ExceededUsageLimitModal from '../../Components/ExceededUsageLimitModal'
import { useQueryClient } from 'react-query'
import { toast } from 'react-toastify'

const Manual = ({ styles, state, campaign }) => {
  const [user, setUser] = useState('')
  const [cellphone, setCellphone] = useState('')
  const [addUserSegment, setAddUserSegment] = useState('')
  const [searchUserSegment, setSearchUserSegment] = useState('')
  const [email, setEmail] = useState('')
  const [searchNameInput, setSearchNameInput] = useState('')
  const [currentUser, setCurrentUser] = useState({})
  const [exceededUsageLimitModalIsOpen, SetExceededUsageLimitModalIsOpen] =
    useState(false)
  const [filterParams, setFilterParams] = useState({
    searchName: '',
    segmentation: '',
  })

  const campaignContext = useContext(CampaignContext)

  const companyId = currentUser?.attributes?.['custom:tenantId']

  const { data: usageBalance, refetch } = useCompanyUsageBalance(companyId)

  const handleClose = () => SetExceededUsageLimitModalIsOpen(false)
  const handleShow = () => SetExceededUsageLimitModalIsOpen(true)
  const queryClient = useQueryClient()

  useEffect(() => {
    const runAsync = async () => {
      setCurrentUser(await Auth.currentAuthenticatedUser())
    }
    runAsync()
  }, [companyId])

  const handleAddParticpant = async () => {
    if (usageBalance?.balance <= 0) {
      handleShow()
      await api.post(
        `/company-negotiation-usage/send-email/negotiation-denied?companyName=${campaignContext.companyName}`
      )
      return
    }

    if (
      (campaign.whatsAppType && (!user || !cellphone)) ||
      (campaign.isApp && (!user || (!email && !cellphone))) ||
      (!campaign.whatsAppType && !campaign.isApp && (!user || !email)) ||
      (campaign.isSegmentationCampaign && !addUserSegment)
    ) {
      toast.error('Por favor, preencha todos os campos obrigatórios.', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      })
      return
    }

    const requestBody = {
      Name: user,
      Cellphone: cellphone,
      Email: email,
    }

    if (campaign.isSegmentationCampaign) {
      requestBody.SegmentationName = addUserSegment
    }

    var queryString = qs.stringify(
      {
        engagementType: campaign.whatsAppType
          ? 'WhatsApp'
          : campaign.isApp
          ? 'App'
          : campaignContext.communicationType,
        companyName: campaignContext.companyName,
        campaingName: campaign.name,
        campaignId: campaign.id,
      },
      { skipNulls: true }
    )

    await api.post(
      `/participant-base/${campaign?.participantBaseId}/add-participant?${queryString}`,
      requestBody
    )

    const addCompanyNegotiationBody = {
      companyId: companyId,
      totalUsage: parseInt(-1),
      action: 'Participante adicionado',
      usageLimit: parseInt(usageBalance.usageLimit),
      negotiationUsageTypeAction: NegotiationUsageTypeAction.AddParticipant,
    }

    await api.post('/company-negotiation-usage', addCompanyNegotiationBody)

    await api.get(
      '/company-negotiation-usage/current-usage/company/' + companyId
    )
    queryClient.refetchQueries('participants-list-from-campaing')
    setUser('')
    setCellphone('')
    setEmail('')
    setAddUserSegment('')
    refetch()

    window.location.reload()

    toast.success('Participante adicionado.', {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'light',
    })
  }

  const removeCellphoneMask = (maskedCellphone) => {
    const cellphone = maskedCellphone.replace(/\D/g, '')
    return cellphone
  }

  const applyCellphoneMask = (cellphone) => {
    if (cellphone.length >= 15) return
    const maskedCellphone = cellphone
      .replace(/^(\d{2})(\d)/g, '($1) $2')
      .replace(/(\d{5})(\d)/, '$1-$2')
    return maskedCellphone
  }

  const segmentations =
    campaign?.segmentations?.map((item) => ({
      value: item,
      label: item,
    })) || []

  return (
    <Fragment>
      <ExceededUsageLimitModal
        styles={styles}
        size='xs'
        show={exceededUsageLimitModalIsOpen}
        onHide={handleClose}
      />

      <div className='border p-3 rounded mb-4'>
        <div className='d-flex justify-content-between align-items-center mb-3'>
          <h3
            className={`
                ${styles.tipography({ paragraphs: 'largeBold' })}
                mb-0
              `}
          >
            Cadastrar usuário
          </h3>

          <div className='w-25 border rounded p-2'>
            <b>Restam {usageBalance?.balance}</b>
            <ProgressBar
              now={(usageBalance?.balance * 100) / usageBalance?.usageLimit}
              style={{ height: '8px', marginTop: 3 }}
              className={styles.progressBar({
                progressBar: 'primary',
              })}
            />
          </div>
        </div>

        <Row>
          <Col xs>
            <Input
              id='txtUserName2'
              inputName='userName2'
              className={styles.inputs({ input: 'default' })}
              idError='fdbUserNameError2'
              inputType='text'
              labelName='Nome'
              placeholder='Digite o nome'
              // errorText={state.userNameError2}
              // isInvalid={state.isInvalidUserName2}
              styles={styles}
              value={user}
              onChange={(e) => setUser(e.target.value)}
              // onKeyDown={(e) => handleKeyDown(e.key)}
            />
          </Col>
          <Col xs>
            <Input
              id='txtUserPhone2'
              inputName='userPhone2'
              className={styles.inputs({ input: 'default' })}
              idError='fdbUserPhoneError2'
              inputType='text'
              labelName='Celular'
              placeholder='Digite o celular'
              styles={styles}
              value={applyCellphoneMask(cellphone)}
              onChange={(e) => {
                if (e.target.value.length > 15) return
                setCellphone(removeCellphoneMask(e.target.value))
              }}
              disabled={!campaign.whatsAppType && !campaign.isApp}
            />
          </Col>
          <Col xs>
            <Input
              id='txtUserEmail2'
              inputName='userEmail2'
              className={styles.inputs({ input: 'default' })}
              idError='fdbUserEmailError2'
              inputType='text'
              labelName='E-mail'
              placeholder='Digite o e-mail'
              disabled={campaign.whatsAppType}
              // errorText={state.userEmailError2}
              // isInvalid={state.isInvalidUserEmail2}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              styles={styles}
            />
          </Col>
          <Col>
            <Select
              labelName='Segmentos'
              placeholder='Selecione'
              options={segmentations}
              errorText='Selecione'
              styles={styles}
              className='mt-4'
              disabled={!campaign.isSegmentationCampaign}
              onChange={(e) => setAddUserSegment(e.value)}
            />
          </Col>
          <Col xs={'auto'} className='d-flex align-items-end'>
            <ButtonComponent
              className={`
              ${styles.buttonSquareLarge({
                large: 'primary',
              })}
              mb-3
            `}
              hasIcon={true}
              iconName='add'
              onClick={handleAddParticpant}
            />
          </Col>
        </Row>
      </div>

      <Row>
        <Col xs={4}>
          <Input
            id='txtUserName2'
            inputName='userName2'
            className={styles.inputs({ input: 'default' })}
            idError='fdbUserNameError2'
            inputType='text'
            labelName='Buscar participantes'
            placeholder='Pesquise por nome, e-mail ou telefone'
            styles={styles}
            value={searchNameInput}
            onChange={(e) => setSearchNameInput(e.target.value)}
          />
        </Col>
        <Col xs={4}>
          <Select
            labelName='Segmentos'
            placeholder='Selecione'
            options={segmentations}
            errorText='Selecione'
            styles={styles}
            className='mt-4'
            disabled={!campaign.isSegmentationCampaign}
            onChange={(e) => setSearchUserSegment(e.value)}
          />
        </Col>
        <Col xs={2} style={{ marginTop: '0.275rem' }}>
          <ButtonComponent
            className={`${styles.buttonStandardLarge({
              large: 'primary',
            })}
            ${
              false &&
              styles.buttonStandardLarge({
                large: 'primaryLoading',
              })
            } w-100 mt-4`}
            text='Buscar'
            isLoading={false}
            styles={styles}
            onClick={() =>
              setFilterParams({
                searchName: searchNameInput,
                segmentation: searchUserSegment,
              })
            }
          />
        </Col>
      </Row>
      <ParticipantsTableComponent
        styles={styles}
        state={state}
        campaign={campaign}
        searchName={filterParams.searchName}
        segmentation={filterParams.segmentation}
        refetchList={handleAddParticpant}
      />
    </Fragment>
  )
}

Manual.propTypes = {
  styles: PropTypes.object,
  state: PropTypes.object,
}

export default Manual
