import dayjs from 'dayjs'
import { read, utils } from 'xlsx'
import { createContext, useState, useEffect } from 'react'
import { Auth } from 'aws-amplify'
import axios from 'axios'
import api from '../API/api'
import useIdentificationInfo from '../API/queries/company/useIdentificationInfo'
import { toast } from 'react-toastify'

export const CampaignContext = createContext()

const CampaignProvider = ({ children }) => {
  const [campaignState, setCampaignState] = useState({
    name: '',
    periodDate: {
      from: '',
      to: '',
    },
    site: '',
    rule: {
      ruleType: 0,
      reachPurchaseValue: {
        value: null,
        type: 0,
      },
    },
    prize: {
      prizeType: 0,
      discount: {
        value: null,
        type: 0,
      },
    },
    imgs: [],
    aspectRatio: '1-1',
    isApp: false,
    logo: null,
    programId: null,
    programName: '',
  })

  const [campaignRuleSelected, setCampaignRuleSelected] = useState(0)
  const [communicationType, setCommunicationType] = useState('email')
  const [comunicationLayoutType, setComunicationLayoutType] = useState('')
  const [termsOfService, setTermsOfService] = useState()
  const [participantBaseId, setParticipantBaseId] = useState()
  const [participants, setParticipants] = useState([])
  const [participantsQuantity, setParticipantQuantity] = useState()
  const [currentUser, setCurrentUser] = useState({})
  const [segmentTags, setSegmentTags] = useState([
    { value: null, label: 'Não segmentado' },
  ])
  const [engajamentSelected, setEngajamentSelected] = useState(0)
  const [isSegmentation, setIsSegmentation] = useState('')

  let layouts = [{ layout: '', index: 0 }]

  const [isLoadingLayoutUpload, setIsLoadingLayoutUpload] = useState(false)
  const [layoutId, setLayoutId] = useState('')
  const [images, setImages] = useState([])

  const [trainingVideoObject, setTrainingVideoObject] = useState({})
  const [trainingQuizObject, setTrainingQuizObject] = useState({})
  const [trainingEbookObject, setTrainingEbookObject] = useState({})

  const [
    currentTersOfServiceFileReference,
    setCurrentTersOfServiceFileReference,
  ] = useState([])

  const [currentParticipantFileReference, setCurrentParticipantFileReference] =
    useState([])

  const [participantCreatingIsLoading, setParticipantCreatingIsLoading] =
    useState(false)

  const [isLoadingCreateCampaign, setIsLoadingCreateCampaign] = useState(false)
  const [campaignId, setCampaignId] = useState('')

  const [layoutInfos, setLayoutInfos] = useState()
  const [campaignDetails, setCampaignDetails] = useState()
  const [campaignDetailsParams, setCampaignDetailsParams] = useState([])
  const [notificationDetails, setNotificationDetails] = useState([])
  const [isRanking, setIsRanking] = useState(false)
  const [updateListParticipants, setUpdateListParticipants] = useState(false)

  const getCurrentCampaign = (campaignId) => {}
  const { data: indentificantionData } = useIdentificationInfo()

  const companyId = currentUser?.attributes?.['custom:tenantId']
  const companyName =
    currentUser?.attributes?.['custom:profile'] === 'Admin'
      ? indentificantionData?.find(
          (x) => x.id === currentUser?.attributes?.['custom:tenantId']
        )?.description
      : currentUser?.attributes?.name

  useEffect(() => {
    const runAsync = async () => {
      setCurrentUser(await Auth.currentAuthenticatedUser())
    }
    runAsync()
  }, [companyId])

  const setBasicInfoData = (name, periodDate, site) => {
    setCampaignState((currentState) => ({
      ...currentState,
      name,
      periodDate,
      site,
    }))
  }

  const setRuleAndPrize = (rule, prize) => {
    if (isSegmentation === 'yes') {
      setCampaignState((currentState) => ({
        ...currentState,
        rules: rule,
        prizes: prize,
        rule: rule[0],
        prize: prize[0],
      }))
    } else {
      setCampaignState((currentState) => ({
        ...currentState,
        rule: rule[0],
        prize: prize[0],
        rules: [rule[0]],
        prizes: [prize[0]],
      }))
    }
  }

  async function readFile(file) {
    const reader = new FileReader()
    reader.onload = (event) => {
      const data = new Uint8Array(event.target.result)
      const workbook = read(data, { type: 'array' })
      const worksheet = workbook.Sheets[workbook.SheetNames[0]]
      const rows = utils.sheet_to_json(worksheet, { header: 1 })

      const filteredRows = rows.filter((row) => {
        const celular = row[1]
        const email = row[2]

        if (communicationType === 'whatsapp' && !celular) {
          return false // Ignora linha
        } else if (communicationType === 'email' && !email) {
          return false // Ignora linha
        }
        return true // Mantém linha
      })

      const rowCount = filteredRows.length
      setParticipantQuantity(() => rowCount - 1)
    }
    reader.readAsArrayBuffer(file)
  }

  const uploadParticipantFile = async (file) => {
    setCurrentParticipantFileReference([file])

    const formDataRequest = new FormData()
    formDataRequest.append('participantFile', file)

    setParticipantCreatingIsLoading(true)

    const participantFileId = await api.post(
      `/participant-base/upload/for-engagement?engagementTypes=${communicationType}`,
      formDataRequest
    )

    await readFile(file)
    setParticipantCreatingIsLoading(false)
    setParticipantBaseId(() => participantFileId.participanteBaseId)
  }

  const deleteParticipantFile = () => {
    // TODO:: criar endpoint
    setCurrentParticipantFileReference([])
    setParticipantBaseId(null)
  }

  const insertListOfParticipants = async () => {
    setParticipantCreatingIsLoading(true)

    try {
      const participantFileId = await api.post(
        `/participant-base/for-engagement?engagementTypes=${communicationType}`,
        participants
      )

      setParticipantQuantity(() => participants.length)
      setParticipantBaseId(participantFileId)
    } catch (error) {
      throw error
    } finally {
      setParticipantCreatingIsLoading(false)
    }
  }

  const uploadTermsOfService = async (file) => {
    setCurrentTersOfServiceFileReference([file])

    const formDataRequest = new FormData()
    formDataRequest.append('termsOfServiceFile', file)

    const termsOfService = await api.post(
      '/terms-of-service/upload',
      formDataRequest
    )

    setTermsOfService(termsOfService)
  }

  const uploadImage = async (file) => {
    try {
      setIsLoadingLayoutUpload(true)
      const formData = new FormData()
      const fileName = file.name.replace(/\s+/g, '-')
      formData.append('file', file, fileName)

      const imageUrl = await api.post('/upload-image', formData)
      setImages((current) => [...current, imageUrl])

      return imageUrl
    } catch (ex) {
      throw ex
    } finally {
      setIsLoadingLayoutUpload(false)
    }
  }

  const uploadLayoutFiles = async ({
    layoutName,
    companyName,
    termsAndRegulation,
    campaingSite,
    ruleType,
    baseColor,
    footerColor,
    ImageHeader,
    ImageBanner,
  }) => {
    try {
      setIsLoadingLayoutUpload(true)

      // layouts.forEach((layout, index) => {
      //   files.push(Buffer.from(LZMA.compress(layout.layout)).toString("hex"));

      //   delete layout[index];
      // });

      const layoutId = await api.post('/notification-layout', {
        layoutName,
        termsAndRegulation,
        companyName,
        campaingSite,
        ruleType,
        baseColor,
        footerColor,
        ImageHeader,
        ImageBanner,
        dispatches: layouts.map((x) => x.layout),
      })

      setLayoutId(layoutId)
      return layoutId
    } catch (e) {
      throw e
    } finally {
      setIsLoadingLayoutUpload(false)
    }
  }

  const createWhatsAppLayout = async ({
    layoutName,
    companyName,
    termsAndRegulation,
    campaingSite,
    ruleType,
    baseColor,
    footerColor,
    ImageHeader,
    ImageBanner,
  }) => {
    try {
      setIsLoadingLayoutUpload(true)

      const layoutId = await api.post('/notification-layout/whatsapp', {
        layoutName,
        companyName,
        termsAndRegulation,
        campaingSite,
        ruleType,
        baseColor,
        footerColor,
        ImageHeader,
        ImageBanner,
        dispatches: layouts.map((x) => x.layout),
      })

      await setLayoutId(layoutId)
      return layoutId
    } catch (e) {
      throw e
    } finally {
      setIsLoadingLayoutUpload(false)
    }
  }

  const getTotalDispatch = () => {
    const dateNow = new Date()
    const startDate =
      campaignState.periodDate.from <= dateNow
        ? dateNow
        : campaignState.periodDate.from

    const totalDispatch =
      parseInt(
        Math.abs(dayjs(startDate).diff(campaignState.periodDate.to, 'day'))
      ) / 7

    if (totalDispatch < 0) return 1

    return Math.round(totalDispatch)
  }

  const addLayout = (layout, index) => {
    layouts = [...layouts.filter((x) => x.index !== index), { layout, index }]
  }

  const createTraining = async (
    trainingObject,
    setTrainingObject,
    campaignId,
    successMessage,
    errorMessage
  ) => {
    setTrainingObject((currentState) => ({
      ...currentState,
      campaignId,
    }))

    const newTrainingObject = {
      ...trainingObject,
      campaignId,
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_ENGAJE}training`,
        newTrainingObject
      )

      return response.data
    } catch (error) {
      toast.error(errorMessage)
      console.log(error)
      return null
    }
  }

  const createCampaign = async (isDraft) => {
    try {
      setIsLoadingCreateCampaign(true)

      const today = new Date()
      const statusValue =
        new Date(campaignState.periodDate.from) > today ? 5 : 1
      const statusDraftValue = 0

      const data = {
        ...campaignState,
        status: !isDraft ? statusValue : statusDraftValue,
        termsOfServiceId: termsOfService?.id,
        tearmsOfServiceId: termsOfService?.id,
        participantBaseId: participantBaseId,
        notificationLayoutId: layoutId,
        campaignDetails: campaignDetails || [],
        notificationDetails: notificationDetails || [],
        layoutType: comunicationLayoutType,
        whatsAppType:
          communicationType !== 'email' ? comunicationLayoutType : '',
        isSegmentationCampaign: isSegmentation === 'yes',
        segmentations:
          isSegmentation === 'yes' ? segmentTags.map((x) => x.label) : [],
        isRanking,
        isApp: communicationType.toLowerCase() === 'app',
      }

      if (communicationType === 'email') {
        data.campaignDetails = []
        data.engajamentSelected = 0
      }

      if (
        communicationType !== 'email' &&
        comunicationLayoutType === 'default'
      ) {
        data.engajamentSelected = engajamentSelected
      } else if (communicationType === 'app') {
        data.layoutType = comunicationLayoutType
      }

      const campaignId = await api.post('/campaign', data)

      setCampaignId(campaignId)

      if (communicationType === 'app') {
        if (trainingVideoObject?.name) {
          await createTraining(
            trainingVideoObject,
            setTrainingVideoObject,
            campaignId,
            'Vídeo enviado com sucesso',
            'Erro ao enviar o vídeo'
          )
        }
        if (trainingEbookObject?.name) {
          await createTraining(
            trainingEbookObject,
            setTrainingEbookObject,
            campaignId,
            'Ebook enviado com sucesso',
            'Erro ao enviar o ebook'
          )
        }
        if (trainingQuizObject?.name) {
          await createTraining(
            trainingQuizObject,
            setTrainingQuizObject,
            campaignId,
            'Quiz enviado com sucesso',
            'Erro ao enviar o quiz'
          )
        }
      }

      return campaignId
    } catch (err) {
      throw err
    } finally {
      setIsLoadingCreateCampaign(false)
    }
  }

  const sendTestDispatch = async (name, cellphone, email) => {
    try {
      setIsLoadingCreateCampaign(true)

      await api.post(`/campaign/${campaignId}/send-dispatch-test`, {
        name,
        cellphone,
        email,
      })
    } catch (err) {
      throw err
    } finally {
      setIsLoadingCreateCampaign(false)
    }
  }

  return (
    <CampaignContext.Provider
      value={{
        getCurrentCampaign,
        campaignState,
        setCampaignState,
        campaignRuleSelected,
        setCampaignRuleSelected,
        setBasicInfoData,
        setRuleAndPrize,
        setCommunicationType,
        communicationType,
        comunicationLayoutType,
        setComunicationLayoutType,
        uploadTermsOfService,
        currentTersOfServiceFileReference,
        uploadParticipantFile,
        participantCreatingIsLoading,
        currentParticipantFileReference,
        participantBaseId,
        deleteParticipantFile,
        setParticipants,
        participants,
        setParticipantQuantity,
        participantsQuantity,
        insertListOfParticipants,
        campaign: campaignState,
        getTotalDispatch,
        addLayout,
        uploadLayoutFiles,
        isLoadingLayoutUpload,
        uploadImage,
        termsOfService,
        createWhatsAppLayout,
        layoutId,
        createCampaign,
        isLoadingCreateCampaign,
        setIsLoadingCreateCampaign,
        sendTestDispatch,
        setLayoutInfos,
        layoutInfos,
        companyId,
        companyName,
        segmentTags,
        setSegmentTags,
        campaignDetails,
        setCampaignDetails,
        notificationDetails,
        setNotificationDetails,
        campaignDetailsParams,
        setCampaignDetailsParams,
        isRanking,
        setIsRanking,
        engajamentSelected,
        setEngajamentSelected,
        isSegmentation,
        setIsSegmentation,
        updateListParticipants,
        setUpdateListParticipants,
        trainingVideoObject,
        setTrainingVideoObject,
        trainingQuizObject,
        setTrainingQuizObject,
        trainingEbookObject,
        setTrainingEbookObject,
      }}
    >
      {children}
    </CampaignContext.Provider>
  )
}

export default CampaignProvider
