import { Accordion, Col, Form, InputGroup, Row } from 'react-bootstrap'
import { styles } from 'cashin-design-system/Styles/Styles'
import Icon from '../../Components/Icon'
import ButtonComponent from '../../Components/Buttons'
import { useCallback, useEffect, useState } from 'react'
import { DrawerFilter } from '../../Components/DrawerFilter'
import {
  getCommunicationReport,
  getCommunicationReportExport,
} from '../../APIV2/reports/get-communication-report'
import { BadgeComponent } from 'cashin-components'
import CustomPagination from '../../Components/CustomPagination'
import moment from 'moment'
import { debounce } from 'lodash'
import './CommunicationReportTable.css'
import { transformCommunicationReportDataForXlxs } from './export-communication-report'
import { exportToXlsx } from '../../functions/export-report-to-xlsx'
import { Box } from '../../Components'
import { stringTypeValidators } from '../../functions/string-input-validations'

export const notificationType = {
  email: 'E-mail',
  whatsapp: 'WhatsApp',
}

export const badgeTextByStatus = {
  sent: 'Enviado',
  delivered: 'Recebido',
  read: 'Lido',
  accepted: 'Recebido',
  scheduled: 'Agendado',
  queued: 'Agendado',
  failed: 'Rejeitado',
  undelivered: 'Rejeitado',
  participate: 'Participando',
}

const badgeTypeByStatus = {
  sent: 'success',
  delivered: 'success',
  read: 'success',
  accepted: 'success',
  scheduled: 'warning',
  queued: 'warning',
  failed: 'danger',
  undelivered: 'danger',
  participate: 'success',
}

const headers = [
  {
    label: 'Participante',
    key: 'name',
    component: (row) => <td>{row.name}</td>,
  },
  { label: 'Telefone', key: 'phone', component: (row) => <td>{row.phone}</td> },
  { label: 'E-mail', key: 'email', component: (row) => <td>{row.email}</td> },
  {
    label: 'Status',
    key: 'status',
    component: (row) => (
      <td>
        <BadgeComponent
          text={badgeTextByStatus[row.status ?? 'scheduled']}
          bgType={badgeTypeByStatus[row.status ?? 'warning']}
          isBadgePill={true}
          styles={styles}
          style={{ cursor: row?.status === 1 ? 'pointer' : '' }}
        />
      </td>
    ),
  },
  {
    label: 'Tipo',
    key: 'notificationType',
    component: (row) => <td>{notificationType[row.notificationType]}</td>,
  },
  {
    label: 'Data de envio',
    key: 'sentAt',
    component: (row) => (
      <td>{moment(row.sentAt).format('DD/MM/YYYY [às] HH:mm')}</td>
    ),
  },
  {
    label: 'Comunicação',
    key: 'subject',
    component: (row) => <td>{row.subject}</td>,
  },
  {
    label: 'Segmentação',
    key: 'segmentation',
    component: (row) => <td>{row.segmentation}</td>,
  },
]

export const CommunicationReportTable = ({ campaignId }) => {
  const [communicationStringFilter, setCommunicationStringFilter] = useState('')
  const [debouncedAndDefinedString, setDebouncedAndDefinedString] = useState('')
  const [paramName, setParamName] = useState('')
  const [currentPagination, setCurrentPagination] = useState(1)
  const [data, setData] = useState([])
  const [totalPages, setTotalPage] = useState(0)
  const [showDrawerFilter, setShowDrawerFilter] = useState(false)
  const [inputDateRange, setInputDateRange] = useState([null, null])
  const [dateRange, setDateRange] = useState([null, null])
  const [checkBoxStatus, setCheckBoxStatus] = useState()
  const [statusFilter, setStatusFilter] = useState(null)

  const debouncedCommunicationStringFilter = debounce((stringFilter) => {
    const matchedValidator = stringTypeValidators.find((type) =>
      type.test(stringFilter)
    )
    if (matchedValidator) {
      setDebouncedAndDefinedString(stringFilter)
      setParamName(matchedValidator.name)
    }
  }, 500)

  const handleStringFilterChange = (e) => {
    setCommunicationStringFilter(e.target.value)
    debouncedCommunicationStringFilter(e.target.value)
  }

  const handleCancelFilter = () => {
    setShowDrawerFilter(false)
  }

  const handleApplyDrawerFilter = () => {
    setStatusFilter(checkBoxStatus)
    setDateRange(inputDateRange)
    setShowDrawerFilter(false)
  }

  const handleCleanDrawerFilter = () => {
    setStatusFilter(null)
    setCheckBoxStatus(null)
    setInputDateRange([null, null])
    setDateRange([null, null])
    setParamName('')
    setDebouncedAndDefinedString('')
    setCommunicationStringFilter('')
    setShowDrawerFilter(false)
  }

  const handleOnPaginationChange = (newPage) => {
    setCurrentPagination(newPage)
  }

  const search = useCallback(async () => {
    const result = await getCommunicationReport(
      campaignId,
      paramName,
      debouncedAndDefinedString,
      statusFilter,
      dateRange[0],
      dateRange[1],
      currentPagination
    )
    setData(result.data.data)
    setTotalPage(result.data.totalPages)
  }, [
    campaignId,
    currentPagination,
    debouncedAndDefinedString,
    paramName,
    statusFilter,
    dateRange,
  ])

  const exportReport = async () => {
    const rest = await getCommunicationReportExport(
      campaignId,
      paramName,
      debouncedAndDefinedString,
      statusFilter,
      dateRange[0],
      dateRange[1],
      currentPagination
    )
    const labels = headers.map((header) => header.label)
    const propertyNames = headers.map((header) => header.key)
    const formattedDate = moment(new Date()).format('DD-MM-YYYY')
    const xlsxData = transformCommunicationReportDataForXlxs(
      labels,
      propertyNames,
      rest.data.data
    )
    exportToXlsx(xlsxData, `relatorio-de-comunicacao-${formattedDate}`)
  }

  useEffect(() => {
    search()
  }, [currentPagination, search, debouncedAndDefinedString])

  return (
    <Box>
      <DrawerFilter
        styles={styles}
        show={showDrawerFilter}
        onHide={() => setShowDrawerFilter(false)}
        dateRange={inputDateRange}
        setDateRange={setInputDateRange}
        onCancel={handleCancelFilter}
        onApply={handleApplyDrawerFilter}
        onCleanFilter={handleCleanDrawerFilter}
      >
        <Accordion defaultActiveKey='0' className='communication-report-table'>
          <Accordion.Item eventKey='0'>
            <Accordion.Header className='d-flex justify-content-start'>
              <span
                className={styles.tipography({ paragraphs: 'mediumRegular' })}
              >
                Status
              </span>
            </Accordion.Header>
            <Accordion.Body>
              <Form>
                {Object.keys(badgeTextByStatus).map((status, index) => (
                  <Form.Check
                    key={index}
                    type='checkbox'
                    label={badgeTextByStatus[status]}
                    checked={checkBoxStatus === status}
                    onChange={() => setCheckBoxStatus(status)}
                  />
                ))}
              </Form>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </DrawerFilter>
      <Row className='align-items-center mb-4'>
        <Col
          xs={{ span: 4, offset: 8 }}
          className='d-flex justify-content-end align-items-center'
        >
          <ButtonComponent
            className='bg-white text-black border-0 hover:bg-violet-200'
            hasIcon={true}
            iconName='filter_alt'
            onClick={() => setShowDrawerFilter(true)}
          />
          <InputGroup className='my-1 me-2'>
            <Form.Control
              className='bg-white rounded-md border-end-0 border'
              placeholder='Busca por participante, telefone ou email'
              value={communicationStringFilter}
              onChange={(e) => handleStringFilterChange(e)}
            />
            <InputGroup.Text className='bg-white border-start-0 rounded-md border'>
              <Icon iconName={'search'} />
            </InputGroup.Text>
          </InputGroup>
          <ButtonComponent
            className={`${styles.buttonStandardSmall({
              small: 'primaryLoading',
            })}`}
            text='Exportar'
            isLoading={false}
            styles={styles}
            onClick={exportReport}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <table id='table' className={`${styles.table()}`}>
            {headers.map((header, index) => (
              <th key={index}>{header.label}</th>
            ))}
            {data.map((row, index) => (
              <tr key={index} className='table-row'>
                {headers.map((header) => header.component(row))}
              </tr>
            ))}
          </table>
          <CustomPagination
            totalPages={totalPages}
            onPaginationChange={handleOnPaginationChange}
            currentPage={currentPagination}
            setCurrentPage={setCurrentPagination}
          />
        </Col>
      </Row>
    </Box>
  )
}
