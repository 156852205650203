import { css } from './CreateStitches';

export const modal = css({
  backgroundColor: 'rgba(0, 0, 0, 0.8)',

  '.modal-dialog': {
    '&.modal-xl': {
      width: 1140,
    },

    '&.modal-lg': {
      width: 800,
    },

    '&.modal-sm': {
      width: 448,
    },

    '.modal-content': {
      borderRadius: '$borderRadiusLG',

      '.modal-header': {
        borderBottom: 'none',
      },

      '.modal-body': {
        h1: {
          lineHeight: '48px',
        },
      },

      '.modal-footer': {
        borderTop: 'none',
      },
    },
  },

  '.editor-container': {
    margin: '1rem 0',
  },

  '.editor-disabled': {
    backgroundColor: 'var(--colors-neutralColorHighLight)',
    borderStyle: 'solid',
    borderWidth: 'var(--borderWidths-borderWidthHairLine)',
    borderColor: 'var(--colors-neutralColorHighMedium)',
    color: 'var(--colors-neutralColorLowMedium)',
    overflow: 'hidden',
  },

  '.editor-label-disabled': {
    color: 'var(--colors-neutralColorLowLight)',
  },

  '.file-input-title': {
    fontWeight: 'bold',
  },

  '.file-drop-area': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '150px',
    padding: '1rem',
    border: '2px dashed #ccc',
    cursor: 'pointer',
    margin: '20px 0',
    position: 'relative',
  },
  '.file-drop-area label': {
    marginBottom: '10px',
    display: 'contents',
  },
  '.file-drop-area .file-icon': {
    fontSize: '30px',
    marginBottom: '10px',
  },
  '.file-drop-area p': {
    fontSize: '12px',
    color: '#888',
  },

  '.rdw-editor-toolbar': {
    justifyContent: 'flex-end',
  },

  '.editor-label': {
    fontWeight: 'bold',
    marginTop: '0.3rem',
    position: 'absolute',
  },

  '.label-file': {
    textDecoration: 'underline',
    color: '#6300FF',
  },

  '.rt-wrapper': {
    margin: '1rem 0',
  },

  '.rt-toolbar': {
  },

  '.rt-editor': {
    height: '140px',
    border: '1px solid #ccc',
    padding: '1rem',
    borderRadius: '1rem',
  },

  '&.modal-layout-edit': {
    '.modal-dialog': {
      '&.modal-xl': {
        width: '95%',
        maxWidth: 'unset',

        '.modal-content': {
          position: 'absolute',
          height: '98%',
          borderBottomRightRadius: 0,

          '.modal-header': {
            borderBottom: 'solid 2px $neutralColorHighMedium !important',

            '.color-picker-position': {
              position: 'absolute',
              top: 70,
              zIndex: 9,
            },

            '.separator-editor-border-color': {
              borderColor: '$neutralColorHighMedium',
            },
          },

          '.modal-body': {
            paddingTop: 0,
            paddingRight: 12,
            paddingBottom: 0,
            overflowY: 'scroll',

            '.selector-area': {
              width: 340,
              // position: 'fixed',

              '.row': {
                position: 'absolute',
                overflowY: 'auto',
                width: '100%',
              },
            },

            '.layout-editor-area': {
              backgroundColor: '$neutralColorHighLight',
              paddingTop: 40,
              paddingBottom: 40,
              display: 'flex',
              justifyContent: 'center',
              // marginLeft: 340,
            },
          },
        },
      },
    },
  },

  variants: {
    button: {
      deleteButtons: {
        backgroundColor: '$neutralColorHighPure',
        borderBottomLeftRadius: '$borderRadiusLG',
        borderBottomRightRadius: '$borderRadiusLG',
        display: 'block',

        '.btn-delete': {
          backgroundColor: '$feedbackColorWarningPure',
          borderColor: '$feedbackColorWarningPure',
        },

        '.btn-cancel': {
          backgroundColor: '$feedbackColorSuccessPure',
          borderColor: '$feedbackColorSuccessPure',
        },
      },
    },
  },
});
