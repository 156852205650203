import React from "react";
import PropTypes from "prop-types";
import Icon from "./Icon";

const rowStyles = {
  overflowY: 'auto',
  height: '80vh',
  marginRight: '-8px',
  paddingRight: '8px'
};

const LayoutTypeEditorSelector = ({
  styles,
  state,
  layoutSelection,
  handleLayoutSelector,
}) => {
  return (
    <div style={rowStyles}>
      {layoutSelection.map((e, i) => {

        function handleSelect() {
          handleLayoutSelector({ i: e.id });
        };

        return (
          <div key={i}>
            <div
              className={`w-100 layout-selector mr-3 ${state.isLayoutSelectorActive[Number(e.id)] ? "active" : ""
                }`}
              role="button"
              onClick={handleSelect}
              tabIndex={0}
            >
              <div className="layout-selector-area">
                <div className="edit-layout-option">
                  <p
                    className={`${styles.tipography({
                      paragraphs: "mediumBold",
                    })} m-0`}
                  >
                    {e.layoutTitle}
                  </p>
                  <p
                    className={`${styles.tipography({
                      paragraphs: "caption",
                    })} m-0`}
                  >
                    {e.layoutDimensions}
                  </p>
                </div>
                <div className="edit-layout-icon text-center">
                  <Icon iconName="edit" />
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

LayoutTypeEditorSelector.propTypes = {
  styles: PropTypes.object,
  state: PropTypes.object,
  layoutSelection: PropTypes.array,
  handleLayoutSelector: PropTypes.func,
};

export default LayoutTypeEditorSelector;
