import React, { useContext, useEffect } from "react";
import PropTypes from "prop-types";
import Icon from "./Icon";
import ButtonComponent from "./Buttons";
import { CampaignContext } from '../context/campaign-context'

const LayoutTypeSelector = ({
  styles,
  state,
  layoutType,
  handleLayoutType,
  handleLayoutChoosen,
}) => {

  useEffect(() => {
    if (layoutType.length === 1) {
      handleLayoutType({ i: 0 })
    }
  }, [])

  const campaignContext = useContext(CampaignContext)
  const isComunicationApp = campaignContext?.communicationType === "app"

  return (
    <div style={{ display: 'flex', gap: '2rem', justifyContent: 'flex-start', textAlign: 'center' }}>
      {layoutType.map((e, i) => {
        return (
          <div style={{ width: isComunicationApp ? 300 : '150px', textAlign: 'center' }} key={i}>
            <div
              className={`${layoutType.length > 1 && "mx-auto"} layout-type ${(state.isLayoutActive[i] || layoutType.length === 1) ? "active" : ""
                }`}
              style={{
                flexDirection: isComunicationApp ? "column" : "row",
                maxWidth: isComunicationApp ? 300 : 150,
              }}
              role="button"
              onClick={() => {
                handleLayoutType({ i })
                if (i == 0) {
                  campaignContext.setComunicationLayoutType("default")
                }
                else if (i == 1) {
                  campaignContext.setComunicationLayoutType("custom")
                }
              }}
              tabIndex={0}
            >
              <div
                className={`layout-title ${state.isLayoutActive[i] ? "active" : ""
                  }`}
                style={{
                  backgroundImage: state.isLayoutActive[i] ? `url(${e.imageExampleHover})` : `url(${e.imageExample})`,
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center center",
                  borderRadius: 8,
                  width: e.size.width,
                  height: `${e.iconName === "" ? e.size.height : "max-content"}`,
                }}
              >
                <div
                  className={`badge-layout text-center ${e.iconName === "" && "d-none"
                    }`}
                >
                  <Icon iconName={e.iconName} />
                </div>
                <p className={`m-0 ${e.title1 === "" && "d-none"}`}>
                  {e.title1 || " "}
                </p>
                <p className={`m-0 ${e.title2 === "" && "d-none"}`}>
                  {e.title2 || " "}
                </p>
                <p className={`m-0 ${e.title3 === "" && "d-none"}`}>
                  {e.title3 || " "}
                </p>
              </div>

              {isComunicationApp && (
                <div
                  className={`${layoutType.length > 1 ? "mx-auto text-center" : ""
                    } pb-6`}
                >
                  <p className="layout-sub-title" style={{ marginBottom: 0 }}>{e.subTitle}</p>
                  <p className="layout-description">{e?.description}</p>

                </div>
              )}
            </div>

            {isComunicationApp && (
              <ButtonComponent
                className={`
                  ${styles.buttonStandardLarge({
                  large: "primary",
                })}
                  ${state.isLayoutActive[i] ? "" : "d-none"}
                  mb-3 btn-layout-edit
                `}
                text="Editar"
                styles={styles}
                onClick={() => handleLayoutChoosen({ e })}
              />
            )}

            {!isComunicationApp && (
              <div
                className={`${layoutType.length > 1 ? "mx-auto text-center" : ""
                  } mb-3 pb-3`}
              >
                <p className="layout-sub-title">{e.subTitle}</p>
                <p className="layout-description">{e?.description}</p>
                <ButtonComponent
                  className={`
                  ${styles.buttonStandardLarge({
                    large: "primary",
                  })}
                  ${state.isLayoutActive[i] ? "" : "d-none"}
                  mb-3 btn-layout-edit
                `}
                  text="Editar"
                  styles={styles}

                  onClick={() => handleLayoutChoosen({ e })}
                />
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

LayoutTypeSelector.propTypes = {
  styles: PropTypes.object,
  state: PropTypes.object,
  layoutType: PropTypes.array,
  handleLayoutType: PropTypes.func,
  handleLayoutChoosen: PropTypes.func,
};

export default LayoutTypeSelector;
