import React from 'react';
import PropTypes from 'prop-types';
import { Badge } from 'react-bootstrap';

const BadgeComponent = ({ styles, bgType, text, isBadgePill, className, onClick, style }) => {
  return isBadgePill ? (
    <Badge
      pill
      bg={bgType}
      onClick={onClick}
      style={style}      
      className={`
        ${styles.badge()}
        ${styles.tipography({ captions: 'captionBold' })}
        ${className}
      `}
    >
      {text}
    </Badge>
  ) : (
    <Badge
      bg={bgType}     
      className={`
        ${styles.badge()}
        ${styles.tipography({ captions: 'captionBold' })}
        ${className}
      `}
    >
      {text}
    </Badge>
  );
};

BadgeComponent.propTypes = {
  styles: PropTypes.object,
  bgType: PropTypes.string,
  text: PropTypes.string,
  isBadgePill: PropTypes.bool,
  className: PropTypes.string,
};

export default BadgeComponent;
