import { css } from './CreateStitches';

export const customAccordion = css({
  '&.accordion': {   
    '.accordion-item': {
      border: 'none',
      borderRadius: '$borderRadiusSM',
      overflow: 'hidden',
      marginBottom: 40,
      boxShadow: '$shadowLevel1',
     
      '.accordion-header': {
        '.accordion-button': {          
          display: 'flex',
          'align-items': 'center',
          height: 80,
          padding: '12px 12px',
          'background-color': '$neutralColorHighPure',
                
          '&::before': {
            content: '',
            display: 'inline-block',
            width: 32,
            height: 32,
            'background-image': 'var(--bs-accordion-btn-icon)',
            'background-size': 32,
            'background-position': 'center',
            'margin-right': 16,
            transition: 'transform 0.3s ease',
            transform: 'rotate(-90deg)',           
          },

          '&[aria-expanded="true"]': {
            '&::before': {
              transform: 'rotate(0deg)',
            },
          },
      
          '&::after': {
            display: 'none', /* Hide the right arrow */
          },

          '.texts': {
            marginTop: '0.65rem'
          },

          '.headerInfos': {
            width: '100%',
            display: 'flex',
            'justify-content': 'space-between'
          },

          '.endInfos': {
            display: 'flex',
            gap: '3rem'
          }
        },
      
        '.accordion-button:focus': {
          'box-shadow': 'rgba(102, 102, 102, .2) 0px calc(-1px) 0px inset',
        },
      },
      
      '.accordion-body': {
        table: {
          borderRadius: 0,

          td: {
            height: 101,

            button: {
              'span[class=""]': {
                display: 'flex',
                alignItems: 'center',
              },
            },

            '&:last-child': {
              position: 'absolute',
              padding: '9px $spacingXXS',
              margin: '$spacingXXS 0',
            },
          },
        },
      },

      '&:last-child': {
        marginBottom: 0,
      },

      '.accordion-collapse': {
        '.accordion-body': {
          padding: 0,
        },
      },
    },
  },
});
