import { css } from './CreateStitches'
import StepActive from './img/Step/step-active.png'
import StepDisabled from './img/Step/step-disabled.png'
import StepDone from './img/Step/step-done.png'

export const steps = css({
  '.steps-area': {
    borderRight: 'solid 1px $neutralColorHighLight',

    '.steps': {
      p: {
        '&:last-child': {
          marginBottom: 40,
        },
      },

      '.steps-line': {
        '&::before': {
          content: 'a',
          backgroundColor: '$neutralColorHighMedium',
          color: 'transparent',
          position: 'absolute',
          width: 1,
          height: 48,
          zIndex: 0,
          marginTop: 40,
        },

        '.step-badge': {
          zIndex: 1,

          '&.done': {
            backgroundColor: '$neutralColorHighDark',
            borderColor: '$neutralColorHighDark',
          },
        },
      },

      '&:last-child': {
        '.steps-line': {
          '&::before': {
            content: '',
            backgroundColor: 'transparent',
          },
        },
      },
    },
  },

  '.step-status': {
    '.step-marker': {
      height: 40,
      padding: 0,
      marginLeft: -1,
      marginBottom: 48,

      '&.active': {
        backgroundImage: `url(${StepActive})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
      },
      '&.done': {
        backgroundImage: `url(${StepDone})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
      },
      '&.disabled': {
        backgroundImage: `url(${StepDisabled})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
      },
    },
  },

  hr: {
    border: 'solid 1px $neutralColorHighMedium',
    margin: '16px 0',
  },

  '.badge-steps': {
    '&.bg-primary': {
      padding: '$spacingQuarck $spacingXXXS',
      backgroundColor: '$brandColorTertiaryPure !important',
    },
  },

  '.rule-type': {
    padding: '$spacingXXXS 11px',
    marginBottom: '$spacingXXXS',
    border: 'solid 1px $neutralColorHighDark',
    borderRadius: '$borderRadiusSM',
    height: 200,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',

    '&:after': {
      content: '',
      width: '$xxxs',
      height: '$xxxs',
      border: 'solid 1px $neutralColorHighDark',
      backgroundColor: '$neutralColorHighPure',
      borderRadius: 10,
      position: 'absolute',
      top: 16,
      right: 16,
    },

    '&:hover': {
      border: 'solid 2px $brandColorTertiaryPure',

      '&:before': {
        content: '',
        width: '$nano',
        height: '$nano',
        border: 'solid 1px $neutralColorHighPure',
        backgroundColor: '$neutralColorHighPure',
        borderRadius: 10,
        position: 'absolute',
        top: 20,
        right: 20,
        zIndex: 1,
      },

      '&:after': {
        content: '',
        borderColor: '$brandColorTertiaryPure',
        backgroundColor: '$brandColorTertiaryPure',
      },
    },

    '&.active': {
      backgroundColor: 'rgba(99, 0, 255, 0.08)',
      border: 'solid 2px $brandColorTertiaryPure',

      '&:before': {
        content: '',
        width: '$nano',
        height: '$nano',
        border: 'solid 1px $neutralColorHighPure',
        backgroundColor: '$neutralColorHighPure',
        borderRadius: 10,
        position: 'absolute',
        top: 20,
        right: 20,
        zIndex: 1,
      },

      '&:after': {
        content: '',
        borderColor: '$brandColorTertiaryPure',
        backgroundColor: '$brandColorTertiaryPure',
      },
    },

    '.rule-title': {
      fontFamily: '$fontFamilyHighBaseBold',
      fontSize: '$fontSizeLG',
      display: 'grid',
      justifyItems: 'center',

      '.badge-rule': {
        width: '$md',
        height: '$md',
        fontSize: '$fontSizeLG',
        border: 'solid 2px $neutralColorHighMedium',
        borderRadius: '$borderRadiusCircular',
        backgroundColor: '$neutralColorHighLight',
        color: '$neutralColorLowLight',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: 16,
      },

      '&.active': {
        '.badge-rule': {
          border: 'solid 2px $brandColorPrimaryPure',
          borderRadius: '$borderRadiusCircular',
          backgroundColor: '$brandColorPrimaryPure',
          color: '$neutralColorHighPure',
        },
      },
    },
  },

  '.layout-type': {
    padding: '0',
    marginBottom: '$spacingXXXS',
    border: 'solid 1px $neutralColorHighDark',
    borderRadius: '$borderRadiusSM',
    maxWidth: 150,
    height: 200,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    overflow: 'hidden',

    '&:after': {
      content: '',
      width: '$xxxs',
      height: '$xxxs',
      border: 'solid 1px $neutralColorHighDark',
      backgroundColor: '$neutralColorHighPure',
      borderRadius: 10,
      position: 'absolute',
      top: 16,
      right: 16,
    },

    '&:hover': {
      border: 'solid 2px $brandColorTertiaryPure',

      '&:before': {
        content: '',
        width: '$nano',
        height: '$nano',
        border: 'solid 1px $neutralColorHighPure',
        backgroundColor: '$neutralColorHighPure',
        borderRadius: 10,
        position: 'absolute',
        top: 20,
        right: 20,
        zIndex: 1,
      },

      '&:after': {
        content: '',
        borderColor: '$brandColorTertiaryPure',
        backgroundColor: '$brandColorTertiaryPure',
      },
    },

    '&.active': {
      border: 'solid 2px $brandColorTertiaryPure',
      background: "#F3EBFF",

      '&:before': {
        content: '',
        width: '$nano',
        height: '$nano',
        border: 'solid 1px $neutralColorHighPure',
        backgroundColor: '$neutralColorHighPure',
        borderRadius: 10,
        position: 'absolute',
        top: 20,
        right: 20,
        zIndex: 1,
      },

      '&:after': {
        content: '',
        borderColor: '$brandColorTertiaryPure',
        backgroundColor: '$brandColorTertiaryPure',
      },
    },

    '.layout-title': {
      display: 'grid',
      justifyItems: 'center',
      backgroundSize: 'cover',

      '.badge-layout': {
        width: '$md',
        height: '$md',
        fontSize: '$fontSizeLG',
        border: 'solid 2px $neutralColorHighMedium',
        borderRadius: '$borderRadiusCircular',
        backgroundColor: '$neutralColorHighLight',
        color: '$brandColorTertiaryPure',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: 16,
      },
    },
  },

  '.layout-sub-title': {
    fontFamily: '$fontFamilyHighBaseBold',
    fontSize: '$fontSizeLG',
  },

  '.layout-selector': {
    padding: '0',
    marginBottom: '$spacingXXXS',
    border: 'solid 1px $neutralColorHighDark',
    borderRadius: '$borderRadiusSM',
    height: 67,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',

    '&:after': {
      content: '',
      width: '$xxxs',
      height: '$xxxs',
      border: 'solid 1px $neutralColorHighDark',
      backgroundColor: '$neutralColorHighPure',
      borderRadius: 10,
      position: 'absolute',
      top: 23,
      left: 16,
    },

    '&:hover': {
      border: 'solid 2px $brandColorTertiaryPure',

      '&:before': {
        content: '',
        width: '$nano',
        height: '$nano',
        border: 'solid 1px $neutralColorHighPure',
        backgroundColor: '$neutralColorHighPure',
        borderRadius: 10,
        position: 'absolute',
        top: 27,
        left: 20,
        zIndex: 1,
      },

      '&:after': {
        content: '',
        borderColor: '$brandColorTertiaryPure',
        backgroundColor: '$brandColorTertiaryPure',
      },
    },

    '&.active': {
      border: 'solid 2px $brandColorTertiaryPure',

      '&:before': {
        content: '',
        width: '$nano',
        height: '$nano',
        border: 'solid 1px $neutralColorHighPure',
        backgroundColor: '$neutralColorHighPure',
        borderRadius: 10,
        position: 'absolute',
        top: 27,
        left: 20,
        zIndex: 1,
      },

      '&:after': {
        content: '',
        borderColor: '$brandColorTertiaryPure',
        backgroundColor: '$brandColorTertiaryPure',
      },
    },

    '.layout-selector-area': {
      display: 'flex',
      width: '100%',

      '.edit-layout-option': {
        marginLeft: 50,
        width: 220,
      },

      '.edit-layout-icon': {
        display: 'flex',
        alignItems: 'center',
        color: '$brandColorTertiaryPure',
      },
    },
  },

  '.btn-layout-edit': {
    width: 150,
  },
})
