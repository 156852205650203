import React from "react";
import PropTypes from "prop-types";
import { Col, Row } from "react-bootstrap";
import ComunicationPlatform from "./comunication-platform";
import EngagementThermometer from "./engagement-thermometer";
import {Box} from "../../Components";

const Engagement = ({ styles, state, campaign }) => {
  return (
    <Box>
      <Col xs={10}>
        <Row>
          <Col xs={12}>
            <Row>
              <ComunicationPlatform
                styles={styles}
                state={state}
                campaign={campaign}
              />
              {state.hideEngagement === false && (
                <EngagementThermometer styles={styles} state={state} />
              )}
            </Row>
          </Col>
        </Row>
      </Col>
    </Box>
  );
};

Engagement.propTypes = {
  styles: PropTypes.object,
  state: PropTypes.object,
};

export default Engagement;
