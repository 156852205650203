import React, { useEffect, useState } from 'react'
import { Auth } from 'aws-amplify'
import PropTypes from 'prop-types'
import { Col, Row, ProgressBar } from 'react-bootstrap'
import { ButtonComponent, Select } from 'cashin-components'
import api from '../API/api'
import useCampaignIdentificationInfo from '../API/queries/campaign/useCampaignIdentificationInfo'
import { useCompanyUsageBalance } from '../API/queries/company/useCompanyUsageBalance'
import useIdentificationInfo from '../API/queries/company/useIdentificationInfo'
import SchedulerComponent from '../Components/SchedulerComponent'
import CustomLabelProgressBar from '../Components/CustomLabelProgressBar'
import dayjs from 'dayjs'
import ExceededUsageLimitModal from '../Components/ExceededUsageLimitModal'
import { Box } from '../Components'

// const percent = (partialValue, totalValue) => {
//   const percentValue = 100 - Math.round((partialValue / totalValue) * 100)
//   return percentValue
// }

// const percent = (value, total) => {
//   if (!total || total === 0) {
//     return 0
//   }
//   return (value / total) * 100
// }

// const invertedPercent = (value, total) => {
//   const percentage = percent(value, total)
//   return 100 - percentage
// }

const DashboardPage = ({ styles, state, handleCreateCampaign }) => {
  const { data } = useCampaignIdentificationInfo()
  const [dashboardData, setDashboardData] = useState()
  const [dateFilter, setDateFilter] = useState(dayjs())
  const [campaignIdFilter, setCampaignIdFilter] = useState('')
  const [currentUser, setCurrentUser] = useState({})
  const [exceededUsageLimitModalIsOpen, SetExceededUsageLimitModalIsOpen] =
    useState(false)
  const { data: indentificantionData } = useIdentificationInfo()

  const companyId = currentUser?.attributes?.['custom:tenantId']
  const companyName =
    currentUser?.attributes?.['custom:profile'] === 'Admin'
      ? indentificantionData?.find(
          (x) => x.id === currentUser?.attributes?.['custom:tenantId']
        )?.description
      : currentUser?.attributes?.name

  const { data: balance } = useCompanyUsageBalance(companyId)

  const handleClose = () => SetExceededUsageLimitModalIsOpen(false)
  const handleShow = () => SetExceededUsageLimitModalIsOpen(true)

  useEffect(() => {
    const runAsync = async () => {
      setCurrentUser(await Auth.currentAuthenticatedUser())
    }

    runAsync()
  }, [companyId])

  useEffect(() => {
    search()
  }, [dateFilter, campaignIdFilter])

  const dashboardInfoCard = [
    {
      title: 'Valor em prêmios',
      value: `${(dashboardData?.totalPrizePrice || 0).toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      })}`,
      info: 'valor total de premiação distribuída',
    },
    {
      title: 'Participantes',
      value: dashboardData?.totalParticipants,
      info: 'total de participantes',
    },
    {
      title: 'Campanhas',
      value: dashboardData?.totalCampaign,
      info: 'total de campanhas ativas e concluídas',
    },
    {
      title: 'Limite de Participantes',
      value: balance?.usageLimit,
      info: balance?.balance,
    },
  ]

  const search = async () => {
    const response = await api.get(
      `/campaign-dashboard?year=${dateFilter.year()}&month=${
        dateFilter.month() + 1
      }&campaignId=${campaignIdFilter}`
    )

    setDashboardData(response)
  }

  async function handleCreateCampaignButton() {
    if (balance?.balance <= 0) {
      handleShow()
      await api.post(
        `/company-negotiation-usage/send-email/negotiation-denied?companyName=${companyName}`
      )
      return
    }

    handleCreateCampaign()
  }

  return (
    <>
      <ExceededUsageLimitModal
        styles={styles}
        size='xs'
        show={exceededUsageLimitModalIsOpen}
        onHide={handleClose}
      />
      <Row className={styles.dashboard()}>
        <Col xs={12} style={{ padding: 0 }}>
          <Row>
            <Col xs={6}>
              <Box>
                <h1
                  className={`${styles.tipography({
                    headings: 'smallBold',
                  })} my-3`}
                >
                  Início
                </h1>
              </Box>
            </Col>
            <Col xs={6} className='d-flex justify-content-end'>
              <Box>
                <ButtonComponent
                  className={`${styles.buttonStandardLarge({
                    large: 'primary',
                  })}
                ${
                  state?.isLoading &&
                  styles.buttonStandardLarge({
                    large: 'primaryLoading',
                  })
                } my-2`}
                  hasIcon={true}
                  iconName='add_circle'
                  text='Criar campanha'
                  styles={styles}
                  onClick={() => handleCreateCampaignButton()}
                />
              </Box>
            </Col>
            <Col xs={12}>
              <div
                className={`${styles.border({
                  borderBottomWidth: 'thick',
                })}   mb-4 page-border `}
              />
            </Col>
            <Col xs={4}>
              <Box>
                <Select
                  id='ddlCampaignFilter'
                  idError='fdbError'
                  labelName='Campanha'
                  options={[
                    { value: '', label: 'Todas campanhas' },
                    ...(data?.map((x) => ({
                      value: x.id,
                      label: x.description,
                    })) || []),
                  ]}
                  onChange={(e) => setCampaignIdFilter(e.value)}
                  styles={styles}
                />
              </Box>
            </Col>
          </Row>
          <Row>
            {dashboardInfoCard?.map((e, i) => {
              return (
                <Col xs key={i}>
                  <Box>
                    <div
                      className={`${styles.shadow({
                        sizeBG: 'layerSize',
                        shadowLevel: `level1`,
                      })} ${styles.tipography({
                        margins: 'marginBottomDefault',
                      })} my-4 w-100 dashboard-info-area`}
                    >
                      <p className='title-dashboard'>{e.title}</p>
                      <p className='value-dashboard'>{e.value}</p>
                      {e.title === 'Limite de Participantes' ? (
                        <>
                          <ProgressBar
                            now={(e.info * 100) / e.value}
                            className={styles.progressBar({
                              progressBar: 'default',
                            })}
                          />
                          <CustomLabelProgressBar balance={e.info} />
                        </>
                      ) : (
                        <p className='info-dashboard'>{e.info}</p>
                      )}
                    </div>
                  </Box>
                </Col>
              )
            })}
          </Row>
          <Row>
            <Col xs={12}>
              <div className='calendar-area'>
                <Box>
                  <h2 className={styles.tipography({ fontSizes: 'hxs' })}>
                    Calendário
                  </h2>
                  <SchedulerComponent
                    state={state}
                    style={styles}
                    dashboardData={dashboardData}
                  />
                </Box>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  )
}

DashboardPage.propTypes = {
  styles: PropTypes.object,
  state: PropTypes.object,
  handleCreateCampaign: PropTypes.func,
}

export default DashboardPage
