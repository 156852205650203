import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { Check } from 'cashin-components';

const EngagementThermometer = ({ styles, state }) => {
  const value = 500;

  return (
    <Col xs={6}>
      <h2
        className={`
          ${styles.tipography({ paragraphs: 'largeBold' })}
          mb-3
        `}
      >
        Termômetro de engajamento
      </h2>
      <Row>
        <Col xs={6}>
          <div className="engagement-chart">
            <CircularProgressbarWithChildren
              value={(value / 1000) * 100}
              circleRatio={0.5}
              styles={buildStyles({
                rotation: -2.5 / 10,
                strokeLinecap: 'round',
                trailColor: '#D5D8E4',
              })}
            >
              <p
                className={`
                  ${styles.tipography({ headings: 'largeBold' })}
                  m-0
                `}
              >
                {value}
              </p>
              <p
                className={`
                  ${styles.tipography({ paragraphs: 'mediumRegular' })}
                  m-0
                `}
              >
                de 1000
              </p>
            </CircularProgressbarWithChildren>
          </div>
        </Col>
        <Col xs={6}>
          <Check
            id="chkTimeResponse"
            label="Tempo de Resposta"
            type="checkbox"
            checked={true}
            className={styles.check({ checkbox: 'normal' })}
            disabled={true}
          />
          <Check
            id="chkInterationLevel"
            label="Nível de Interação"
            type="checkbox"
            checked={true}
            className={styles.check({ checkbox: 'normal' })}
            disabled={true}
          />
          <Check
            id="chkCTR"
            label="CTR (Clickthrough Rate)"
            type="checkbox"
            checked={true}
            className={styles.check({ checkbox: 'normal' })}
            disabled={true}
          />
        </Col>
      </Row>
    </Col>
  );
};

EngagementThermometer.propTypes = {
  styles: PropTypes.object,
  state: PropTypes.object,
};

export default EngagementThermometer;
