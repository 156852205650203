import { formatDateWithHours } from "../../functions/date";
import { formatIntToMonetaryString } from "../../functions/money";

export const transformPrizeReportDataForXlxs = (headers, propertyNames, data) => {
  data.map(item => {
    item.amount = formatIntToMonetaryString(item.amount);
    item.approvedAt = formatDateWithHours(item.approvedAt);
    return item;
  })

  const transformedData = data.map(item => {
    const transformedItem = {};
    propertyNames.forEach((propertyName, index) => {
      transformedItem[headers[index]] = item[propertyName];
    });

    return transformedItem;
  });

  return transformedData;
} 