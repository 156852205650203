import React, { useContext, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Col, Row } from 'react-bootstrap'
import {
  BadgeComponent,
  Check,
  Input,
  ButtonComponent,
} from 'cashin-components'
import ParticipantsTabs from './participants-tabs'
import StepsControls from '../steps-controls'
import CampaignRulesForm from './campaign-rules-form'
import { CampaignContext } from '../../../context/campaign-context'
import { useCompanyUsageBalance } from '../../../API/queries/company/useCompanyUsageBalance'
import ExceededUsageLimitModal from '../../../Components/ExceededUsageLimitModal'
import { toast } from 'react-toastify'
import DeleteSegmentModal from './delete-segment-modal'
import { LabeledProgressBar } from '../../../Components/LabeledProgressBar'

const tagStyle = {
  display: 'inline-block',
  padding: '5px 10px',
  alignItems: 'center',
  border: '1px solid #CCCCCC',
  borderRadius: '15px',
  position: 'relative',
  marginRight: '5px',
  marginBottom: '10px',
}

const removeTagStyle = {
  cursor: 'pointer',
  color: 'white',
  borderRadius: '50%',
  width: '15px',
  height: '15px',
  lineHeight: '15px',
  textAlign: 'center',
  fontSize: '12px',
  marginRight: '5px',
  color: '#666666',
  fontWeight: 'bold',
}

const Participants = ({
  styles,
  state,
  steps,
  handleStepBack,
  handleStepForward,
  handleSaveAndContinue,
  handleUploadChangeBatch,
  handleDeleteFile,
}) => {
  const [exceededUsageLimitModalIsOpen, SetExceededUsageLimitModalIsOpen] =
    useState(false)
  const [canProceedToNextStep, setCanProceedToNextStep] = useState(false)
  // const [isSegmentation, setIsSegmentation] = useState('');
  const [tagsSegment, setTagsSegment] = useState([])
  const [currentTagSegment, setCurrentTagSegment] = useState({
    value: null,
    label: '',
  })
  const [showDeleteSegmentModalOpen, setShowDeleteSegmentModalOpen] =
    useState(false)
  const [tagNameSegmentDelete, setTagNameSegmentDelete] = useState({
    value: null,
    label: '',
  })
  const [indexSegmentDelete, setIndexSegmentDelete] = useState(null)

  const handleClose = () => SetExceededUsageLimitModalIsOpen(false)
  const handleShow = () => SetExceededUsageLimitModalIsOpen(true)

  const campaignContext = useContext(CampaignContext)
  const [currentTab, setCurrentTab] = useState('manual-input')

  const { data: balance } = useCompanyUsageBalance(campaignContext.companyId)

  const handleAddTagSegment = () => {
    if (currentTagSegment.label.length > 50) {
      toast.error('O nome da segmentação deve ter no máximo 50 caracteres')
      return
    } else if (
      tagsSegment
        .map((tag) => tag.label.toLowerCase())
        .includes(currentTagSegment.label.toLowerCase())
    ) {
      toast.error('O nome da segmentação já foi adicionado')
      return
    } else if (currentTagSegment.label.trim() !== '') {
      setTagsSegment([...tagsSegment, currentTagSegment])
      campaignContext.setSegmentTags([...tagsSegment, currentTagSegment])
      setCurrentTagSegment({ value: null, label: '' })
    }
  }

  const handleRemoveTagSegment = (tagName, indexToRemove) => {
    if (
      campaignContext.participants.filter(
        (participant) => participant.SegmentationName === tagName.label
      ).length > 0
    ) {
      setShowDeleteSegmentModalOpen(true)
      setTagNameSegmentDelete(tagName)
      setIndexSegmentDelete(indexToRemove)
    } else {
      const newTags = tagsSegment.filter((_, index) => index !== indexToRemove)
      setTagsSegment(newTags)
      campaignContext.setSegmentTags(newTags)
    }
  }

  const handleOnDeleteTagsParticipants = () => {
    const newTags = tagsSegment.filter(
      (_, index) => index !== indexSegmentDelete
    )
    setTagsSegment(newTags)
    setShowDeleteSegmentModalOpen(false)
    setTagNameSegmentDelete({
      value: null,
      label: '',
    })
    setIndexSegmentDelete(null)
    campaignContext.setSegmentTags(newTags)
    campaignContext.setParticipants(
      campaignContext.participants.filter(
        (participant) =>
          participant.SegmentationName !== tagNameSegmentDelete.label
      )
    )
  }

  const handleStepForwardMiddleware = async (...props) => {
    if (balance?.balance <= 0) {
      handleShow()
      return
    }

    if (currentTab !== 'manual-input') return handleStepForward(...props)

    if (campaignContext.isSegmentation === 'yes' && tagsSegment.length === 0)
      return

    // if (isSegmentation === "no") {
    //   campaignContext.setSegmentTags([]);
    // }

    try {
      await campaignContext.insertListOfParticipants()
      handleStepForward(...props)
    } catch {}
  }

  const handleCheckAllTagsInParticipants = () => {
    if (currentTab !== 'manual-input') {
      return true
    }

    if (tagsSegment.length === 0) {
      return false
    }

    return tagsSegment.every((tag) => {
      return campaignContext.participants.some(
        (participant) => participant.SegmentationName === tag.label
      )
    })
  }

  useEffect(() => {
    if (
      campaignContext.isSegmentation === 'yes' &&
      campaignContext.updateListParticipants
    ) {
      campaignContext.setUpdateListParticipants(false)
      handleCheckAllTagsInParticipants()
    }
  }, [campaignContext.participants, campaignContext.updateListParticipants])

  useEffect(() => {
    // setIsSegmentation(campaignContext.segmentTags.length > 0 ? 'yes' : 'no');
    setTagsSegment(campaignContext.segmentTags)
  }, [campaignContext.segmentTags])

  // useEffect(() => {
  //   if (campaignContext.isSegmentation === "yes") {
  //     const filteredParticipants = campaignContext.participants.filter(participant => participant.segment !== "Não segmentado");
  //     campaignContext.setParticipants(filteredParticipants);
  //   }
  //   else {
  //     campaignContext.setParticipants([{ segment: "Não segmentado" }])
  //   }
  // }, [campaignContext.isSegmentation]);

  useEffect(() => {
    if (
      campaignContext.isSegmentation === 'yes' &&
      campaignContext.updateListParticipants
    ) {
      campaignContext.setUpdateListParticipants(false)
      handleCheckAllTagsInParticipants()
    }
  }, [campaignContext.participants, campaignContext.updateListParticipants])

  useEffect(() => {
    // setIsSegmentation(campaignContext.segmentTags.length > 0 ? 'yes' : 'no');
    setTagsSegment(campaignContext.segmentTags)
  }, [campaignContext.segmentTags])

  // useEffect(() => {
  //   if (campaignContext.isSegmentation === "yes") {
  //     const filteredParticipants = campaignContext.participants.filter(participant => participant.segment !== "Não segmentado");
  //     campaignContext.setParticipants(filteredParticipants);
  //   }
  //   else {
  //     campaignContext.setParticipants([{ segment: "Não segmentado" }])
  //   }
  // }, [campaignContext.isSegmentation]);

  return (
    <>
      <ExceededUsageLimitModal
        styles={styles}
        size='xs'
        show={exceededUsageLimitModalIsOpen}
        onHide={handleClose}
      />
      <DeleteSegmentModal
        styles={styles}
        size='xs'
        show={showDeleteSegmentModalOpen}
        onHide={() => setShowDeleteSegmentModalOpen(false)}
        onDelete={handleOnDeleteTagsParticipants}
      />
      <Col xs={10} className='pt-4 px-5'>
        <Row>
          <Col xs={12}>
            <BadgeComponent
              text={`Etapa ${state.activeStep}/${steps.length}`}
              styles={styles}
              className='badge-steps'
            />
          </Col>
          <Col xs={12}>
            <Row>
              <h2
                className={`
                  ${styles.tipography({ headings: 'smallBold' })}
                  mt-2 mb-3
                `}
              >
                Vamos criar a sua campanha?
              </h2>
            </Row>
            <hr />
          </Col>
          <Col xs={12}>
            <CampaignRulesForm onFieldError={setCanProceedToNextStep} />
            <hr />
            <Row>
              <Col xs={6}>
                <h2
                  className={`
                    ${styles.tipography({ headings: 'smallBold' })}
                    mt-2 mb-4 pb-1
                  `}
                >
                  Cadastre os participantes:
                </h2>
              </Col>
              <Col xs={{span: 4, offset: 2}}>
                <LabeledProgressBar label={`Restam ${balance?.balance}`} progress={balance?.balance * 100 / balance?.usageLimit} />
              </Col>
            </Row>
            <hr />
            <p
              className={`
                ${styles.tipography({ paragraphs: 'mediumRegular' })}
                mt-2 mb-4 pb-1
              `}
            >
              Você gostaria de segmentar sua base de participantes?
            </p>
            <div className=' my-3 d-flex'>
              <Check
                id='yes'
                name='segmentParcipants'
                label='Sim'
                type='radio'
                value='yes'
                checked={campaignContext.isSegmentation === 'yes'}
                onChange={(e) => {
                  campaignContext.setParticipants([])
                  campaignContext.setSegmentTags([])
                  campaignContext.setIsSegmentation(e.target.id)
                }}
                className={`${styles.check({ radio: 'normal' })} me-3`}
              />

              <Check
                id='no'
                name='segmentParcipants'
                label='Não'
                type='radio'
                value='no'
                checked={campaignContext.isSegmentation === 'no'}
                onChange={(e) => {
                  campaignContext.setParticipants([])
                  campaignContext.setSegmentTags([
                    { value: null, label: 'Não segmentado' },
                  ])
                  campaignContext.setIsSegmentation(e.target.id)
                }}
                className={`${styles.check({ radio: 'normal' })} me-3`}
              />
            </div>
            {campaignContext.isSegmentation === 'yes' && (
              <>
                <Row>
                  <Col xs>
                    <Input
                      id='txtNamesSegment'
                      className={styles.inputs({ input: 'default' })}
                      inputType='text'
                      labelName='Adicione nomes para sua segmentação'
                      styles={styles}
                      placeholder='Ex.: Gerente, Coordenador, Vendedor'
                      value={currentTagSegment.label}
                      onChange={(e) =>
                        setCurrentTagSegment({
                          ...currentTagSegment,
                          label: e.target.value,
                        })
                      }
                      maxLength={50}
                    />
                  </Col>
                  <Col xs={'auto'} className='d-flex align-items-end'>
                    <ButtonComponent
                      className={`
                    ${styles.buttonSquareLarge({
                      large: 'primary',
                    })}
                    mb-3
                  `}
                      hasIcon={true}
                      iconName='add'
                      onClick={handleAddTagSegment}
                    />
                  </Col>
                </Row>
                <div className='mb-3'>
                  {tagsSegment.map(
                    (tag, index) =>
                      campaignContext.isSegmentation === 'yes' && (
                        <div
                          key={index}
                          style={tagStyle}
                          className='mr-2 d-inline-flex align-items-center'
                        >
                          <span
                            style={removeTagStyle}
                            onClick={() => handleRemoveTagSegment(tag, index)}
                          >
                            X
                          </span>
                          <span
                            style={{
                              fontWeight: 'bold',
                              marginLeft: '5px',
                              color: '#666666',
                            }}
                          >
                            {tag.label}
                          </span>
                        </div>
                      )
                  )}
                </div>
              </>
            )}
          </Col>
          {/* <Col>
            <p
              className={`
                ${styles.tipography({ paragraphs: "mediumRegular" })}
                mt-2 mb-4 pb-1
              `}          
            >
              Limite de participantes disponiveis: <b style={{ color: '#A500B9' }}>{balance?.balance}</b>
            </p>
          </Col> */}
          <Col xs={12}>
            {(campaignContext.isSegmentation === 'no' ||
              (campaignContext.isSegmentation === 'yes' &&
                tagsSegment.length > 0)) && (
              <ParticipantsTabs
                styles={styles}
                state={state}
                isSegmentation={campaignContext.isSegmentation}
                tagsSegment={tagsSegment}
                handleUploadChangeBatch={handleUploadChangeBatch}
                handleDeleteFile={handleDeleteFile}
                onChangeCurrentTab={setCurrentTab}
                currentTab={currentTab}
              />
            )}
          </Col>
          <StepsControls
            styles={styles}
            state={state}
            handleStepBack={handleStepBack}
            handleStepForward={handleStepForwardMiddleware}
            handleSaveAndContinue={handleSaveAndContinue}
            isLoading={campaignContext.participantCreatingIsLoading}
            canProceedToNextStep={
              campaignContext.isSegmentation === 'no'
                ? canProceedToNextStep &&
                  ((currentTab !== 'manual-input' &&
                    !campaignContext.participantCreatingIsLoading &&
                    campaignContext.participantBaseId) ||
                    (currentTab === 'manual-input' &&
                      !campaignContext.participantCreatingIsLoading &&
                      !!campaignContext.participants.length))
                : canProceedToNextStep &&
                  ((currentTab !== 'manual-input' &&
                    !campaignContext.participantCreatingIsLoading &&
                    campaignContext.participantBaseId) ||
                    (currentTab === 'manual-input' &&
                      !campaignContext.participantCreatingIsLoading &&
                      !!campaignContext.participants.length)) &&
                  handleCheckAllTagsInParticipants()
            }
          />
        </Row>
      </Col>
    </>
  )
}

Participants.propTypes = {
  styles: PropTypes.object,
  state: PropTypes.object,
  steps: PropTypes.array,
  handleStepBack: PropTypes.func,
  handleStepForward: PropTypes.func,
  handleSaveAndContinue: PropTypes.func,
  handleUploadChangeBatch: PropTypes.func,
  handleDeleteFile: PropTypes.func,
}

export default Participants
