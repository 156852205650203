import { apiv2 } from "../apiv2";

export const getCommunicationReport = async (campaignId, paramName = '', paramValue = '', status, from, to, page = 1) => {
  return await apiv2.get(`/communication-report/${campaignId}`, {
    params: {
      page: page,
      ...(paramName && paramValue && {[paramName]: paramValue}),
      ...(status && { status }),
      ...(from && { from }),
      ...(to && { to }),
    }
  });
}

export const getCommunicationReportExport = async (campaignId, paramName = '', paramValue = '', status, from, to, page = 1) => {
  return await apiv2.get(`/communication-report/${campaignId}/export`, {
    params: {
      page: page,
      ...(paramName && paramValue && {[paramName]: paramValue}),
      ...(status && { status }),
      ...(from && { from }),
      ...(to && { to }),
    }
  });
}
