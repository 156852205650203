import { Accordion } from "react-bootstrap";
import { styles } from 'cashin-design-system/Styles/Styles'
import { getRandomNumber } from "../../functions/utils";
import { CampaignTable } from "./CampaignTable";

export const CampaignAccordion = ({ data }) => {
  const themeColors = [
    '#A500B9',
    '#D778CD',
    '#FF7503',
    '#1B7D2C',
    '#76E388',
    '#666666',
    '#5900E1',
  ]

  return (
    <Accordion className={styles.customAccordion()} defaultActiveKey='0'>
      {data &&
        data.map((item, i) => {
          const randomIndex = getRandomNumber(themeColors.length)
          const randomColor = themeColors[randomIndex]

          return (
            <Accordion.Item
              eventKey={i}
              style={{ borderLeft: `4px solid ${randomColor}` }}
            >
              <Accordion.Header>
                <div className='headerInfos'>
                  <div className='texts'>
                    <span
                      className={styles.tipography({
                        paragraphs: 'mediumBold',
                      })}
                    >
                    {item?.name}
                    </span>
                  </div>
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <CampaignTable item={data[i]} />
              </Accordion.Body>
            </Accordion.Item>
          )
        })
      }
    </Accordion>
  )
}
