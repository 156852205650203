import React from 'react'
import PropTypes from 'prop-types'
import { TabsComponent } from 'cashin-components'
import ParticipantsManualInput from './participants-manual-input'
import ParticipantsBatchInput from './participants-batch-input'

const ParticipantsTabs = ({
  styles,
  state,
  isSegmentation,
  tagsSegment,
  currentTab,
  handleUploadChangeBatch,
  handleDeleteFile,
  onChangeCurrentTab,
}) => {
  const tabs = [
    {
      eventKey: 'manual-input',
      title: 'Envio manual',
      active: true,
      content:
        currentTab === 'manual-input' ? (
          <ParticipantsManualInput
            styles={styles}
            state={state}
            isSegmentation={isSegmentation}
            tagsSegment={tagsSegment}
          />
        ) : null,
    },
    {
      eventKey: 'batch-input',
      title: 'Envio em lote',
      active: false,
      content: (
        <ParticipantsBatchInput
          styles={styles}
          state={state}
          tagsSegment={isSegmentation === 'yes' ? tagsSegment : []}
          handleUploadChangeBatch={handleUploadChangeBatch}
          handleDeleteFile={handleDeleteFile}
        />
      ),
    },
  ]

  return (
    <TabsComponent
      items={tabs}
      tabID='participant-tab-input'
      contentTabID='content-example'
      styles={styles}
      hasBiggerFont={true}
      onClick={(e) => onChangeCurrentTab(e.target.id)}
      className={`
        ${styles.tabs({ tabsType: 'main' })}
        tab-bg
      `}
    />
  )
}

ParticipantsTabs.propTypes = {
  styles: PropTypes.object,
  state: PropTypes.object,
  handleUploadChangeBatch: PropTypes.func,
  handleDeleteFile: PropTypes.func,
}

export default ParticipantsTabs
