import React, { useContext, useState, useEffect } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types'
import { Col, Row } from 'react-bootstrap';
import { BadgeComponent, Select, Check } from 'cashin-components';
import ComunicationPlatform from './comunication-platform';
import StepsControls from '../steps-controls';
import { CampaignContext } from '../../../context/campaign-context';
import { Auth } from 'aws-amplify';
import TrainingVideo from './training-video';
import TrainingQuiz from './training-quiz';
import TrainingEbook from './training-ebook';

export const Engagement = ({
  styles, state, steps, handleStepBack, handleStepForward, handleSaveAndContinue,
  // handlePlatformComunication,
}) => {
  const campaignContext = useContext(CampaignContext);
  const [programSelected, setProgramSelected] = useState(null);
  const [programs, setPrograms] = useState([]);
  const [showTrainingVideo, setShowTrainingVideo] = useState(false);
  const [showTrainingQuiz, setShowTrainingQuiz] = useState(false);
  const [showTrainingEbook, setShowTrainingEbook] = useState(false);
  const [choicesSavedConentVideo, setChoicesSavedConentVideo] = useState('none'); // 'none', 'draft', 'published'
  const [choicesSavedConentQuiz, setChoicesSavedConentQuiz] = useState('none'); // 'none', 'draft', 'published'
  const [choicesSavedConentEbook, setChoicesSavedConentEbook] = useState('none'); // 'none', 'draft', 'published'
  const [loading, setLoading] = useState(false);

  const getPrograms = async () => {
    const user = await Auth.currentAuthenticatedUser();
    const tenantId = user?.attributes?.['custom:tenantId'];

    await axios
      .get(`${process.env.REACT_APP_API_ENGAJE}get-programs/${tenantId}`)
      .then((response) => {
        const { data } = response;
        setPrograms(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleSetProgram = (programId) => {
    campaignContext.setCampaignState((currentState) => ({
      ...currentState,
      programId,
      programName: programs.find((item) => item._id === programId)?.name,
    }));
  };

  useEffect(() => {
    if (campaignContext.communicationType.toLowerCase() === 'app') {
      getPrograms();
    }
  }, [campaignContext.communicationType]);

  useEffect(() => {
    if (campaignContext.communicationType.toLowerCase() === 'app' &&
      programs.length === 1) {
      handleSetProgram(programs[0]._id, programs[0].name);
    }
  }, [programs, setPrograms, campaignContext.communicationType]);


  return (
    <Col xs={10} className='pt-4 px-5'>
      <Row>
        <Col xs={12}>
          <BadgeComponent
            text={`Etapa ${state.activeStep}/${steps.length}`}
            styles={styles}
            className='badge-steps' />
        </Col>
        <Col xs={12}>
          <h2
            className={`
              ${styles.tipography({ headings: 'smallBold' })}
              my-2
            `}
          >
            Estratégia de engajamento
          </h2>
          <p
            className={`
              ${styles.tipography({ fontSizes: 'sm' })}
              ${styles.colors({ colors: 'neutralColorLowMedium' })}
            `}
          >
            Aqui você escolhe o canal de comunicação com os Participantes.
          </p>
          <hr />
          <Row>
            <ComunicationPlatform />
            {/* <EngagementThermometer styles={styles} state={state} /> */}
            <hr />

            {campaignContext.communicationType === 'app' && (
              <>
                <Row>
                  <p
                    className={`
                    ${styles.tipography({ fontSizes: 'sm' })}
                    ${styles.colors({ colors: 'neutralColorLowMedium' })}
                  `}
                  >
                    Essa campanha será de qual programa?
                  </p>

                  <Col xs={4}>
                    <Select
                      id='ddlCampaignFilter'
                      idError='fdbError'
                      labelName='Programa'
                      placeholder='Selecione o programa'
                      options={[
                        ...(programs?.map((x) => ({
                          value: x?._id,
                          label: x?.name,
                        })) || []),
                      ]}
                      selectedValue={programs.length === 1
                        ? {
                          value: programs[0]?._id,
                          label: programs[0]?.name,
                        }
                        : programSelected === null
                          ? null
                          : programs.length === 0
                            ? null
                            : {
                              value: campaignContext.campaignState.programId,
                              label: programs.find(
                                (x) => x?._id ===
                                  campaignContext.campaignState.programId
                              )?.name,
                            }}
                      onChange={(e) => {
                        handleSetProgram(e.value);
                        setProgramSelected(e);
                      }}
                      styles={styles} />
                  </Col>
                </Row>

                {/* <Row className='mt-4'>
                  <h3 className={`${styles.tipography({ paragraphs: 'largeBold' })}`}>
                    Sua campanha terá treinamento?
                  </h3>
                  <div className='form-control my-3 p-4 d-flex' style={{ marginLeft: 10 }}>
                    <Check
                      id='video'
                      name='swtEngagementGroup'
                      label='Vídeo'
                      type='switch'
                      checked={showTrainingVideo}
                      onChange={(e) => {
                        setShowTrainingVideo(e.target.checked);
                        if (!e.target.checked) {
                          setChoicesSavedConentVideo('none');
                        } else {
                          setChoicesSavedConentVideo('draft');
                        }
                      }}
                      className={`
                        ${styles.check({ switch: 'normal' })}
                         ps-0 me-3
                      `} />
                    <Check
                      id='quiz'
                      name='swtEngagementGroup'
                      label='Quiz'
                      type='switch'
                      checked={showTrainingQuiz}
                      onChange={(e) => {
                        setShowTrainingQuiz(e.target.checked)
                        if (!e.target.checked) {
                          setChoicesSavedConentQuiz('none')
                        } else {
                          setChoicesSavedConentQuiz('draft')
                        }
                      }}
                      className={`
                        ${styles.check({ switch: 'normal' })}
                         ps-0 me-3
                      `} />
                    <Check
                      id='pdf'
                      name='swtEngagementGroup'
                      label='Arquivo PDF'
                      type='switch'
                      checked={showTrainingEbook}
                      onChange={(e) => {
                        setShowTrainingEbook(e.target.checked)
                        if (!e.target.checked) {
                          setChoicesSavedConentEbook('none')
                        } else {
                          setChoicesSavedConentEbook('draft')
                        }
                      }}
                      className={`
                        ${styles.check({ switch: 'normal' })}
                         ps-0 me-3
                      `} />
                  </div>
                </Row> */}

                {showTrainingVideo && (
                  <TrainingVideo styles={styles} loading={loading} setLoading={setLoading} setShowTrainingVideo={setShowTrainingVideo} setChoicesSavedConentVideo={setChoicesSavedConentVideo} />
                )}
                {showTrainingQuiz && (
                  <TrainingQuiz styles={styles} loading={loading} setLoading={setLoading} setShowTrainingQuiz={setShowTrainingQuiz} setChoicesSavedConentQuiz={setChoicesSavedConentQuiz} />
                )}
                {showTrainingEbook && (
                  <TrainingEbook styles={styles} loading={loading} setLoading={setLoading} setShowTrainingEbook={setShowTrainingEbook} setChoicesSavedConentEbook={setChoicesSavedConentEbook} />
                )}
              </>
            )}

            <StepsControls
              styles={styles}
              state={state}
              handleStepBack={handleStepBack}
              handleStepForward={handleStepForward}
              handleSaveAndContinue={handleSaveAndContinue}
              canProceedToNextStep={campaignContext.communicationType.toLowerCase() === 'email' ||
                campaignContext.communicationType.toLowerCase() ===
                'whatsapp' ||
                (campaignContext.communicationType.toLowerCase() === 'app' &&
                  (programs !== null || programs.length === 1) &&
                  campaignContext.campaignState.programId)}
            // && choicesSavedConentVideo !== 'draft' &&
            // choicesSavedConentQuiz !== 'draft' &&
            // choicesSavedConentEbook !== 'draft'}
            />
          </Row>
        </Col>
      </Row>
    </Col>
  );
};
Engagement.propTypes = {
  styles: PropTypes.object,
  state: PropTypes.object,
  steps: PropTypes.array,
  handleStepBack: PropTypes.func,
  handleStepForward: PropTypes.func,
  handleSaveAndContinue: PropTypes.func,
  handlePlatformComunication: PropTypes.func,
}

export default Engagement