import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ProfilePage from './profile-page';

const defaultState = {
  valueStatusOptions: [
    {
      value: 1,
      label: 'Todos',
    },
  ],
  breadcrumb: [
    {
      isActive: true,
      itemName: 'Meu perfil',
    },
  ],
};

const Profile = ({ styles, history }) => {
  const [ownState, setOwnState] = useState(defaultState);
  const [showSaveProfileModal, setShowSaveProfileModal] = useState(false);

  const handleCloseSaveProfileModal = () => setShowSaveProfileModal(false);
  const handleShowSaveProfileModal = () => setShowSaveProfileModal(true);

  const handleSaveProfile = () => {
    handleShowSaveProfileModal();
  };

  return (
    <ProfilePage
      styles={styles}
      state={ownState}
      showSaveProfileModal={showSaveProfileModal}
      handleSaveProfile={handleSaveProfile}
      handleCloseSaveProfileModal={handleCloseSaveProfileModal}
    />
  );
};

Profile.propTypes = {
  styles: PropTypes.object,
  history: PropTypes.object,
};

export default Profile;
