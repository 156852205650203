import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { Col, } from "react-bootstrap";
import { Input } from "cashin-components";
import { Check } from "cashin-components";
import { CampaignContext } from '../../context/campaign-context'

const ComunicationPlatform = ({ styles, state, campaign }) => {
  const campaignContext = useContext(CampaignContext)
  const [layoutSelected, setLayoutSelected] = useState("")

  const handleSelectedCheck = () => {
    if (campaign?.isApp) {
      setLayoutSelected("app")
    } else if (campaign?.notificationLayout?.notificationDispatches?.[0]?.imageUrl) {
      setLayoutSelected("email")
    }
    else {
      setLayoutSelected("whatsapp")
    }
  }

  useEffect(() => {
    handleSelectedCheck()
  }, [campaign, layoutSelected, setLayoutSelected])

  return (
    <Col xs={6}>
      <h2 className={`${styles.tipography({ paragraphs: "largeBold" })}`}>
        Plataforma de comunicação
      </h2>
      <div className="form-control my-3 p-4 d-flex">
        <Check
          id="swtEmailComunication"
          label="E-mail"
          type="switch"
          className={`
                  ${styles.check({ switch: "normal" })}
                  ps-0 me-3
                `}
          checked={layoutSelected === "email"}
          disabled
        />
        <Check
          id="swtWhatsAppComunication"
          label="WhatsApp"
          type="switch"
          className={`
                  ${styles.check({ switch: "normal" })}
                  ps-0 me-3
                `}
          checked={layoutSelected === "whatsapp"}
          disabled
        />
        <Check
          id='swtAppComunication'
          label='App'
          type='switch'
          className={`
            ${styles.check({ switch: 'normal' })}
            ps-0
          `}
          checked={layoutSelected === 'app'}
          disabled
        />
      </div>

      {(campaign?.isApp) && (
        <Col xs={12}>
          <Input
            id='txtProgramName'
            className={styles.inputs({ input: 'default' })}
            inputType='text'
            labelName='Nome do programa'
            styles={styles}
            value={campaign?.programName || campaignContext?.companyName}
            disabled={true}
          />
        </Col>
      )}
    </Col>
  );
};

ComunicationPlatform.propTypes = {
  styles: PropTypes.object,
  state: PropTypes.object,
};

export default ComunicationPlatform;
