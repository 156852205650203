import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { Col } from 'react-bootstrap'
import { Check } from 'cashin-components'
import { CampaignContext } from '../../../context/campaign-context'
import { styles } from 'cashin-design-system/Styles/Styles'

const ComunicationPlatform = () => {
  const campaignContext = useContext(CampaignContext)

  return (
    <Col xs={6}>
      <h3 className={`${styles.tipography({ paragraphs: 'largeBold' })}`}>
        Onde a sua campanha vai acontecer? Escolha a plataforma.
      </h3>
      <div className='form-control my-3 p-4 d-flex'>
        <Check
          id='email'
          name='swtEngagementGroup'
          label='E-mail'
          type='radio'
          checked={campaignContext.communicationType === 'email'}
          className={`
            ${styles.check({ radio: 'normal' })}
            me-3
          `}
          onChange={(e) => campaignContext.setCommunicationType(e.target.id)}
          onClick={(e) => campaignContext.setCommunicationType(e.target.id)}
        />
        <Check
          id='whatsapp'
          name='whatsapp'
          label='WhatsApp'
          type='radio'
          checked={campaignContext.communicationType === 'whatsapp'}
          className={`
            ${styles.check({ radio: 'normal' })}
            me-3
          `}
          onChange={(e) => campaignContext.setCommunicationType(e.target.id)}
          onClick={(e) => campaignContext.setCommunicationType(e.target.id)}
        />
        <Check
          id='app'
          name='app'
          label='App'
          type='radio'
          checked={campaignContext.communicationType === 'app'}
          className={`
            ${styles.check({ radio: 'normal' })}
            me-3
          `}
          onChange={(e) => campaignContext.setCommunicationType(e.target.id)}
          onClick={(e) => campaignContext.setCommunicationType(e.target.id)}
        />
      </div>
    </Col>
  )
}

ComunicationPlatform.propTypes = {
  styles: PropTypes.object,
  state: PropTypes.object,
  handlePlatformComunication: PropTypes.func,
}

export default ComunicationPlatform
