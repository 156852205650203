import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Auth } from 'aws-amplify'
import { Col, Row } from 'react-bootstrap'
import { ButtonComponent, BreadcrumbComponent } from 'cashin-components'
import DeleteCampaignModal from './delete-campaign-modal'
import ExceededUsageLimitModal from '../Components/ExceededUsageLimitModal'
import CampaignPaginationPageBase from '../base-components/campaign-pagination/campaign-pagination-page-base'
import { useCompanyUsageBalance } from '../API/queries/company/useCompanyUsageBalance'
import { NegotiationUsageTypeAction } from '../enums/NegotiationUsageTypeAction'
import api from '../API/api'
import useIdentificationInfo from '../API/queries/company/useIdentificationInfo'
import { Box } from '../Components'

const CampaignsPage = ({ styles, state, handleCreateCampaign }) => {
  const [deleteCampaignModalIsOpen, setDeleteCampaignModalIsOpen] =
    useState(false)
  const [campaignId, setCampaignId] = useState()
  const [currentUser, setCurrentUser] = useState({})
  const [campaign, setCampaign] = useState({})
  const [exceededUsageLimitModalIsOpen, SetExceededUsageLimitModalIsOpen] =
    useState(false)
  const { data: indentificantionData } = useIdentificationInfo()

  const companyId = currentUser?.attributes?.['custom:tenantId']
  const companyName =
    currentUser?.attributes?.['custom:profile'] === 'Admin'
      ? indentificantionData?.find(
          (x) => x.id === currentUser?.attributes?.['custom:tenantId']
        )?.description
      : currentUser?.attributes?.name

  const { data: balance } = useCompanyUsageBalance(companyId)

  const handleClose = () => SetExceededUsageLimitModalIsOpen(false)
  const handleShow = () => SetExceededUsageLimitModalIsOpen(true)

  useEffect(() => {
    const runAsync = async () => {
      setCurrentUser(await Auth.currentAuthenticatedUser())
    }

    const getCampaignById = async () => {
      const response = await api.get(`/campaign/${campaignId}`)
      setCampaign(response)
    }

    getCampaignById()
    runAsync()
  }, [companyId, campaignId])

  const deleteCampaign = async () => {
    const totalParticipants = campaign?.participant?.totalParticipants

    const deleteCampaignNegotiationBody = {
      companyId: companyId,
      totalUsage: parseInt(totalParticipants),
      action: 'Campanha excluída',
      usageLimit: parseInt(balance?.usageLimit),
      negotiationUsageTypeAction: NegotiationUsageTypeAction.RemoveParticipant,
    }

    await api.post('/company-negotiation-usage', deleteCampaignNegotiationBody)
    await api.post(`/campaign/campaign/${campaignId}/change-status/4`)
    window.location.reload()
  }

  function handleDeleteCampaing(campaignId) {
    setCampaignId(campaignId)
    setDeleteCampaignModalIsOpen(true)
  }

  async function handleCreateCampaignButton() {
    if (balance?.balance <= 0) {
      handleShow()
      await api.post(
        `/company-negotiation-usage/send-email/negotiation-denied?companyName=${companyName}`
      )
      return
    }

    handleCreateCampaign()
  }

  return (
    <>
      <ExceededUsageLimitModal
        styles={styles}
        size='xs'
        show={exceededUsageLimitModalIsOpen}
        onHide={handleClose}
      />
      <DeleteCampaignModal
        show={deleteCampaignModalIsOpen}
        size={'xs'}
        styles={styles}
        onHide={() => setDeleteCampaignModalIsOpen(false)}
        onDelete={deleteCampaign}
      />
      <Row>
        <Col xs={12} style={{ padding: 0 }}>
          <Row>
            <Col xs={12} className='pt-4'>
              <Box>
                <BreadcrumbComponent
                  hasIcon={true}
                  styles={styles}
                  initialIcon='home-color'
                  initialNode='Dashboard'
                  breadcrumbData={state.breadcrumb}
                />
              </Box>
            </Col>
            <Col xs={6}>
              <Box>
                <h1
                  className={`${styles.tipography({
                    headings: 'smallBold',
                  })}   my-3 `}
                >
                  Campanhas
                </h1>
              </Box>
            </Col>
            <Col xs={6} className='d-flex justify-content-end'>
              <Box>
                <ButtonComponent
                  className={`${styles.buttonStandardLarge({
                    large: 'primary',
                  })}
                my-2`}
                  hasIcon={true}
                  iconName='add_circle'
                  text='Criar campanha'
                  styles={styles}
                  onClick={() => handleCreateCampaignButton()}
                />
              </Box>
            </Col>
            <Col xs={12}>
              <div
                className={`${styles.border({
                  borderBottomWidth: 'thick',
                })} mb-4 page-border `}
              />
            </Col>
            <Col>
              <Box>
                <CampaignPaginationPageBase
                  isValidationPage={false}
                  onCampaignDelete={handleDeleteCampaing}
                />
              </Box>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  )
}

CampaignsPage.propTypes = {
  styles: PropTypes.object,
  state: PropTypes.object,
  handleKeyDown: PropTypes.func,
  handleChange: PropTypes.func,
  handleSearch: PropTypes.func,
  handleSelectChange: PropTypes.func,
  handleCreateCampaign: PropTypes.func,
  startDate: PropTypes.any,
  endDate: PropTypes.any,
  setDateRange: PropTypes.any,
  handleEdit: PropTypes.func,
  handleDelete: PropTypes.func,
  show: PropTypes.any,
  handleClose: PropTypes.func,
  handleShow: PropTypes.func,
}

export default CampaignsPage
