import { format } from 'date-fns'

export const formatDate = (date) => {
  return format(new Date(date), 'dd/MM/yyyy')
}

export const formatIntervalString = (to, from) => {
  const formattedTo = format(new Date(to), 'dd/MM/yyyy')
  const formattedFrom = format(new Date(from), 'dd/MM/yyyy')
  return `${formattedFrom} - ${formattedTo}`
}

export const formatDateWithHours = (date) => {
  return format(new Date(date), "dd/MM/yyyy 'às' HH:mm")
}