import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { TabsComponent } from 'cashin-components'
import { Col } from 'react-bootstrap'
import SummaryCampaign from './summary-campaign/summary-campaign'
import RulesCampaign from './rules-campaign/rules-campaign'
import Engagement from './engagement/engagement'
import Participants from './participants/participants'
import Layout from './layout'
import emailDivertidoLayoutImage from 'cashin-design-system/Styles/img/ExampleLayout/miniatura-1.png'
import whatsappLayoutImage from 'cashin-design-system/Styles/img/ExampleLayout/exampleLayout5.png'
import appLayoutImage from 'cashin-design-system/Styles/img/ExampleLayout/appLayout.png'
import { CommunicationReportTable } from './communication-report/CommunicationReportTable'
import { PrizeReportTable } from './prize-report/prize-report-table'

const EditCampaignTabs = ({ styles, state, campaign }) => {
  const [layoutSelected, setLayoutSelected] = useState('')

  const handleSelectedLayoutImage = () => {
    if (layoutSelected === 'app') {
      return appLayoutImage
    } else if (layoutSelected === 'email') {
      return emailDivertidoLayoutImage
    } else {
      return whatsappLayoutImage
    }
  }

  const handleSelectedCheck = () => {
    if (campaign?.isApp) {
      setLayoutSelected('app')
    } else if (
      campaign?.notificationLayout?.notificationDispatches?.[0]?.imageUrl
    ) {
      setLayoutSelected('email')
    } else {
      setLayoutSelected('whatsapp')
    }
  }

  useEffect(() => {
    handleSelectedCheck()
  }, [campaign, layoutSelected, setLayoutSelected])

  const tabs = [
    {
      eventKey: 'campaign-summary',
      title: 'Resumo',
      active: true,
      content: (
        <SummaryCampaign styles={styles} state={state} campaign={campaign} />
      ),
    },
    {
      eventKey: 'campaign-rules',
      title: 'Regras',
      active: false,
      content: (
        <RulesCampaign styles={styles} state={state} campaign={campaign} />
      ),
    },
    {
      eventKey: 'campaign-engagement',
      title: 'Engajamento',
      active: false,
      content: <Engagement styles={styles} state={state} campaign={campaign} />,
    },
    {
      eventKey: 'campaign-participants',
      title: 'Participantes',
      active: false,
      content: (
        <Participants styles={styles} state={state} campaign={campaign} />
      ),
    },
    {
      eventKey: 'campaign-layout',
      title: 'Layout',
      active: false,
      // content: <Layout styles={styles} campaign={campaign} />,
      content: (
        <Col xs={'auto'}>
          <img
            src={handleSelectedLayoutImage()}
            height={appLayoutImage ? 'auto' : 150}
            width={appLayoutImage ? 300 : 150}
            className={styles.shadow({ shadowLevel: 'level1' })}
            alt='Layout escolhido para sua campanha: Executivo'
            style={
              appLayoutImage
                ? {
                    display: 'flex',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    marginTop: '2rem',
                    marginBottom: '2rem',
                  }
                : null
            }
          />
        </Col>
      ),
    },
    {
      eventKey: 'comunicação-report',
      title: 'Relatório de comunicação',
      active: false,
      content: <CommunicationReportTable campaignId={campaign.id} />,
    },
    {
      eventKey: 'award-report',
      title: 'Relatório de premiação',
      active: false,
      content: <PrizeReportTable campaignId={campaign.id} />,
    },
  ]

  return (
    <TabsComponent
      items={tabs}
      tabID='participant-tab-input'
      contentTabID='content-example'
      styles={styles}
      hasBiggerFont={true}
      className={`
        ${styles.tabs({ tabsType: 'main' })}
        tab-bg
      `}
    />
  )
}

EditCampaignTabs.propTypes = {
  styles: PropTypes.object,
  state: PropTypes.object,
}

export default EditCampaignTabs
