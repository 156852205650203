import { styles } from 'cashin-design-system/Styles/Styles'
import { formatIntervalString } from '../../functions/date'
import { BadgeComponent } from 'cashin-components';
import { formatIntToMonetaryString } from '../../functions/money';

export const headers = [
  { title: 'Nº de participantes', key: 'numberOfParticipants' },
  { title: 'Canal de comunicação', key: 'communicationType' },
  { title: 'Engajamento', key: 'engagement' },
  { title: 'Segmentação', key: 'hasSegmentation' },
  { title: 'Status', key: 'status' },
  { title: 'Qtd comunicações', key: 'communicationQuantity' },
  { title: 'Tipo de meta', key: 'rule' },
  { title: 'Total em premiação', key: 'prize' },
  { title: 'Período', key: 'interval' }
]

export const communicationTypeToString = (communicationTypeArray) => {
  return communicationTypeArray.join(', ')
}

export const ruleMap = {
  'ReachPurchaseValue': 'Meta Financeira',
  'ItemQuantity': 'Produto ou Serviço',
  'MultipleReachValueCondition': 'Híbrido',
}

export const badgeTextByStatus = {
  'Active': 'Ativa',
  'Deleted': 'Deletada',
  'Draft': 'Rascunho',
  'Finished': 'Concluída',
}

const badgeTypeByStatus = {
  'Active': 'info',
  'Deleted': 'danger',
  'Draft': 'warning',
  'Finished': 'success',
}

export const CampaignTable = ({item}) => {
  return (
    <table
      id='table'
      className={`${styles.table()}`}
    >
      <thead>
        <tr>
          {headers.map((header, index) => (
            <th key={index}>{header.title}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{item.numberOfParticipants}</td>
          <td>{communicationTypeToString(item.communicationType)}</td>
          <td>{`${item.engagement[0]?.percentage ?? 0}%`}</td>
          <td>{item.hasSegmentation? 'Sim' : 'Não'}</td>
          <td>
            <BadgeComponent
              text={badgeTextByStatus[item.status ?? 'Active']}
              bgType={badgeTypeByStatus[item.status ?? 'Active']}
              isBadgePill={true}
              styles={styles}
            />
          </td>
          <td>{item.communicationQuantity}</td>
          <td>{ruleMap[item.rule] ?? item.rule}</td>
          <td>{formatIntToMonetaryString(item.prize)}</td>
          <td>{formatIntervalString(item.to, item.from)}</td>
        </tr>
      </tbody>
    </table>
  );
}
