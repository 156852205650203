import React, { useContext, useState } from 'react'
import PropTypes from 'prop-types'
import { Col, Row } from 'react-bootstrap'
import { Input, Select, ButtonComponent } from 'cashin-components'
import { CampaignContext } from '../../../context/campaign-context'
import DeleteParticipantModal from './delete-participant-modal'
import { useCompanyUsageBalance } from '../../../API/queries/company/useCompanyUsageBalance'
import ExceededUsageLimitModal from '../../../Components/ExceededUsageLimitModal'
import api from '../../../API/api'
import { toast } from 'react-toastify'

const ParticipantsManualInput = ({ styles, isSegmentation, tagsSegment }) => {
  const campaignContext = useContext(CampaignContext)

  const [deleteParticipantModalIsOpen, setDeleteParticipantModalIsOpen] =
    useState(false)
  const [valueName, setValueName] = useState('')
  const [valuePhone, setValuePhone] = useState('')
  const [valueEmail, setValueEmail] = useState('')
  const [indexParticipant, setIndexParticipant] = useState()
  const [exceededUsageLimitModalIsOpen, SetExceededUsageLimitModalIsOpen] =
    useState(false)
  const [valueSegment, setValueSegment] = useState('')

  const handleClose = () => SetExceededUsageLimitModalIsOpen(false)
  const handleShow = () => SetExceededUsageLimitModalIsOpen(true)

  const { data: balance } = useCompanyUsageBalance(campaignContext.companyId)

  const handleAddField = async () => {
    let isValidNewPhoneEmail = true
    if (valueName.trim() === '') {
      toast.error('Preencha o campo Nome')
      return
    }

    if (valuePhone.trim() === '' && valueEmail.trim() === '') {
      toast.error('Informe o celular ou e-mail do participante')
      return
    }

    if (isSegmentation === 'yes' && valueSegment.trim() === '') {
      toast.error('Selecione um segmento')
      return
    }

    if (
      isSegmentation === 'yes' &&
      tagsSegment.filter((tag) => tag.label === valueSegment).length === 0
    ) {
      toast.error('Segmento inválido')
      return
    }

    if (balance <= 0) {
      handleShow()
      await api.post(
        `/company-negotiation-usage/send-email/negotiation-denied?companyName=${campaignContext.companyName}`
      )
      return
    }

    campaignContext.participants.forEach((participant) => {
      if (
        (valuePhone.trim() !== '' && participant.cellphone === valuePhone) ||
        (valueEmail.trim() !== '' && participant.email === valueEmail)
      ) {
        isValidNewPhoneEmail = false
        return
      }
    })

    if (!isValidNewPhoneEmail) {
      toast.error('Celular ou e-mail  já cadastrado.')
      return
    }

    await campaignContext.setParticipants((currentFields) => [
      ...currentFields,
      {
        name: valueName,
        cellphone: valuePhone,
        email: valueEmail,
        SegmentationName: valueSegment,
      },
    ])
    setValueName('')
    setValuePhone('')
    setValueEmail('')
    setValueSegment('')
  }

  function hideModalDeleteParticipant() {
    setIndexParticipant()
    setDeleteParticipantModalIsOpen(false)
  }

  function handleDelete(index) {
    setIndexParticipant(index)
    setDeleteParticipantModalIsOpen(true)
  }

  function onDelete() {
    campaignContext.participants.splice(indexParticipant, 1)
    campaignContext.setUpdateListParticipants(true)
    setDeleteParticipantModalIsOpen(false)
    setIndexParticipant()
  }

  const removeCellphoneMask = (maskedCellphone) => {
    if (typeof maskedCellphone !== 'string') {
      return maskedCellphone
    }
    const cellphone = maskedCellphone.replace(/\D/g, '')
    return cellphone
  }

  const applyCellphoneMask = (cellphone) => {
    if (cellphone && cellphone.length >= 15) return
    const maskedCellphone = cellphone
      .replace(/^(\d{2})(\d)/g, '($1) $2')
      .replace(/(\d{5})(\d)/, '$1-$2')
    return maskedCellphone
  }

  const headerTable = () => {
    return (
      <>
        <th>Nome</th>
        <th>Celular</th>
        <th>E-mail</th>
        {isSegmentation === 'yes' && <th>Segmento</th>}
        <th>Ações</th>
      </>
    )
  }

  return (
    <>
      <ExceededUsageLimitModal
        styles={styles}
        size='xs'
        show={exceededUsageLimitModalIsOpen}
        onHide={handleClose}
      />
      <Row>
        <Col xs>
          <Input
            id={'txtUserName2'}
            inputName={'userName2'}
            className={styles.inputs({ input: 'default' })}
            idError='fdbUserNameError2'
            inputType='text'
            labelName='Nome'
            placeholder='Nome do participante'
            styles={styles}
            value={valueName}
            onChange={(e) => setValueName(e.target.value)}
            disabled={
              tagsSegment !== undefined &&
              tagsSegment.length === 0 &&
              isSegmentation === 'yes'
            }
          />
        </Col>
        <Col xs>
          <Input
            id='txtUserPhone2'
            inputName='userPhone2'
            className={styles.inputs({ input: 'default' })}
            idError='fdbUserPhoneError2'
            inputType='text'
            labelName='Celular'
            placeholder={`Celular do participante`}
            styles={styles}
            value={applyCellphoneMask(valuePhone)}
            onChange={(e) => {
              if (e.target.value.length > 15) return
              setValuePhone(removeCellphoneMask(e.target.value))
            }}
            hasTooltip={true}
            tooltipText={
              'Para campanhas via WhatsApp ou App Engaje insira o telefone com DDD e o código do país.'
            }
            disabled={
              tagsSegment !== undefined &&
              tagsSegment.length === 0 &&
              isSegmentation === 'yes'
            }
          />
        </Col>
        <Col xs>
          <Input
            id='txtUserEmail2'
            inputName='userEmail2'
            className={styles.inputs({ input: 'default' })}
            idError='fdbUserEmailError2'
            inputType='text'
            labelName='E-mail'
            placeholder='E-mail do participante'
            styles={styles}
            value={valueEmail}
            hasTooltip={true}
            tooltipText={
              'Para Campanhas via e-mail ou App Engaje insira o endereço de e-mail.'
            }
            onChange={(e) => setValueEmail(e.target.value)}
            disabled={
              tagsSegment !== undefined &&
              tagsSegment.length === 0 &&
              isSegmentation === 'yes'
            }
          />
        </Col>
        {isSegmentation === 'yes' && (
          <Col xs>
            <Select
              id='selectSegment'
              inputName='segment'
              className={styles.inputs({ input: 'default' })}
              idError='fdbSegmentError'
              labelName='Segmento'
              placeholder='Selecione uma segmento'
              styles={styles}
              value={valueSegment}
              options={tagsSegment}
              onChange={(e) => {
                setValueSegment(e.label)
              }}
              disabled={
                tagsSegment !== undefined &&
                tagsSegment.length === 0 &&
                isSegmentation === 'yes'
              }
            />
          </Col>
        )}
        <Col xs={'auto'} className='d-flex align-items-end'>
          <ButtonComponent
            className={`
          ${styles.buttonSquareLarge({
            large: 'primary',
          })}
          mb-3
        `}
            hasIcon={true}
            iconName='add'
            onClick={handleAddField}
          />
        </Col>
      </Row>
      <table
        id='table'
        className={`
          ${styles.table()}
        `}
      >
        <thead>
          <tr>{headerTable()}</tr>
        </thead>
        <tbody>
          {campaignContext.participants.length > 0 &&
            campaignContext.participants?.map((participant, i) => {
              return (
                <tr key={i}>
                  <td>{participant.name}</td>
                  <td>{applyCellphoneMask(participant.cellphone)}</td>
                  <td>{participant.email}</td>
                  {isSegmentation === 'yes' && (
                    <td>
                      {participant.SegmentationName
                        ? participant.SegmentationName
                        : 'não informado'}
                    </td>
                  )}
                  <td>
                    <ButtonComponent
                      className={styles.buttonSquareLarge({
                        large: 'secondary',
                      })}
                      hasIcon={true}
                      iconName='delete_outline'
                      onClick={() => handleDelete(i)}
                    />
                    {/* Botão de editar omentado por enquanto
                    <ButtonComponent
                    className={styles.buttonSquareLarge({
                      large: "secondary",
                    })}
                    hasIcon={true}
                    iconName="drive_file_rename_outline"
                    onClick={() => console.log('EDIT')}
                  /> */}
                  </td>
                </tr>
              )
            })}
        </tbody>
      </table>
      <DeleteParticipantModal
        show={deleteParticipantModalIsOpen}
        size={'xs'}
        styles={styles}
        onHide={hideModalDeleteParticipant}
        onDelete={onDelete}
      />
    </>
  )
}

ParticipantsManualInput.propTypes = {
  styles: PropTypes.object,
  state: PropTypes.object,
}

export default ParticipantsManualInput
