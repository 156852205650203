/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Col, Row } from 'react-bootstrap'
import { LayoutTypeSelector } from 'cashin-components'
import ModalLayoutContent from './modal-layout-content'
import exampleLayout1 from 'cashin-design-system/Styles/img/ExampleLayout/miniatura-2.png'
import exampleLayout2 from 'cashin-design-system/Styles/img/ExampleLayout/miniatura-1.png'
import exampleLayout3 from 'cashin-design-system/Styles/img/ExampleLayout/miniatura-3.png'
import whatsAppDefault from 'cashin-design-system/Styles/img/ExampleLayout/whatsAppDefault.png'
import whatsAppCustom from 'cashin-design-system/Styles/img/ExampleLayout/whatsAppCustom.png'
import notificationDefault from 'cashin-design-system/Styles/img/ExampleLayout/notificationDefault.png'
import notificationCustom from 'cashin-design-system/Styles/img/ExampleLayout/notificationCustom.png'
import notificationDefaultHover from 'cashin-design-system/Styles/img/ExampleLayout/notificationDefaultHover.png'
import notificationCustomHover from 'cashin-design-system/Styles/img/ExampleLayout/notificationCustomHover.png'

import { CampaignContext } from '../../../context/campaign-context'

const LayoutSelector = ({
  styles,
  state,
  handleLayoutType,
  handleLayoutSelector,
  show,
  handleClose,
  handleLayoutChoosen,
  handleSaveLayout,
  wppMessageAttributes,
  setWppMessageAttributes,
  appMessageAttributes,
  setAppMessageAttributes,
}) => {
  const [layoutList, setLayoutList] = useState()
  const campaignContext = useContext(CampaignContext)

  const layoutEmail = [
    {
      iconName: '',
      imageExample: exampleLayout1,
      imageExampleHover: exampleLayout1,
      title1: '',
      title2: '',
      title3: '',
      subTitle: 'Moderno',
      size: {
        width: '100%',
        height: '199px',
      },
    },
    {
      iconName: '',
      imageExample: exampleLayout2,
      imageExampleHover: exampleLayout2,
      title1: '',
      title2: '',
      title3: '',
      subTitle: 'Divertido',
      size: {
        width: '100%',
        height: '199px',
      },
    },
    {
      iconName: '',
      imageExample: exampleLayout3,
      imageExampleHover: exampleLayout3,
      title1: '',
      title2: '',
      title3: '',
      subTitle: 'Executivo',
      size: {
        width: '100%',
        height: '199px',
      },
    },
  ]

  const layoutWhatsApp = [
    {
      iconName: '',
      imageExample: whatsAppDefault,
      imageExampleHover: whatsAppDefault,
      title1: '',
      title2: '',
      title3: '',
      subTitle: 'WhatsApp padrão',
      size: {
        width: '100%',
        height: '199px',
      },
    },
    {
      iconName: '',
      imageExample: whatsAppCustom,
      imageExampleHover: whatsAppCustom,
      title1: '',
      title2: '',
      title3: '',
      subTitle: 'WhatsApp personalizado',
      size: {
        width: '100%',
        height: '199px',
      },
    },
  ]

  const layoutNotification = [
    {
      iconName: '',
      imageExample: notificationDefault,
      imageExampleHover: notificationDefaultHover,
      title1: '',
      title2: '',
      title3: '',
      subTitle: 'Notificação padrão',
      description: 'Utilize nosso padrão de notificações',
      size: {
        width: '54px',
        height: '54px',
      },
    },
    // {
    //   iconName: '',
    //   imageExample: notificationCustom,
    //   imageExampleHover: notificationCustomHover,
    //   title1: '',
    //   title2: '',
    //   title3: '',
    //   subTitle: 'Notificação Personalizada',
    //   description: 'Você pode configurar como desejar',
    //   size: {
    //     width: '54px',
    //     height: '54px',
    //   }
    // },
  ]

  const welcomeTemplate = {
    id: 0,
    layoutTitle: 'Boas vindas',
    layoutDimensions: '600x1200px',
  }
  const tipToHitGoalTemplate = {
    id: 1,
    layoutTitle: 'Dica para bater a meta',
    layoutDimensions: '600x1200px',
  }
  const motivational1Template = {
    id: 2,
    layoutTitle: 'Motivacional',
    layoutDimensions: '600x1200px',
  }
  const rememberPeriod1Template = {
    id: 3,
    layoutTitle: 'Lembrete de Período',
    layoutDimensions: '600x1200px',
  }
  const howToMeetYourProductTemplate = {
    id: 4,
    layoutTitle: 'Como conhecer o seu Produto',
    layoutDimensions: '600x1200px',
  }
  const engajeQuestion2Template = {
    id: 5,
    layoutTitle: 'Pergunta de Engajamento',
    layoutDimensions: '600x1200px',
  }
  const rememberPeriod2Template = {
    id: 6,
    layoutTitle: 'Lembrete de Período',
    layoutDimensions: '600x1200px',
  }
  const motivational3Template = {
    id: 7,
    layoutTitle: 'Motivacional',
    layoutDimensions: '600x1200px',
  }
  const rememberRulesTemplate = {
    id: 8,
    layoutTitle: 'Relembre suas Regras',
    layoutDimensions: '600x1200px',
  }
  const rememberPeriod3Template = {
    id: 9,
    layoutTitle: 'Lembrete de Período 3',
    layoutDimensions: '600x1200px',
  }
  const motivational2Template = {
    id: 10,
    layoutTitle: 'Motivacional',
    layoutDimensions: '600x1200px',
  }
  const engajeQuestion1Template = {
    id: 11,
    layoutTitle: 'Pergunta de Engajamento',
    layoutDimensions: '600x1200px',
  }
  const countdownTemplate = {
    id: 12,
    layoutTitle: 'Contagem Regressiva',
    layoutDimensions: '600x1200px',
  }
  const thanksForParticipatingTemplate = {
    id: 13,
    layoutTitle: 'Obrigada por participar',
    layoutDimensions: '600x1200px',
  }

  const welcomeTemplateApp = {
    id: 0,
    layoutTitle: 'Notificação',
    layoutDimensions: '600x1200px',
  }
  const tipToHitGoalTemplateApp = {
    id: 1,
    layoutTitle: 'Notificação',
    layoutDimensions: '600x1200px',
  }
  const motivational1TemplateApp = {
    id: 2,
    layoutTitle: 'Notificação',
    layoutDimensions: '600x1200px',
  }
  const rememberPeriod1TemplateApp = {
    id: 3,
    layoutTitle: 'Notificação',
    layoutDimensions: '600x1200px',
  }
  const howToMeetYourProductTemplateApp = {
    id: 4,
    layoutTitle: 'Notificação',
    layoutDimensions: '600x1200px',
  }
  const engajeQuestion2TemplateApp = {
    id: 5,
    layoutTitle: 'Notificação',
    layoutDimensions: '600x1200px',
  }
  const rememberPeriod2TemplateApp = {
    id: 6,
    layoutTitle: 'Notificação',
    layoutDimensions: '600x1200px',
  }
  const motivational3TemplateApp = {
    id: 7,
    layoutTitle: 'Notificação',
    layoutDimensions: '600x1200px',
  }
  const rememberRulesTemplateApp = {
    id: 8,
    layoutTitle: 'Notificação',
    layoutDimensions: '600x1200px',
  }
  const motivational2TemplateApp = {
    id: 9,
    layoutTitle: 'Notificação',
    layoutDimensions: '600x1200px',
  }
  const engajeQuestion1TemplateApp = {
    id: 10,
    layoutTitle: 'Notificação',
    layoutDimensions: '600x1200px',
  }
  const lastWeekTemplateApp = {
    id: 11,
    layoutTitle: 'Notificação',
    layoutDimensions: '600x1200px',
  }
  const thanksForParticipatingTemplateApp = {
    id: 12,
    layoutTitle: 'Notificação',
    layoutDimensions: '600x1200px',
  }

  const thanksForParticipatingTemplateApp2 = {
    id: 13,
    layoutTitle: 'Notificação',
    layoutDimensions: '600x1200px',
  }

  const totalDispatchWeek = campaignContext.getTotalDispatch()

  const handleSelectLayout = (layout) => {
    switch (layout) {
      case 'email':
        return layoutEmail
      case 'whatsapp':
        return layoutWhatsApp
      case 'app':
        return layoutNotification
      default:
        return layoutEmail
    }
  }

  function comunicationSchedule() {
    let layoutSelection = []

    switch (true) {
      case totalDispatchWeek <= 1:
        layoutSelection.push(welcomeTemplate)
        if (campaignContext.communicationType !== 'whatsapp') {
          layoutSelection.push(tipToHitGoalTemplate)
        }
        layoutSelection.push(thanksForParticipatingTemplate)
        setLayoutList(() => layoutSelection)
        break
      case totalDispatchWeek === 2:
        layoutSelection.push(welcomeTemplate)
        layoutSelection.push(tipToHitGoalTemplate)
        layoutSelection.push(thanksForParticipatingTemplate)
        setLayoutList(() => layoutSelection)
        break
      case totalDispatchWeek === 3:
        layoutSelection.push(welcomeTemplate)
        layoutSelection.push(tipToHitGoalTemplate)
        layoutSelection.push(thanksForParticipatingTemplate)
        setLayoutList(() => layoutSelection)
        break
      case totalDispatchWeek === 4:
        layoutSelection.push(welcomeTemplate)
        layoutSelection.push(tipToHitGoalTemplate)
        layoutSelection.push(engajeQuestion1Template)
        layoutSelection.push(thanksForParticipatingTemplate)
        setLayoutList(() => layoutSelection)
        break
      case totalDispatchWeek === 5:
        layoutSelection.push(welcomeTemplate)
        layoutSelection.push(tipToHitGoalTemplate)
        layoutSelection.push(motivational1Template)
        layoutSelection.push(engajeQuestion1Template)
        layoutSelection.push(thanksForParticipatingTemplate)
        setLayoutList(() => layoutSelection)
        break
      case totalDispatchWeek === 6:
        layoutSelection.push(welcomeTemplate)
        layoutSelection.push(tipToHitGoalTemplate)
        layoutSelection.push(motivational1Template)
        layoutSelection.push(howToMeetYourProductTemplate)
        layoutSelection.push(engajeQuestion1Template)
        layoutSelection.push(thanksForParticipatingTemplate)
        setLayoutList(() => layoutSelection)
        break
      case totalDispatchWeek === 7:
        layoutSelection.push(welcomeTemplate)
        layoutSelection.push(tipToHitGoalTemplate)
        layoutSelection.push(motivational1Template)
        layoutSelection.push(howToMeetYourProductTemplate)
        layoutSelection.push(motivational2Template)
        layoutSelection.push(engajeQuestion1Template)
        layoutSelection.push(thanksForParticipatingTemplate)
        setLayoutList(() => layoutSelection)
        break
      case totalDispatchWeek === 8:
        layoutSelection.push(welcomeTemplate)
        layoutSelection.push(tipToHitGoalTemplate)
        layoutSelection.push(motivational1Template)
        layoutSelection.push(rememberPeriod1Template)
        layoutSelection.push(howToMeetYourProductTemplate)
        layoutSelection.push(motivational2Template)
        layoutSelection.push(engajeQuestion1Template)
        layoutSelection.push(thanksForParticipatingTemplate)
        setLayoutList(() => layoutSelection)
        break
      case totalDispatchWeek === 9:
        layoutSelection.push(welcomeTemplate)
        layoutSelection.push(tipToHitGoalTemplate)
        layoutSelection.push(motivational1Template)
        layoutSelection.push(rememberPeriod1Template)
        layoutSelection.push(howToMeetYourProductTemplate)
        layoutSelection.push(rememberRulesTemplate)
        layoutSelection.push(motivational2Template)
        layoutSelection.push(engajeQuestion1Template)
        layoutSelection.push(thanksForParticipatingTemplate)
        setLayoutList(() => layoutSelection)
        break
      case totalDispatchWeek === 10:
        layoutSelection.push(welcomeTemplate)
        layoutSelection.push(tipToHitGoalTemplate)
        layoutSelection.push(motivational1Template)
        layoutSelection.push(rememberPeriod1Template)
        layoutSelection.push(howToMeetYourProductTemplate)
        layoutSelection.push(engajeQuestion2Template)
        layoutSelection.push(rememberRulesTemplate)
        layoutSelection.push(motivational2Template)
        layoutSelection.push(engajeQuestion1Template)
        layoutSelection.push(thanksForParticipatingTemplate)
        setLayoutList(() => layoutSelection)
        break
      case totalDispatchWeek === 11:
        layoutSelection.push(welcomeTemplate)
        layoutSelection.push(tipToHitGoalTemplate)
        layoutSelection.push(motivational1Template)
        layoutSelection.push(rememberPeriod1Template)
        layoutSelection.push(howToMeetYourProductTemplate)
        layoutSelection.push(engajeQuestion2Template)
        layoutSelection.push(rememberPeriod2Template)
        layoutSelection.push(rememberRulesTemplate)
        layoutSelection.push(motivational2Template)
        layoutSelection.push(engajeQuestion1Template)
        layoutSelection.push(thanksForParticipatingTemplate)
        setLayoutList(() => layoutSelection)
        break
      case totalDispatchWeek >= 12:
        layoutSelection.push(welcomeTemplate)
        layoutSelection.push(tipToHitGoalTemplate)
        layoutSelection.push(motivational1Template)
        layoutSelection.push(rememberPeriod1Template)
        layoutSelection.push(howToMeetYourProductTemplate)
        layoutSelection.push(engajeQuestion2Template)
        layoutSelection.push(rememberPeriod2Template)
        layoutSelection.push(motivational3Template)
        layoutSelection.push(rememberRulesTemplate)
        layoutSelection.push(rememberPeriod3Template)
        layoutSelection.push(motivational2Template)
        layoutSelection.push(engajeQuestion1Template)
        layoutSelection.push(countdownTemplate)
        layoutSelection.push(thanksForParticipatingTemplate)
        setLayoutList(() => layoutSelection)
        break
      default:
        layoutSelection.push(welcomeTemplate)
        layoutSelection.push(tipToHitGoalTemplate)
        layoutSelection.push(thanksForParticipatingTemplate)
        setLayoutList(() => layoutSelection)
        break
    }
  }

  function comunicationScheduleApp() {
    let layoutSelectionApp = []

    switch (true) {
      case totalDispatchWeek <= 1:
        layoutSelectionApp.push(welcomeTemplateApp)
        layoutSelectionApp.push(thanksForParticipatingTemplateApp2)
        setLayoutList(() => layoutSelectionApp)
        break
      case totalDispatchWeek === 2:
        layoutSelectionApp.push(welcomeTemplateApp)
        layoutSelectionApp.push(tipToHitGoalTemplateApp)
        layoutSelectionApp.push(thanksForParticipatingTemplateApp2)
        setLayoutList(() => layoutSelectionApp)
        break
      case totalDispatchWeek === 3:
        layoutSelectionApp.push(welcomeTemplateApp)
        layoutSelectionApp.push(tipToHitGoalTemplateApp)
        layoutSelectionApp.push(thanksForParticipatingTemplateApp)
        layoutSelectionApp.push(thanksForParticipatingTemplateApp2)
        setLayoutList(() => layoutSelectionApp)
        break
      case totalDispatchWeek === 4:
        layoutSelectionApp.push(welcomeTemplateApp)
        layoutSelectionApp.push(tipToHitGoalTemplateApp)
        layoutSelectionApp.push(rememberPeriod2TemplateApp)
        layoutSelectionApp.push(thanksForParticipatingTemplateApp)
        layoutSelectionApp.push(thanksForParticipatingTemplateApp2)
        setLayoutList(() => layoutSelectionApp)
        break
      case totalDispatchWeek === 5:
        layoutSelectionApp.push(welcomeTemplateApp)
        layoutSelectionApp.push(tipToHitGoalTemplateApp)
        layoutSelectionApp.push(rememberPeriod2TemplateApp)
        layoutSelectionApp.push(rememberPeriod1TemplateApp)
        layoutSelectionApp.push(thanksForParticipatingTemplateApp)
        layoutSelectionApp.push(thanksForParticipatingTemplateApp2)
        setLayoutList(() => layoutSelectionApp)
        break
      case totalDispatchWeek === 6:
        layoutSelectionApp.push(welcomeTemplateApp)
        layoutSelectionApp.push(tipToHitGoalTemplateApp)
        layoutSelectionApp.push(motivational1TemplateApp)
        layoutSelectionApp.push(rememberPeriod1TemplateApp)
        layoutSelectionApp.push(rememberPeriod2TemplateApp)
        layoutSelectionApp.push(thanksForParticipatingTemplateApp)
        layoutSelectionApp.push(thanksForParticipatingTemplateApp2)
        setLayoutList(() => layoutSelectionApp)
        break
      case totalDispatchWeek === 7:
        layoutSelectionApp.push(welcomeTemplateApp)
        layoutSelectionApp.push(tipToHitGoalTemplateApp)
        layoutSelectionApp.push(motivational1TemplateApp)
        layoutSelectionApp.push(rememberPeriod1TemplateApp)
        layoutSelectionApp.push(rememberPeriod2TemplateApp)
        layoutSelectionApp.push(engajeQuestion2TemplateApp)
        layoutSelectionApp.push(thanksForParticipatingTemplateApp)
        layoutSelectionApp.push(thanksForParticipatingTemplateApp2)
        setLayoutList(() => layoutSelectionApp)
        break
      case totalDispatchWeek === 8:
        layoutSelectionApp.push(welcomeTemplateApp)
        layoutSelectionApp.push(tipToHitGoalTemplateApp)
        layoutSelectionApp.push(motivational1TemplateApp)
        layoutSelectionApp.push(rememberPeriod1TemplateApp)
        layoutSelectionApp.push(rememberPeriod2TemplateApp)
        layoutSelectionApp.push(engajeQuestion2TemplateApp)
        layoutSelectionApp.push(rememberRulesTemplateApp)
        layoutSelectionApp.push(thanksForParticipatingTemplateApp)
        layoutSelectionApp.push(thanksForParticipatingTemplateApp2)
        setLayoutList(() => layoutSelectionApp)
        break
      case totalDispatchWeek === 9:
        layoutSelectionApp.push(welcomeTemplateApp)
        layoutSelectionApp.push(tipToHitGoalTemplateApp)
        layoutSelectionApp.push(motivational1TemplateApp)
        layoutSelectionApp.push(rememberPeriod1TemplateApp)
        layoutSelectionApp.push(howToMeetYourProductTemplateApp)
        layoutSelectionApp.push(engajeQuestion2TemplateApp)
        layoutSelectionApp.push(rememberPeriod2TemplateApp)
        layoutSelectionApp.push(rememberRulesTemplateApp)
        layoutSelectionApp.push(thanksForParticipatingTemplateApp)
        layoutSelectionApp.push(thanksForParticipatingTemplateApp2)
        setLayoutList(() => layoutSelectionApp)
        break
      case totalDispatchWeek === 10:
        layoutSelectionApp.push(welcomeTemplateApp)
        layoutSelectionApp.push(tipToHitGoalTemplateApp)
        layoutSelectionApp.push(motivational1TemplateApp)
        layoutSelectionApp.push(rememberPeriod1TemplateApp)
        layoutSelectionApp.push(howToMeetYourProductTemplateApp)
        layoutSelectionApp.push(engajeQuestion2TemplateApp)
        layoutSelectionApp.push(rememberPeriod2TemplateApp)
        layoutSelectionApp.push(motivational3TemplateApp)
        layoutSelectionApp.push(rememberRulesTemplateApp)
        layoutSelectionApp.push(thanksForParticipatingTemplateApp)
        layoutSelectionApp.push(thanksForParticipatingTemplateApp2)
        setLayoutList(() => layoutSelectionApp)
        break
      case totalDispatchWeek === 11:
        layoutSelectionApp.push(welcomeTemplateApp)
        layoutSelectionApp.push(tipToHitGoalTemplateApp)
        layoutSelectionApp.push(motivational1TemplateApp)
        layoutSelectionApp.push(rememberPeriod1TemplateApp)
        layoutSelectionApp.push(howToMeetYourProductTemplateApp)
        layoutSelectionApp.push(engajeQuestion2TemplateApp)
        layoutSelectionApp.push(rememberPeriod2TemplateApp)
        layoutSelectionApp.push(motivational3TemplateApp)
        layoutSelectionApp.push(rememberRulesTemplateApp)
        layoutSelectionApp.push(motivational2TemplateApp)
        layoutSelectionApp.push(thanksForParticipatingTemplateApp)
        layoutSelectionApp.push(thanksForParticipatingTemplateApp2)
        setLayoutList(() => layoutSelectionApp)
        break
      case totalDispatchWeek === 12:
        layoutSelectionApp.push(welcomeTemplateApp)
        layoutSelectionApp.push(tipToHitGoalTemplateApp)
        layoutSelectionApp.push(motivational1TemplateApp)
        layoutSelectionApp.push(rememberPeriod1TemplateApp)
        layoutSelectionApp.push(howToMeetYourProductTemplateApp)
        layoutSelectionApp.push(engajeQuestion2TemplateApp)
        layoutSelectionApp.push(rememberPeriod2TemplateApp)
        layoutSelectionApp.push(motivational3TemplateApp)
        layoutSelectionApp.push(rememberRulesTemplateApp)
        layoutSelectionApp.push(motivational2TemplateApp)
        layoutSelectionApp.push(engajeQuestion1TemplateApp)
        layoutSelectionApp.push(thanksForParticipatingTemplateApp)
        layoutSelectionApp.push(thanksForParticipatingTemplateApp2)
        setLayoutList(() => layoutSelectionApp)
        break
      case totalDispatchWeek >= 13:
        layoutSelectionApp.push(welcomeTemplateApp)
        layoutSelectionApp.push(tipToHitGoalTemplateApp)
        layoutSelectionApp.push(motivational1TemplateApp)
        layoutSelectionApp.push(rememberPeriod1TemplateApp)
        layoutSelectionApp.push(howToMeetYourProductTemplateApp)
        layoutSelectionApp.push(engajeQuestion2TemplateApp)
        layoutSelectionApp.push(rememberPeriod2TemplateApp)
        layoutSelectionApp.push(motivational3TemplateApp)
        layoutSelectionApp.push(rememberRulesTemplateApp)
        layoutSelectionApp.push(motivational2TemplateApp)
        layoutSelectionApp.push(engajeQuestion1TemplateApp)
        layoutSelectionApp.push(lastWeekTemplateApp)
        layoutSelectionApp.push(thanksForParticipatingTemplateApp)
        layoutSelectionApp.push(thanksForParticipatingTemplateApp2)
        setLayoutList(() => layoutSelectionApp)
        break
      default:
        layoutSelectionApp.push(welcomeTemplateApp)
        layoutSelectionApp.push(thanksForParticipatingTemplateApp2)
        setLayoutList(() => layoutSelectionApp)
        break
    }
  }

  useEffect(() => {
    campaignContext?.communicationType === 'app'
      ? comunicationScheduleApp()
      : comunicationSchedule()
  }, [totalDispatchWeek])

  const layoutType = handleSelectLayout(campaignContext.communicationType)

  return (
    <Row>
      <LayoutTypeSelector
        styles={styles}
        state={state}
        layoutType={layoutType}
        handleLayoutType={handleLayoutType}
        handleLayoutChoosen={handleLayoutChoosen}
      />
      <ModalLayoutContent
        styles={styles}
        state={state}
        size='xl'
        show={show}
        layoutSelection={layoutList}
        handleLayoutSelector={handleLayoutSelector}
        onHide={handleClose}
        handleSaveLayout={handleSaveLayout}
        wppMessageAttributes={wppMessageAttributes}
        setWppMessageAttributes={setWppMessageAttributes}
        appMessageAttributes={appMessageAttributes}
        setAppMessageAttributes={setAppMessageAttributes}
      />
    </Row>
  )
}

LayoutSelector.propTypes = {
  styles: PropTypes.object,
  state: PropTypes.object,
  layoutType: PropTypes.array,
  handleLayoutType: PropTypes.func,
  layoutSelection: PropTypes.array,
  handleLayoutSelector: PropTypes.func,
  show: PropTypes.any,
  handleClose: PropTypes.func,
  handleLayoutChoosen: PropTypes.func,
  handleSaveLayout: PropTypes.func,
}

export default LayoutSelector
