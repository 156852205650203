import { Col, Form, InputGroup, Row } from "react-bootstrap"
import { styles } from 'cashin-design-system/Styles/Styles'
import Icon from "../../Components/Icon";
import ButtonComponent from "../../Components/Buttons";
import { useCallback, useEffect, useState } from "react";
import { DrawerFilter } from "../../Components/DrawerFilter";
import { Box } from "../../Components";
import { formatIntToMonetaryString } from "../../functions/money";
import { formatDateWithHours } from "../../functions/date";
import { debounce } from 'lodash';
import { stringTypeValidators } from "../../functions/string-input-validations";
import { getPrizeReport, getPrizeReportExport } from "../../APIV2/reports/get-prize-report";
import { transformPrizeReportDataForXlxs } from "./export-prize-report";
import moment from "moment";
import { exportToXlsx } from "../../functions/export-report-to-xlsx";
import CustomPagination from "../../Components/CustomPagination";
import Loading from "../../Components/Loading";

const headers = [
  { label: 'Participantes', key: 'name', component: (row) => <td>{row.name}</td> },
  { label: 'Telefone', key: 'phone', component: (row) => <td>{row.phone}</td> },
  { label: 'E-mail', key: 'email', component: (row) => <td>{row.email}</td> },
  { label: 'Premiação', key: 'amount', component: (row) => <td>{formatIntToMonetaryString(row.amount)}</td> },
  { label: 'Data de aprovado', key: 'approvedAt', component: (row) => <td>{formatDateWithHours(row.approvedAt)}</td> },
];

export const PrizeReportTable = ({ campaignId }) => {
  const [data, setData] = useState([]);
  const [showDrawerFilter, setShowDrawerFilter] = useState(false);
  const [dateRange, setDateRange] = useState([null, null]);
  const [inputDateRange, setInputDateRange] = useState([null, null]);
  const [paramName, setParamName] = useState('');
  const [stringFilter, setStringFilter] = useState('');
  const [debouncedStringFilter, setDebouncedStringInput] = useState('');
  const [currentPagination, setCurrentPagination] = useState(1);
  const [totalPages, setTotalPages]= useState(1);
  const [isLoading, setIsLoading] = useState(false);

  const debounceStringFilter = debounce((stringInput) => {
    const matchedValidator = stringTypeValidators.find((validator) => validator.test(stringInput));
    if (matchedValidator) {
      setDebouncedStringInput(stringInput);
      setParamName(matchedValidator.name);
    } 
  }, 500);

  const handleStringFilterChange = (e) => {
    setStringFilter(e.target.value);
    debounceStringFilter(e.target.value);
  }

  const handleCancelDrawerFilter = () => {
    setDateRange([null, null]);
    setInputDateRange([null, null]);
    setShowDrawerFilter(false);
  }

  const handleApplyDrawerFilter = () => {
    setDateRange(inputDateRange);
    setShowDrawerFilter(false);
  };

  const handleCleanDrawerFilter = () => {
    setDateRange([null, null]);
    setInputDateRange([null, null]);
    setParamName('');
    setStringFilter('');
    setDebouncedStringInput('');
    setShowDrawerFilter(false);
  }

  const handleOnPaginationChange = (newPage) => {
    setCurrentPagination(newPage);
  }

  const search = useCallback(async () => {
    setIsLoading(true);
    const result = await getPrizeReport(
      campaignId,
      paramName,
      debouncedStringFilter,
      dateRange[0],
      dateRange[1],
      currentPagination
    );
    setData(result.data.data);
    setTotalPages(result.data.totalPages);
    setIsLoading(false);
  }, [campaignId, paramName, debouncedStringFilter, dateRange, currentPagination]);

  const exportReport = async () => {
    const result = await getPrizeReportExport(
      campaignId,
      paramName,
      debouncedStringFilter,
      dateRange[0],
      dateRange[1],
      currentPagination
    );

    const labels = headers.map((header) => header.label);
    const propertyNames = headers.map((header) => header.key);
    const xlsxData = transformPrizeReportDataForXlxs(labels, propertyNames, result.data.data);
    const formattedDate = moment(new Date()).format('DD-MM-YYYY');
    exportToXlsx(xlsxData, `relatório-de-premiação-${formattedDate}`);
  }

  useEffect(() => {
    search();
  }, [currentPagination, search, debouncedStringFilter])

  return (
    <Box>
      <DrawerFilter
        styles={styles}
        show={showDrawerFilter}
        onHide={() => setShowDrawerFilter(false)}
        dateRange={inputDateRange}
        setDateRange={setInputDateRange}
        onCancel={handleCancelDrawerFilter}
        onApply={handleApplyDrawerFilter}
        onCleanFilter={handleCleanDrawerFilter}
      />
    <Row className="align-items-center mb-4">
        <Col xs={{span: 4, offset: 8}} className="d-flex justify-content-end align-items-center">
        <ButtonComponent
            className="bg-white text-black border-0 hover:bg-violet-200"
            hasIcon={true}
            iconName="filter_alt"
            onClick={() => setShowDrawerFilter(true)}
          />
          <InputGroup className="my-1 me-2">
            <Form.Control
              className="bg-white rounded-md border-end-0 border"
              placeholder="Busca por participante, telefone ou email"
              value={stringFilter}
              onChange={handleStringFilterChange}
            />
            <InputGroup.Text className="bg-white border-start-0 rounded-md border">
              <Icon iconName={'search'} />
            </InputGroup.Text>
          </InputGroup>
          <ButtonComponent
            className={`${
              styles.buttonStandardSmall({ small: 'primaryLoading' })}`}
            text='Exportar'
            isLoading={false}
            styles={styles}
            onClick={exportReport}
          />
        </Col>
      </Row>
      <Row>
        <Col>  
          {isLoading ? <Loading /> : (
            <>
              <table
                id='table'
                className={`${styles.table()}`}
              >
                {headers.map((header, index) => (
                  <th key={index}>{header.label}</th>
                ))}
                {data.map((row, index) => (
                    <tr key={index} className="table-row">
                      {headers.map((header) => (
                        header.component(row)
                      ))}
                    </tr>
                ))}
              </table>
              <CustomPagination
                totalPages={totalPages}
                onPaginationChange={handleOnPaginationChange}
                currentPage={currentPagination}
                setCurrentPage={setCurrentPagination}
              />
            </>
          )}
        </Col>
      </Row>
    </Box>
  )
}